.app-gradient {
    position: absolute;
    width: 100%;
    height: 100%;
    .app-gradient-1 {
        position: absolute;
        width: 512px;
        height: 512px;
        left: -280px;
        top: -327px;

        background: #0500fe;
        opacity: 0.7;
        filter: blur(200px);
    }
}
