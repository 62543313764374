.segment-container {
    background: $white;
    border: $borderStyle;
    border-radius: 20px;
    display: flex;
    margin-bottom: 50px;
    overflow: hidden;
    width: auto;
    .segment-menu {
        .MuiTypography-root {
            padding: 15px 20px;
            color: #dbdbdb;
        }
        &:hover {
            cursor: pointer;
            background: $white;
        }
        border-right: 1px solid $border;
    }
}
