#design-book {
    .teritary-container {
        margin-bottom: 20px;
    }

    .btn-primary,
    .btn-secondary,
    .btn-teritary {
        margin: 8px 8px 8px 0px;
    }

    .palettes {
        padding: 20px 0px;
        .palette {
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            width: 200px;
            height: 200px;
            border-radius: 20px;
            margin-right: 20px;
            margin-bottom: 20px;
            color: white;
        }
        .primary {
            background-color: $primary;
        }
        .text-primary {
            background-color: $text-primary;
        }
        .text-secondary {
            background-color: $text-secondary;
        }
        .text-gray {
            background-color: $text-gray;
        }
    }
}
