$primary: #000000;;
$white: #ffffff;

// Checkbox
$checkbox-label: #151515;
$checkbox-box: #000000;

// Input, TextArea, Selectbox
$input-text: #000000;
$input-placeholder: #000000;
$input-border: #000000;

// Buttons
$btn-primary-bg: #000000;
$btn-primary-text: white;

$btn-secondary-bg: white;
$btn-secondary-text: #000000;

// Texts
$text-primary: #151515;
$text-secondary: #333333;
$text-gray: #9f9f9f;

// Border
$border: #1515151a;
$borderStyle: 1px solid $border;

// Table
$table-gray: #f2f2f2;

// Gradients
$redLight: #ee4f62;
$redDark: #ee4f84;
$yellowLight: #f6de86;
$yellowDark: #e2be3d;
$blueLight: #18d1fa;
$blueDark: #000000;
$greenLight: #18fa8d;
$greenDark: #18fad1;
$purpleLight: #9768fd;
$purpleDark: #fb09e3;
$greenBlue: #00b77d;
$grayChip: #9f9f9f;

$redGradient: linear-gradient(90deg, $redLight 2.9%, $redDark 93.78%);
$yellowGradient: linear-gradient(
    64.19deg,
    $yellowLight 2.55%,
    $yellowDark 95.86%
);
$blueGradient: linear-gradient(180deg, $blueLight 5.45%, $blueDark 90.8%);
$greenGradient: linear-gradient(68.51deg, $greenLight 3.53%, $greenDark 95.59%);
$purpleGradient: linear-gradient(
    68.89deg,
    $purpleLight 5.44%,
    $purpleDark 94.17%
); ;
