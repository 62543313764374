.MuiFormControl-root {
    input,
    select,
    textarea {
        color: $input-text !important;
        &::placeholder {
            color: $input-placeholder;
        }
    }

    .MuiOutlinedInput-notchedOutline {
        border: 1px solid $input-border;
    }

    .MuiSelect-icon {
        color: $primary !important;
    }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type='number'] {
    -moz-appearance: textfield;
}

.outer-label {
    margin-bottom: 24px !important;
    margin-top: 8px !important;
}
