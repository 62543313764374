#home-wrapper {
    background-size: cover;
    overflow-x: hidden;

    #faq-link-section {
        display: flex;
        flex-direction: column;
        background-color: #000;
        justify-items: center;
        align-items: flex-start;
        padding: 40px 0px;

        h1 {
            color: #fff;
            font-size: 50px;
            padding: 8px 0;
        }

        p {
            color: #fff;
            font-size: 18px;
            font-weight: 300;
            padding: 8px 0;
        }
    }

    #explore-cta-section {
        display: flex;
        flex-direction: column;
        background-color: #000;
        padding: 80px 0px 120px;
        justify-items: center;
        align-items: center;

        h1 {
            color: #fff;
            font-size: 55px;
            line-height: 66px;
            padding-top: 40px;

            text-align: center;
            width: 60%;
        }

        h5 {
            padding: 20px 0px;
            color: #fff;
            font-family: 'Poppins';
        }

        button {
            width: 300px;
        }
    }

    .video-section {
        margin-bottom: 50px;

        .left-section {
            padding: 0px 50px;
        }

        .right-section {
            position: relative;

            .video-banner {
                border-radius: 20px;
                width: 100%;
            }

            .play-layer {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;

                .icon-play {
                    width: 100px;
                    cursor: pointer;
                }
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    #home-wrapper {
        .video-section {
            .left-section {
                h2 {
                    font-size: 20px;
                    line-height: 40px;
                    text-align: center;
                }

                padding: 0px 50px;
            }
        }

        #faq-link-section {
            align-items: flex-start;
            padding: 16px;

            p {
                line-height: 25px;
            }
        }

        #explore-cta-section {
            h1 {
                width: 100%;
            }

            h5 {
                text-align: center;
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    #explore-cta-section {
        padding: 40px 0px 80px !important;
    }
}

.homebanner-title {
    font-size: 3.8em !important;
    font-weight: 600 !important;
    line-height: normal !important;
    @media (max-width :564px) {
        text-align: center;
        font-size: 2em !important;
    }
}

.avatar-curator-banner {
    width: 6rem !important;
    height: 6rem !important;

    @media (max-width :564px) {
        width: 4rem !important;
        height: 4rem !important;
    }

}

.curatorinfo-wrapper {
    display: flex;
    justify-content: start;
    align-items: center;
    @media (max-width :564px) {
        display: flex;
        justify-content: center;
    }
}