.equaliser-container {
    height: 5px;
    width: 20px;
    margin: 0 0;
    padding: 0 0 0 0;
    position: relative;
}

.colour-bar {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 10px;
    background: $yellowGradient;
}

.equaliser-column {
    width: 3px;
    float: left;
    margin: 0 1px 0 0;
    padding: 0;
    height: 10px;
    position: relative;
    list-style-type: none;
}

.equaliser-column:nth-child(1) .colour-bar {
    animation: color-bar 2s 1s ease-out alternate infinite;
}

.equaliser-column:nth-child(2) .colour-bar {
    animation: color-bar 2s 0.5s ease-out alternate infinite;
}

.equaliser-column:nth-child(3) .colour-bar {
    animation: color-bar 2s 1.5s ease-out alternate infinite;
}

.equaliser-column:nth-child(4) .colour-bar {
    animation: color-bar 2s 0.25s ease-out alternate infinite;
}

.equaliser-column:nth-child(5) .colour-bar {
    animation: color-bar 2s 2s ease-out alternate infinite;
}

.equaliser-column:last-child {
    margin-right: 0;
}

@keyframes color-bar {
    0% {
        height: 1px;
        background: $yellowGradient;
    }
    10% {
        height: 5px;
        background: $blueGradient;
    }
    20% {
        height: 7px;
        background: $purpleGradient;
    }
    30% {
        height: 15px;
        background: $greenGradient;
    }
    40% {
        height: 7px;
        background: $redGradient;
    }
    50% {
        height: 10px;
        background: $redGradient;
    }
    60% {
        height: 10px;
        background: $yellowGradient;
    }
    70% {
        height: 7px;
        background: $purpleGradient;
    }
    80% {
        height: 11px;
        background: $greenGradient;
    }
    90% {
        height: 14px;
        background: $blueGradient;
    }
    100% {
        height: 5px;
        background: $yellowGradient;
    }
}
