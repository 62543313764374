.activity-container {
    background-color: $white;
    padding-top: 20px;
    min-height: 90vh;
    .activity-heading {
        margin: 0px 40px;
    }
    .heading {
        margin-bottom: 20px;
    }
    .activity-filter {
        overflow-y: auto;
        margin: 20px 0px 20px;
        .chips {
            margin-right: 10px;
        }
    }
}

@media only screen and (max-width: 768px) {
    .activity-container {
        .activity-heading {
            margin: 10px 20px;
        }
        .activity-filter {
            margin: 10px 0px 10px;
        }
    }
}
