#careerPage {
    .career-container {
        border: 1px solid $border;
        border-radius: 10px;
        background: $white;
        color: $text-primary !important;
        margin: 10px 0px;
    }
    .acr {
        display: block;
        p.ulli {
            line-height: 1.8;
        }
        h5 {
            font-size: 18px;
            margin-bottom: 15px;
            margin-top: 15px;
        }
    }

    .Mui-expanded {
        background-color: $white;
        color: $text-primary;
    }

    .MuiPaper-elevation1 {
        box-shadow: none !important;
    }

    .MuiAccordionSummary-root {
        padding: 15px !important;
    }

    .divider {
        padding: 0px 15px !important;
    }

    @media (max-width: 959.95px) {
        .about-banner {
            width: auto;
        }
    }
    @media screen and (max-width: 500px) {
        .helpContainer h1 {
            font-size: 22px;
        }
    }

    .faqHeading {
        font-size: 20px;
        font-weight: 600;
        color: #820101;
    }

    .Mui-selected {
        background: #232f37;
        color: white;
        .MuiSvgIcon-root {
            color: white;
        }
    }

    .helpContainer {
        top: 50%;
        left: 50%;
        position: absolute;
        transform: translate(-50%, -50%);
        padding-top: 0;
        padding-bottom: 0;
        color: white;
        font-size: 1.2em;
    }

    .MuiButtonBase-root {
        // margin-top: 20px;
    }

    .MuiAccordionDetails-root {
        padding: 20px !important;
    }
}

.myCareerForm {
    width: 600px;
    @media screen and (max-width: 500px) {
        width: 100%;
    }

    .MuiFormControl-fullWidth {
        margin-bottom: 25px;
    }
}
