.top-collections-wrapper {
    margin-bottom: 50px;
    .section-header {
        color: $text-primary;
        margin: 50px 0px;
    }

    #collections-viewall {
        a {
            margin-top: 40px !important;
            border: 1px solid $primary;
            border-radius: 30px;
            padding: 20px 50px;
            color: $primary;
        }
    }
}

.top-collections {
    .slick-slide {
        margin: 0 10px;
    }
    .slide-arrow-prev {
        left: 0px;
    }
    .top-collection {
        display: flex;
        flex-direction: column;
        align-items: center;
        border-radius: 20px;
        height: 480px;
        position: relative;
        padding: 0;
        .collection-banner {
            height: 100%;
            width: 100%;
            border-radius: 5px;
        }
        .collection-logo {
            margin-top: -25px;
            width: 60px;
            height: 60px;
            border-radius: 50%;
            z-index: 9;
        }
        .collection-name {
            font-size: 20px;
            color: white;
            margin-top: 15px;
            // white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 100%;
            text-align: center;
            padding: 8px 0;
            line-height: 20px;
        }
        .user-detail {
            display: flex;
            position: absolute;
            align-items: center;
            justify-content: space-between;
            margin-top: 15px;
            padding: 10px;
            background-color: rgba(0, 0, 0, 0.55);
            backdrop-filter: blur(5px);
            border-radius: 30px;
            left: 20px;
            .user-info {
                .title {
                    color: #ffffff;
                }
            }
            .name-section {
                display: flex;
                .avatar {
                    height: 35px;
                    width: 35px;
                    margin-right: 10px;
                    border-radius: 50%;
                }
                .user-name {
                    color: white;
                    max-width: 100%;
                    margin-right: 5px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
                .eth-address {
                    color: white;
                    max-width: 10ch;
                    margin-right: 5px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
                .icon-verified {
                    width: 20px;
                }
                .user-role {
                    color: $text-primary;
                }
            }
        }
        .item-detail {
            margin-top: 20px;
            display: flex;
            flex-direction: column;
            // align-items: center;
            position: absolute;
            background-color: rgba(0, 0, 0, 0.55);
            backdrop-filter: blur(5px);
            bottom: 0;
            height: 150px;
            width: 100%;
            border-radius: 5px;
            justify-content: space-evenly;

            .item-detail-wrapper{
                margin: 10px;
                .collection-name{
                    text-align: left;
                    height:40px;
                }

                .icon-item {
                    margin-right: 5px;
                    width: 15px;
                }
                .item-text {
                    color: $text-gray;
                }
    
                .item-detail-collections {
                    background-color: rgba(0, 0, 0, 0.85);
                    margin-top: 15px;
                    padding: 1rem;
                    border-radius: 20px;
                    align-items: center;
                    align-items: center;
                }
    
                .item-detail-favourites {
                    background-color: rgba(0, 0, 0, 0.85);
                    margin-top: 15px;
                    width: 45px;
                    padding: 1rem;
                    border-radius: 20px;
                    display: flex;
                    justify-content: center;
                }
            }
            
        }
    }
}
