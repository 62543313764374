#item-sell-initiate-container {
    background-color: $white;
    #sell-border-container {
        border: 1px solid $border;
        border-radius: 10px;
        margin-bottom: 30px;
        .title-wrapper {
            font-size: 16px;
        }
    }

    .back-header {
        margin-bottom: 20px;
        padding: 10px 10px;
        a {
            color: $primary;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            .avatar-cont {
                height: 50px;
                width: 100px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            .names {
                display: flex;
                flex-direction: column;
                justify-content: start;
                .title {
                    font-size: 26px;
                }
                .subtitle {
                    font-size: 16px;
                }
            }
        }
    }

    .left-tile {
        border-top: 1px solid $border;
        padding: 12px 18px;
        p {
            font-weight: 400;
            margin: 5px 0px 0px 0px;
        }
    }

    .sell-options {
        display: flex;
        margin-top: 20px;
        .sell-option {
            width: 200px;
            padding: 20px;
            text-align: center;
            border: $borderStyle;
            border-radius: 5px;
            margin-bottom: 20px;
            cursor: pointer;
            &:hover {
                border: 1px solid $borderStyle;
                color: $primary;
            }
            margin-right: 20px;
        }

        .active-option {
            border: 1px solid $primary;
        }
    }
}

.sale-confirmation-wrapper {
    border: 1px solid $border;
    border-radius: 12px;
}

.left-panel {
    .payment-type {
        height: 100%;
        padding: 20px;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
    }
}
