.MuiButtonBase-root.MuiChip-root {
    border: $borderStyle;
    background-color: white;
    color: $text-primary;
    &:hover {
        border: $borderStyle;
        background-color: $primary;
        color: $white;
    }
}

.MuiButtonBase-root.MuiChip-root.active-chip {
    border: $borderStyle;
    background-color: $primary;
    color: $white;
    &:hover {
        border: 1px solid $border;
        background-color: white;
        color: $text-primary;
    }
}
