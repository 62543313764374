#aboutPage {
    .about-banner {
        width: 100%;
        min-height: 400px;
    }

    .overFlowAbout {
        max-height: 100px;
        overflow: auto;
    }
    @media (max-width: 959.95px) {
        .about-banner {
            width: auto;
        }
    }
    @media screen and (max-width: 500px) {
        .overFlowAbout {
            max-height: max-content;
        }
        .teamMobileShrink {
            margin-top: 140px !important;
        }
    }

    .about-paragraph {
        line-height: 1.8;
        text-align: justify;
    }

    .meetOurTeam {
        margin-top: 50px !important;
        // padding-bottom: 140px;
    }

    .teamSubHeading {
        margin: 50px auto 0;
        color: $text-primary;
    }

    .teamPhoto {
        position: absolute !important;
        transform: translate(-50%, -50%);
        left: 50%;
        top: -10px;
        width: 200px !important;
        height: 200px !important;
    }

    .teamHeading {
        margin-top: 80px !important;
    }

    .teamIntro {
        text-align: center;
        line-height: 1.8 !important;
        margin-top: 20px !important;
    }

    .teamSocialIcon {
        color: white !important;
    }

    .teamCard {
        transition: ease 0.5s !important;
        color: white !important;
        background-color: $text-primary;
        &:hover {
            .teamSocialIcon {
                &:hover {
                    background: white;
                    color: black !important;
                }
            }
            transform: scale(1.04);
            color: white !important;
            background-color: $white;
        }
    }

    .aboutContainer {
        top: 50%;
        left: 50%;
        position: absolute;
        transform: translate(-50%, -50%);
        padding-top: 0;
        padding-bottom: 0;
        color: white;
        font-size: 1.2em;
    }
}
