.mini-player {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .play-controls {
        width: 100%;
        display: flex;
        position: absolute;
        justify-content: space-between;
        align-items: center;
        top: 0;

        .equalizer {
            margin-right: 10px;
        }
    }

    .MuiAvatar-colorDefault {
        background-color: transparent !important;
    }
}

.nft-item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background-color: #ffffff;
    // border-radius: 10px;
    border: 1px solid #000;
    width: 100%;
    // box-shadow: 0px 0px 50px #0000001a;

    // box-shadow: 0px 0px 35px #c5b9a9;
    padding: 0px !important;
    margin: auto;
    overflow: hidden;
    position: relative;

    .item-image {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        width: 100% !important;
        height: 310px !important;
        padding: 8px;
        @media (max-width : 1400px) {
           height: 290px !important;
        }
        @media (max-width : 650px) {
            height: 270px !important;
         }

        img,
        video {
            transform: scale(1);
            transition: 0.6s ease;
            object-fit: cover;
            // height: 308px !important;
            // width: 320px !important;
            // min-width: 100%;
            // max-height: 100%;
            @media(max-width: 768px){
                // width: 340px !important;
            }
            @media(max-width: 599px){
                width: 100% !important;
            }
        }

        .chip {
            position: absolute;
            bottom: 0px;
            left: 0px;
            background-color: white;
        }
    }

    .item-detail {
        display: flex;
        width: 100%;
        flex-direction: column;
        justify-content: space-between;

        .item-name {
            text-align: start;
            width: 100%;
            color: $input-text;
            font-weight: 600;
            font-size: 26.8288px;
            line-height: 39px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          
        }

        .add-fav{
            width: 6px;
            height: 5px;
            position: absolute;
            top: 5px;
            right: -15px;
        }


        .btn-small {
            padding: 0px !important;

            .MuiButton-label {
                display: flex;
                justify-content: flex-end;
            }

            .MuiButton-startIcon {
                margin-right: 0px;
            }

            &:hover {
                background-color: transparent !important;
            }
        }

        .user-detail {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin: 18px 0 8px;
            @media(max-width: 1475px){
                margin: 0;
            }

            .name-section {
                display: flex;
                align-items: center;

                div {
                    .fvc {
                        display: none;
                    }
                }

                .avatar {
                    height: 50px;
                    width: 50px;
                    margin-right: 10px;
                    border-radius: 50%;
                
                }

                .user-name {
                    max-width: 100px;
                    margin-right: 5px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    color: #151515;
                    // font-size: 14px;
                }

                .user-role {
                    font-weight: 400;
                    font-size: 19.7024px;
                    line-height: 24px;
                    color: #000;
                    font-family: 'Matter';
                    -webkit-line-clamp: 1 !important;
    overflow: hidden !important;
    -webkit-box-orient: vertical !important;
    display: -webkit-box !important;
                  
                }
            }

            .views {
                // height:15px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                flex-direction: column;

                img {
                    @media(max-width: 1475px){
                        margin: 0;
                    }
                }
            }

            .fav-section {
                display: flex;
                align-items: center;

                .icon-fav {
                    width: 15px;
                }

                .fav-text {
                    color: $text-primary;
                }
            }

            .icon-fav {
                margin-right: 5px;
            }
        }

        .auction-detail {
            border-top: 1px solid #A3A3A3;
            display: flex;
            width: 100%;
            justify-content: space-between;

            .bid-info,
            .stock-info {
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                .status {
                    font-size: 16px;
                    font-weight: bold;
                }
            }

            .stock-info {
                justify-content: center;
                padding: 25px;
                @media(max-width: 1475px){
                    padding: 23px 10px;
                }

                p {
                    font-family: 'Matter';
                    font-size: 14px;
                    @media(max-width: 1024px){
                        font-size: 13px;
                        padding: 10px 5px;
                    }
                }
            }

            .expire-info {
                display: flex;
                flex-direction: column;
                width: 90px;
                align-items: center;
                margin: auto 0;
            }

            .expire-info-2 {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
            }

            .current-bid-text,
            .end-text {
                color: $text-primary;
                opacity: 0.6;
                font-size: 14px;
                font-family: 'Matter';
            }

            .current-bid-value {
                color: $text-primary;
                font-size: 17px;
                font-family: 'Matter';
                // font-weight: 600;
            }

            .end-text {
                text-align: center;
            }

            .end-value {
                color: $text-primary;
                font-size: 14px;
                font-weight: 600;
            }
        }
    }
}

.sellimage{
    border-radius: 10px;
    box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.08);
    object-fit: contain;
}
.sellButton{
    color: #fff !important;
    font-size: 1.4rem !important;
    height: 2.6rem;
    width: 100%;
    background: #000000 !important;
    border-radius: 122.794px !important;
}
.sellItem-bid-input{
    border: none;
    text-align: end;
    // max-width:80px;
    font-size: 1.4rem !important;
    font-weight: 700 !important;
    &:focus{
        outline: none;
    }
    &:active{
        outline: none;
    }
}

.date-input{
    border: none !important;
    outline: none;
    &:focus{
        outline: none;
    }
    &:active{
        outline: none;
    }
}
.reject-bid-nft{
    width: 48%;
   border:1px solid #000000 !important;
}
.cancel-bid-btn{
    background-color: #000000 !important;
    color: #fff !important;
    font-size: 1rem !important;
    width: 100%;
}

.title-n-action-btn-Wrapper-artwork-detail{
    display: flex;
    justify-content: space-between !important;
    @media (max-width:764px) {
        flex-direction: column-reverse !important;
    }
}