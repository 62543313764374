.privacy-terms {
    li {
        margin-bottom: 5px;
        text-align: justify;
        font-weight: 300;
    }
    ol {
        margin-top: 10px;
        font-weight: 300;
    }

    .paddingSpecial {
        padding-left: 20px;
    }

    .paddingSpecial1 {
        padding-left: 30px;
    }

    p {
        margin-bottom: 10px !important;
        font-weight: 300;
    }
}
