
.app-top-header {
    padding: 0 40px 0 29px;
    background-color: #0D0D0D;
    @media(max-width: 1024px){
        padding: 0 20px;
    }
    .app-logo {
        width: 209px;
        padding: 10px 0 7px 0;
        @media(max-width: 575px){
            padding: 0;
        }
    }
    .tagline {
        font-size: 0.5rem;
    }
    .header-box {
        margin-left: 50px;
        display: flex;
        align-items: center;
        @media(max-width: 575px){
          margin-left: 0;
        }
        @media(max-width:600px){
            .top-menu{
                font-size: 1rem !important;
            }
        }
        .top-menu {
            color: $white;
        }
        .btn-menu {
            font-family: 'Matter';
            font-style: normal;
            font-weight: normal;
            font-size: 20px;
            line-height: 31px;
            padding: 33px 24px;
            color: #FFFFFF;

            @media(max-width: 1250px){
                padding: 33px 16px;
                font-size: 20px;
              }
              @media(max-width: 660px){
                padding: 20px 10px;
            //    background-color: red !important;
              }

            &:hover {
                background-color:transparent !important;
            }
        }
        .search-box {
            width: 500px;
            border-radius: 25px !important;
            .MuiOutlinedInput-root {
                border-radius: 25px !important;
            }
        }
    }

    .btn-login {
        // border: $borderStyle;
        border: none;
        border-radius: 0px !important;
    }

    .mobile-menu {
        display: none;
        align-items: center;
    }

    .black{
        fill: #FFFFFF;
    }

    .desktop-menu {

        .MuiIconButton-root:hover {
            background-color: transparent;
        }
    }

    .user-menu-list {
        background-color: #0d0d0d;
        position: absolute;
        width: 200px;
        right: 0px;
        top: 100%;
    }

    .mobile-menu-contaniner {
        display: none;
    }
}
.user-menu-list .MuiTypography-displayBlock {
    font-style: normal;
    font-weight: 400;
    font-size: 19px;
    line-height: 23px;
    color: #FFFFFF;
}
.item-search-list-container {
    position: relative;
    padding-top: 5px;

    .item-search-list {
        border-radius: 6px;
        padding: 2px;
        display: block;
        position: absolute;
        top: 58px;
        background-color: $text-secondary;
        color: $white;
        width: 100%;
        height: 250px;
        overflow-y: auto;
        z-index: 1;
        box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.25);
    }

    &::after {
        position: absolute;
        content: '';
        height: 2px;
        width: 100%;
        bottom: 0px;
        opacity: 0;
        transition: all 250ms ease-in-out;
    }
}

.gradient-header {
    background: linear-gradient(
        90deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 100%
    );
}

.dark-header {
    background: #0D0D0D;
}

@media only screen and (max-width: 1024px) {
    .app-top-header {
        position: relative;
        .app-logo {
            width: 150px;
        }
        .tagline {
            font-size: 0.4rem;
        }
        .mobile-menu {
            display: flex;
        }
        .desktop-menu {
            display: none;
        }
        .mobile-menu-contaniner {
            display: block;
            background-color: #0d0d0d;
            position: absolute;
            width: 100%;
            height: 100vh;
            top: 100%;
            right: 0px;

            .mobile-nav {
                margin: 1rem;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                a {
                    color: #fff;
                    font-size: 1rem;
                    line-height: 20px;
                    font-style: normal;
                    font-weight: 400;
                }
            }
        }
    }
}
.mobile-menu-contaniner button {
    color: #fff;
    font-size: 1rem;
    line-height: 20px;
    font-family: 'Matter';
    font-style: normal;
    font-weight: 400;
}
@media only screen and (max-width: 767px) {
    .app-top-header {
        position: relative;
        .app-logo {
            width: 120px;
        }
    }
}

.user-account-block button {
    padding: 28px 0 28px 38px;
}
@media(max-width:1250px){
    .user-account-block button {
        padding: 28px 0 28px 20px;
    }
}
@media(max-width:1024px){
    .user-account-block button {
        padding: 28px 0 28px 0;
    }
}
.user-icon{
    background-color: transparent !important;
}