.creation-bg {
    width: 100% !important;
    height: 260px !important;
}

.creation-logo {
    margin: 0 auto 24px;
    width: 130px !important;
    height: 130px !important;
    border-radius: 50%;
    margin-top: -65px;
}

.creation-info-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 450px;
    margin: 0 auto;
}

.creation-user-info-section {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 30px;
}

.creaction-thumbnail {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    margin-left: 8px;
    margin-right: 8px;
}

.check-icon {
    width: 12.8px;
    height: 12.8px;
    border-radius: 50%;
    margin-left: 8px;
}

.creation-desc {
    text-align: center;
    color: #9f9f9f;
}
