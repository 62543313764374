.primary {
    color: $primary;
}

.text-primary {
    color: $white;
}

.text-secondary {
    color: $text-secondary;
}

.text-gray {
    color: $text-gray;
}

.white {
    color: white !important;
}

.bg-white {
    background-color: $white !important;
}
