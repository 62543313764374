#faqPage {
    .faqLeft {
        border-radius: 10px;
        border: 1px solid $border;

        .list {
            padding: 20px;
            background: $white;
            color: $text-primary;
            border-radius: 10px;
        }

        img {
            width: 25px;
        }
        .MuiPaper-elevation1 {
            box-shadow: none !important;
        }

        // .list:nth-last-child(2) {
        //     border: none !important;
        //     background-color: yellow !important;
        // }
    }

    .faq-heading {
        @include playfair-bold;
    }

    .faq-content {
        color: $text-primary;
        margin-top: 15px !important;
    }

    .Mui-selected {
        background-color: transparent;
        .MuiSvgIcon-root {
            color: $primary;
        }
    }
    .divider {
        border: 1px solid $border;
    }

    .MuiListItem-button {
        padding: 15px 0px;
    }

    .faqContainer {
        box-shadow: none;
        background-color: $white;
        transition: ease 0.3s !important;
        margin-bottom: 15px;
        color: $text-primary;
        border: 1px solid $border;
        &:hover {
            box-shadow: #38373740 0px 0px 8px 0px;
            transform: scale(1.02);
        }
    }

    .helpContainer {
        top: 50%;
        left: 50%;
        position: absolute;
        transform: translate(-50%, -50%);
        padding-top: 0;
        padding-bottom: 0;
        color: white;
        font-size: 1.2em;
    }
}
