.market-place-container {
    .filter-panel {
        background-color: $white;
        // border-right: $borderStyle;
        z-index: 1;
    }

    .nft-list {
        background-color: $white;
        min-height: 100vh;
        padding: 16px;
        .selected-filters-container {
            .selected-filters {
                margin: 0px 0px 20px !important;

                .MuiChip-root {
                    margin-right: 20px;
                }
            }
        }
    }
}
