.profile-wrapper {
    background: #ffffff;
    // padding-top: 70px;
    padding: 94.68px 0 0 0;
    @media(max-width: 1600px){
        padding: 70px 0 0 0 !important;
    }
    @media(max-width: 991px){
        padding: 40px 0 0 0 !important;
    }
    @media(max-width: 575px){
        padding: 20px 0 0 0 !important;
    }

    .profile-card {
        padding: 32px;
        // background: #ffffff 0% 0% no-repeat padding-box;
        // box-shadow: 0px 0px 35px #0000001a;
        // border-radius: 10px;

        .card-header {
            display: flex;
            margin-bottom: 20px;
            align-items: center;
        }

        .card-header-btn {
            display: flex;
            justify-content: space-between;
            margin-bottom: 20px;
            align-items: center;
        }

        .title {
            font-weight: 400;
            color: $text-primary;
        }

        .subtitle {
            margin-top: 15px;
            font-weight: 400;
            color: $text-secondary;
            font-family: 'Matter' !important;
        }

        .details {
            font-weight: 500;
            font-family: 'Matter';
        }

        .addressBtn {
            background-color: $input-text;
            color: #fff;
            font-family: 'Matter';
            @media(max-width: 600px){
                // padding: 10px !important;
            }
            span{
                span{
                    font-size: 12px;
                }
            }
        }
    }

    #top-panel {
        border-bottom: 1px solid $border;
        position: relative;
        background-color: #bdbdbd6b;

        .user-bg {
            width: 100%;
            height: 250px;
            position: relative;
        }

        .no-image {
            display: flex;
            justify-content: center;
            align-items: center;

            img {
                width: 50px;
                height: 50px;
            }
        }

        .cover-trigger {
            position: absolute;
            right: 10px;
            top: 10px;
            cursor: pointer;
            background-color: $primary;
            padding: 12px;
        }
    }

    .left-panel {
        background: #ffffff 0% 0% no-repeat padding-box;
        border: 10px solid #ffffff;
        border-radius: 50%;
        opacity: 1;
        backdrop-filter: blur(50px);
        -webkit-backdrop-filter: blur(50px);
        width: fit-content;
        position: relative;
        // margin-top: -80px;

        .active-link .MuiTypography-root {
            color: $primary  !important;
        }

        .left-panel-top-menu {
            padding: 20px 20px 0px 20px;
        }

        .profile-pic-container {
            display: flex;
            justify-content: center;
            position: relative;

            .profile-pic {
                position: relative;
                div{
                    background-color: #000000 !important;
                    width: 118.68px;
                    height: 118.68px;
                    // @media(max-width: 1600px){
                    //     width: 180px;
                    // height: 180px;
                    // }
                    // @media(max-width: 991px){
                    //     width: 150px;
                    // height: 150px;
                    // }

                }

                // .MuiAvatar-root {
                //     width: 200px;
                //     height: 200px;
                // }
            }

            .profile-trigger {
                cursor: pointer;
                top: 0px;
                right: -15px;
                position: absolute;
                background-color: #9f9f9f;
                color: $primary;
                padding: 12px;
            }
        }

        .profile-menus {
            padding: 0px 20px;

            .MuiListItem-root {
                border-bottom: $borderStyle;
                padding: 10px 0px;
            }
        }
    }

    .nft-menus {
        margin: 0;
        // margin-top: 97px;
        padding-top: 70px;
        border-top: 1px solid #000000;
        width: 20%;
        @media(max-width: 1600px){
            padding-top: 40px;
        }
        @media(max-width: 1440px){
            width: 20%;
        }
        @media(max-width: 991px){
            width: 28%;
            padding-top: 30px;
            // margin-top: 75px;
        }

        .nft-menu {
            color: $text-primary;
            padding: 13px 0 13px 118px;
            cursor: pointer;
            font-size: 17px;
            font-family: 'Matter';
            font-weight: 400 !important;
            margin-bottom: 8px;
            @media(max-width: 1800px){
                // font-size: 18px;
            }
            @media(max-width: 1600px){
               padding: 13px 0 13px 100px;
            }
            @media(max-width: 1200px){
                padding: 10px 0 10px 70px;
                font-size: 14px;
            }
            @media(max-width: 991px){
                padding: 10px 0 10px 50px;
            }
            @media(max-width: 991px){
                
            }
            @media(max-width: 768px){
                padding: 10px 0 10px 40px !important;

            }
        }

        .nft-menu:hover,
        .active-menu {
            color: #000000;
            // border-radius: 2.5px;
            font-weight: 500;
            background-color: rgba(217, 217, 217, 0.37);
            // border-bottom: 3px solid $primary;
        }
    }

    .follow-list {
        li {
            display: flex;
            align-items: center;

            .MuiListItemSecondaryAction-root {
                position: static;
                transform: none;
            }

            .MuiListItemText-root {
                cursor: pointer;
            }
        }
    }
}

// My Profile Changes

.left-panel-main {
    height: 250px;
    @media(max-width: 768px){
        height: 150px;
    }
}

.sub-cards-wrapper {
    padding: 0 !important;
    @media(max-width: 599px){
        padding: 10px 0 0 !important;
    }
}

.sub-cards {
    // background-color: #f2f2f2;
    // border-radius: 3px;
    // padding: 10%;
    // margin-bottom: 37px !important;

    p {
        font-weight: 400;
        color: $input-text;
        font-family: 'Matter';
        font-size: 17px;
        margin-bottom: 8px;
        @media(max-width: 600px){
            font-size: 15px;
        }
    }
}

.sub-cards-value {
    font-weight: 400;
    color: $input-text;
    font-family: 'Matter';
    font-size: 17px;
    @media(max-width: 600px){
        font-size: 15px;
    }
}

#mynft-cardmain {
    margin: 0px auto;
    justify-content:space-between;
    // margin-left: 10%;

    .mynft-card {
        display: grid;
        align-content: end;

        .header {
            background-color: $input-text;
            padding: 8%;
        }

        .nft-avatar {
            margin-top: -14%;
            width: 70px;
            height: 70px;
            border: 4px solid #ffffff;

            img {
                width: 70px;
                height: 70px;
            }
        }

        .arrow-btn {
            border-radius: 25px;
            border: 1px solid $input-text;
            padding: 12px;
        }
    }
}

#interested-div {
    margin: 0 auto;
    justify-content: center;
    margin-left: 10%;

    .main {
        background: rgb(255, 245, 240);
        background: linear-gradient(90deg,
                rgba(255, 245, 240, 1) 0%,
                rgba(244, 244, 246, 1) 100%);
    }

    .interested {
        width: 100%;
        margin: 0 auto;

        // padding: 2%;
        .iconsvg-div {
            display: flex;
            align-items: center;
            justify-content: center;

            .icon-svg {
                width: 35px;
                height: 35px;
                padding: 5%;
            }
        }

        .text {
            display: grid;
            align-content: center;
            justify-content: space-between;

            .thank-you {
                display: flex;
            }
        }

        .animated {
            -webkit-animation-duration: 2s;
            animation-duration: 2s;
            -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
            // display: none;
        }

        @-webkit-keyframes fadeOut {
            0% {
                opacity: 1;
            }

            100% {
                opacity: 0;
            }
        }

        @keyframes fadeOut {
            0% {
                opacity: 1;
            }

            100% {
                opacity: 0;
            }
        }

        .fadeOut {
            -webkit-animation-name: fadeOut;
            animation-name: fadeOut;
            // display: none;
        }
    }

    // #wallet-interest { display: block; padding: 2%;}
    // #thanks-div { display: none; }
}

// View Profile CSS changes

.view-profile {
    justify-content: space-between !important;
    align-items: center;
    display: flex;
    padding-right: 32px;
    margin-bottom: 0 !important;
    position: relative;
    @media(max-width: 768px){
        padding-right: 0;
    }

    h3 {
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 98.9%;
        position: absolute;
        // top: -7%;
        left: -20%;
        bottom: 20px;
        @media(max-width: 1600px){
            // top: -7%;
            // left: 60px;
        }
        @media(max-width: 1200px){
            // left: 40px;
        }
        @media(max-width: 991px){
            left: -32%;
            font-size: 20px;
        }
        @media(max-width: 768px){
            // top: -9%;
            left: 3%;
            bottom: 45px;
        }
        @media(max-width: 575px){
            // left: 10px;
        }
       
    }

    .edit-btn {
        padding: 10px 30px;
        background-color: #000000;
        @media(max-width: 768px){
            padding: 8px 25px;
        }

        &:hover {
            background-color: #000000 !important;
        }
    }
}

// Bank Accounts CSS
.primary-btn {
    width: 200px;
    background-color: $input-text !important;
    color: #fff;
}

#textsmall {
    font-size: small;
    font-weight: 600;
    font-family: Poppins;
    // margin-top: 15px;
}

.myofferbg {
    background-color: #f5f5f5;
}

@media (min-width: 300px) and (max-width: 768px) {
    .profile-wrapper {
        padding: 5% 3%;

        .profile-card {
            padding: 10px;

            .title {
                font-size: 20px;
            }
        }

        #top-panel {
            .user-bg {
                width: 100%;
                height: 100px;
                position: relative;
            }
        }

        .left-panel {
            border: 5px solid #ffffff;

            .profile-pic-container {
                display: flex;
                justify-content: center;
                position: relative;

                .profile-pic {
                    position: relative;

                    .MuiAvatar-root {
                        width: 85px;
                        height: 85px;
                    }
                }

                // .profile-trigger {}
            }
        }

        #mynft-cardmain {
            margin-left: 0;
        }

        .nft-menus {
            padding: 0;
            width: 100%;
            overflow-x: scroll;
            display: -webkit-inline-box;
            border-top: none;
            border-bottom: 1px solid #000000;
            margin-top: 0;

            .nft-menu {
                margin: 0 3px;
                // padding: 10px 20px;
                font-size: 14px;
            }
        }
    }

    #interested-div {
        margin-left: 0;

        .interested {
            width: inherit;
            padding: 5%;

            .iconsvg-div {
                justify-content: left;
                margin-bottom: 5%;

                .icon-svg {
                    padding: 0;
                }
            }

            .text {
                .thank-you {
                    display: grid;
                }
            }
        }
    }
}