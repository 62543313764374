.footer-wrapper-contant{
    background-color: #000;
    padding: 36px 40px;
    @media(max-width: 600px){
        padding: 30px 20px;
    }
    .policy-info-wrapper {
        padding-left: 119px;
        width: 40%;
        @media(max-width: 1199px){
            width: 20%;
            padding-left: 20px;
        }
        @media(max-width: 990px){
            padding-left: 0px;
        }
        img{
            @media(max-width: 1199px){
                width: 190px;
            }
            @media(max-width: 990px){
                width: 170px;
            }
        }
    
        .copy-right-section {
            display: flex;
            flex-direction: column;
            justify-content: center;
            text-align: center;
        }
    
        .policy-links-section {
            display: flex;
            justify-content: center;
            flex-direction: row;
            width: 100%;
            margin-top: 20px;
    
            .footer-link {
                margin-left: 11px;
            }
        }
    }
    .footer-navlink{
        padding-top: 63px;
        @media (max-width: 990px) {
            padding-top: 20px;
        }   
        .footer-wrapper{
            .link-section{
                @media(max-width: 990px){
                    margin-bottom: 30px;
                }
            }
        }
    }
    @media only screen and (min-width: 991px) {
        .copy-right-section {
            display: flex;
            flex-direction: row !important;
            margin-top: 20px;
    
            .footer-link {
                // margin-right: 30px;
                font-size: 12px;
                color: #9f9f9f;
            }
        }
    
        .policy-info-wrapper {
            flex-direction: column;
            // justify-content: center;
        }
        .policy-links-section {
            // justify-content: flex-start;
            // width: fit-content !important;
            // margin: 10px 0px !important;
    
            .follow-us-label {
                font-size: 14px;
                color: $text-primary;
                text-align: start;
                font-weight: 600;
            }
    
            // a {
            //     &:last-child {
            //         margin-left: 40px;
            //     }
            // }
        }
    }
}
