.accountlist-container {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.withdraw-container {
    width: 100%;
}

.amount-wrapper {
    margin-top: 20px;
    position: relative;
}

#wallet-fantico, #wallet-fiat {
    color: $input-text;
    font-weight: 600;
    font-size: large;
    font-family: 'Matter';
}

#wallet-address {
    color: #000;
    font-weight: 400;
    font-size: 16px;
    font-family: 'Matter';
    @media (max-width : 768px) {
        font-size: 12px;
    }
}

#balance {
    font-weight: 600;
    font-family: 'Matter';
}

.primary-btn {
    width: 200px;
    background-color: $primary;
    color: #fff;
}
