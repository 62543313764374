#contactPage {
    textarea {
        min-height: 100px;
    }

    .contact-wrapper {
        border: 1px solid $border;
        border-radius: 10px;
        background: $white;
    }

    .form-card {
        // @extend .contact-wrapper;

        .MuiCardContent-root {
            padding: 0px 16px !important;
        }
    }

    .contact-container {
        .customCard {
            @extend .contact-wrapper;
            padding: 3px 8px !important;
            margin-bottom: 20px;
            transition: all 0.5s;
            color: $text-primary !important;
        }
    }
    .MuiPaper-elevation1 {
        box-shadow: none !important;
    }

    .customCard:hover a {
        color: white !important;
    }

    .socialIcons a {
        color: black;
        display: inline-block;
        // padding:10px;
        width: 50px;
        padding: 0 4px;
        padding-top: 10px;
        font-size: 1.5rem;
        &:hover {
            opacity: 0.6;
        }
    }

    .acr {
        display: block;
        p.ulli {
            line-height: 1.8;
        }
        h5 {
            font-size: 18px;
            color: #4c8405;
            margin-bottom: 15px;
        }
    }

    .helpContainer {
        top: 50%;
        left: 50%;
        position: absolute;
        transform: translate(-50%, -50%);
        padding-top: 0;
        padding-bottom: 0;
        color: white;
        font-size: 1.2em;
    }
}

.myCareerForm {
    width: 100%;
    @media screen and (max-width: 500px) {
        width: 100%;
    }
}
