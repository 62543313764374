.video-player {
    width: 100%;
    .play-controls {
        background-color: rgba($color: $text-secondary, $alpha: 0.8);
        border-radius: 8px;
        width: calc(100% - 40px);
        display: flex;
        position: absolute;
        justify-content: space-between;
        align-items: center;
        bottom: 20px;
    }
    .MuiLinearProgress-barColorPrimary {
        border-radius: 2px;
    }
    .duration {
        color: white;
    }
    .MuiLinearProgress-root {
        margin: 0px 10px;
        flex: 1;
        border-radius: 2px;
    }
    .MuiAvatar-root {
        cursor: pointer;
        width: 30px !important;
        height: 30px !important;
    }
    .MuiAvatar-colorDefault {
        svg {
            width: 20px;
        }
        background-color: transparent !important;
    }

    video {
        max-width: 100%;
        max-height: 500px;
        position: relative;
    }
}
