.referral-body-container {
    font-family: 'Poppins';
    .referral-body {
        font-size: 1.2em;

        .referral-page-link {
            color: $primary;
        }
    }
    #referral-button {
        margin: 0 auto;
        text-align: center;

        button {
            width: 180px;
            font-size: 1.2em;
            font-family: 'Poppins' !important;
            background-color: $primary;
            color: #fff;
            border: none;
            padding: 1rem 2rem;
            border-radius: 5px;
            cursor: pointer;
        }
    }

    #referral-footer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        font-size: x-small;
    }
}

@media only screen and (max-width: 767px) {
    .referral-body-container {
        .referral-body {
            font-size: 0.8em;
        }
        #referral-footer {
            flex-direction: column;
            text-align: 'center';
        }
    }
}
