@import '../theme/Colors.scss';

.flex {
    display: flex;
}

.fvc {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.flex-row {
    display: flex;
    flex-direction: row;
}

.flex-column {
    display: flex;
    flex-direction: column;
}

.justify-center {
    display: flex;
    justify-content: center;
}

.align-center {
    display: flex;
    align-items: center;
}

.center-content {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.start-content {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.empty-content {
    height: 300px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.end-content {
    display: flex;
    justify-content: flex-end !important;
}

.space-content {
    display: flex;
    justify-content: space-between;
}

.align-content {
    display: flex;
    align-items: center;
}

.trans-3d-hover:not(.no-animate) {
    cursor: pointer;
    // &:hover {
    //     margin-top: -4px;
    //     margin-bottom: 4px;
    //     transition: 0.2s;
    //     transition-timing-function: ease-out;
    // }
}

.input-split {
    padding-left: 10px;
    border: 0;
    background-color: transparent !important;
    &:first-child {
        padding-left: 0px !important;
    }
}

.input-otp {
    width: 1.75rem !important;
    height: 32px !important;
    text-align: center;
    border: 1px solid $text-primary;
    outline: none;
    color: $white;
    font-size: 14px;
    background-color: transparent !important;
}

.no-padding {
    padding: 0px !important;
}

.display-none {
    display: none;
}

.cursor {
    cursor: pointer;
}
