.trending-auctions-wrapper {
    margin-bottom: 50px;
    .section-header {
        // color: white;
        margin-bottom: 50px;
    }

    #trending-auctions-viewall {
        display: flex;
        justify-content: center;

        a {
            border: 1px solid $primary;
            margin-top: 40px;
            border-radius: 30px;
            padding: 20px 50px;
            color: $text-primary;
        }
    }
}

.trending-auctions {
    .slick-slide {
        margin: 0 10px;
    }
    .slide-arrow-prev {
        left: 0px;
    }
    .trending-auction {
        display: flex;
        flex-direction: column;
        // align-items: center;
        background-color: white;
        // border-radius: 20px;
        // height: 200px;
        // max-width: 300px;
        height: 400px;

        box-shadow: 0px 0px 35px #0000001a;

        .item-image {
            position: relative;
            height: 100%;
            align-items: center;
            display: flex;
            // width: 50%;
            overflow: hidden;
            object-fit: cover;
            img {
                // border-radius: 14px;
                width: 100%;
                max-width: 100%;
                max-height: 100%;
                transform: scale(1);
            }
            .chip {
                position: absolute;
                bottom: 0px;
                left: 0px;
                background-color: $white;
            }
        }
        .item-detail {
            // width: 50%;
            // margin-left: 16px;
            display: flex;
            height: 100%;
            flex: 1;
            flex-direction: column;
            justify-content: space-between;
            background-color: white;
            padding: 8px;
            .item-name {
                color: $text-primary;
                max-height: 40px;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                line-height: 20px;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            .user-detail {
                display: flex;
                width: 100%;
                flex-direction: column;
                margin-top: 10px;
                .name-section {
                    display: flex;
                    width: 100%;
                    .avatar {
                        height: 35px;
                        width: 35px;
                        margin-right: 10px;
                        border-radius: 50%;
                    }
                    .user-name {
                        color: $text-primary;
                        max-width: 105px;
                        margin-right: 5px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        max-height: 45px;
                    }

                    .user-role {
                        color: $text-primary;
                    }
                }
                .fav-section {
                    display: flex;
                    align-items: center;
                    margin-top: 10px;
                    .icon-fav {
                        width: 15px;
                    }
                    .fav-text {
                        color: $text-primary;
                    }
                }
                .icon-fav {
                    margin-right: 5px;
                }
            }
            .auction-detail {
                border-top: 1px solid $border;
                display: flex;
                width: 100%;
                justify-content: space-between;
                .bid-info,
                .expire-info {
                    display: flex;
                    flex-direction: column;
                }
                .expire-info {
                    width: 90px;
                }

                .bid-info {
                    justify-content: flex-start;
                }
                .current-bid-text,
                .end-text {
                    color: $text-primary;
                    font-size: 12px;
                }
                .current-bid-value {
                    color: $text-primary;
                    font-size: 17px;
                    margin: 8px 0;
                }
                .end-text {
                    text-align: left;
                }
                .end-value {
                    color: $text-primary;
                    font-size: 18px;
                    margin: 4px 0;
                }
            }
        }

        &:hover {
            .item-image {
                img {
                    transform: scale(1.1);
                    transition: all 0.3s;
                }
            }
        }
    }
}

@media only screen and (max-width: 600px) {

    .trending-auctions-wrapper{
        .trending-auctions{
            padding: 16px;
        }
    }
}