.slider-arrow {
    background-color: $primary;
    color: white;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 9;
}

.slide-arrow-next {
    right: -4px;
    top: calc(100% / 2 - 24px);
    @media (max-width : 1024px) {
        width : 40px;
        height : 40px;
    }
}

.slide-arrow-prev {
    transform: scaleX(-1);
    left: -22px;
    top: calc(100% / 2 - 24px);
    @media (max-width : 1024px) {
        width : 40px;
        height : 40px;
    }
}

.slick-dots li.slick-active button:before {
    content: '';
    border: 1px solid $primary;
    opacity: 1;
    background-color: $primary;
    width: 7px !important;
    height: 7px !important;
    border-radius: 7px !important;
}
.slick-dots li button:before {
    content: '';
    border: 1px solid white;
    opacity: 1;
    background-color: $text-gray;
    width: 7px !important;
    height: 7px !important;
    border-radius: 7px !important;
}
