.footer-content{
    // display: flex;
    // align-items: center;
    // justify-content: space-between;
    border-top:  0.80135px solid #E3E3E3;

    @media(max-width: 990px){
        padding-top: 50px;
        flex-direction: column;
        align-items: flex-start;
    }
    @media(max-width: 575px){
        padding-top: 20px;
    }
    .footer-wrapper {
        .footer-container {
            display: flex;
            flex-direction: column;
            padding-bottom: 40px;
        }
    
        .section-header {
            color: $white;
            margin-bottom: 35px;
            font-size: 25px;
            line-height: 18px;
            font-weight: 400;
            font-family: 'Matter';
            @media(max-width: 990px){
                margin-bottom: 22px;
                font-size: 22px;
            }
        }
        .subscription-msg {
            color: $text-primary;
            margin-top: 16px;
            line-height: 22px;
        }
        .search-box {
            display: flex;
            align-items: center;
            border: 1px solid $primary;
            padding: 5px 20px;
            margin-right: 0px !important;
            margin-top: 24px;
            border-radius: 0px;
            width: 300px;
    
            img {
                width: 20px;
                color: white;
                margin-right: 20px;
            }
    
            input {
                width: 220px;
                padding-right: 30px;
                color: $text-primary;
                ::placeholder {
                    color: white !important;
                }
            }
        }
    
        .search-box-lg {
            display: flex;
            flex-direction: column;
            align-items: center;
            .search-box {
                margin-top: 24px;
                flex-grow: 1;
                box-shadow: none;
            }
            .sub-btn {
                margin-top: 14px;
                padding: 12px 24px !important;
                height: 41px;
                width: 100%;
                font-weight: 400;
                border-radius: 0px;
                margin-bottom: 10px;
                line-height: 18px;
                border: none;
                cursor: pointer;
                &:hover {
                    transition: none !important;
                }
            }
        }
    
        .subscribe-btn {
            margin-top: 16px;
        }
    
        .link-title {
            color: $white;
            font-size: 25px;
            font-weight: 400;
            font-family: 'Matter';
            margin-bottom: 30px;
            @media(max-width: 990px){
                font-size: 22px;
                margin-bottom: 22px;
            }
        }
    
        a {
            text-decoration: none;
        }
    
        .footer-links {
            text-align: left;
        }
    
        .footer-link {
            color: $white;
            margin-top: 16px;
            line-height: 16px;
            font-family: 'Matter';
            @media(max-width: 990px){
                line-height: 14px;
            }
        }
        .policy-links-section {
            .footer-link {
                @media(max-width: 575px){
                    // margin-left: 16px;
                    &:first-child {
                        margin-left: 0px !important;
                    }
                }
            }
        }
    
        .footer-links-box1 {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            margin-top: 40px;
            margin-bottom: 32px;
    
            // .link-section {
            //     min-width: 50%;
            // }
    
            .footer-link {
                // border-bottom: 1px solid #8522fa;
                width: fit-content;
            }
        }
    
        hr {
            margin: 0px;
        }

        @media (max-width: 1199px) {
            .policy-info-wrapper {
                img{
                    width: 190px;
                }
            }
        }
    
        @media only screen and (min-width: 575px) {
            .copy-right-section {
                display: flex;
                flex-direction: row !important;
    
                .footer-link {
                    margin-right: 30px;
    
                    &:last-child {
                        margin-right: 0px !important;
                    }
                }
            }
    
            .policy-links-section {
                text-align: center !important;
    
                .footer-link {
                    margin-left: 26px !important;
                    &:first-child {
                        margin-left: 0px !important;
                    }
                }
            }
    
            // .link-section {
            //     min-width: 33.3% !important;
            // }
        }
    
        @media only screen and (min-width: 991px) {
            .search-box-lg {
                flex-direction: row;
                align-items: center;
    
                .search-box {
                    margin-top: 24px;
                    flex-grow: 1;
                    box-shadow: none;
                }
                .sub-btn {
                    margin-top: 24px;
                    width: 200px;
                    margin-bottom: 0px;
                    line-height: 18px;
                    border: none;
                    cursor: pointer;
                    &:hover {
                        transition: none !important;
                    }
                }
            }
    
            .footer-container {
                flex-direction: row;
                justify-content: space-evenly;
            }
    
            // .subscription-section {
            //     width: 50%;
            //     padding-right: 40px;
            // }
    
            // .footer-links-box1 {
            //     width: 50%;
            // }
    
            .link-section {
                // min-width: 40% !important;
                &:last-child {
                    margin-top: 0px !important;
                    min-width: 0% !important;
                }
            }
            .policy-info-wrapper {
                flex-direction: row;
                justify-content: space-between;
            }
            .policy-links-section {
                justify-content: flex-start;
                width: 100% !important;
    
            }
        }
    }
}
