.upload-wrapper {
    width: 124px;
    height: 124px;
    background-color: $primary;
    border-radius: 50%;
    padding: 0px;
    @media (max-width:991px) {
        width: 100px;
        height: 100px;
    }

    .upload-container {
        width: 124px;
        height: 124px;
        border-radius: 50%;
        border: 1px dashed $border;
        padding: 8px;
        @media (max-width:991px) {
            width: 100px;
            height: 100px;
        }

        label {
            width: 100%;
            height: 100%;
            // cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;

            img {
                max-width: 100%;
                max-height: 100%;
                position: absolute;
                border-radius: 50%;
            }
        }
    }
    .preview-img-container {
        width: 104px;
        height: 104px;
        border-radius: 12px;
        border: 1px dashed $border;
        padding: 8px;
        img {
            max-width: 100%;
            max-height: 100%;
            position: absolute;
            border-radius: 8px;
        }
    }
}

.upload-div {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.upload-text {
    color: #ffffff;
    margin-left: 10px !important;
}

.change-uploadtext {
    color: $primary;
}

.upload-btn {
    background-color: $primary;
    color: #ffffff;
    padding: 3%;
    margin: 0 5%;
    display: flex;
    align-content: center;
    justify-content: center;
    // width: 150px;
    border-radius: 3px;
}

.kyc-status {
    position: relative;
    p {
        position: absolute;
        left: 0;
        z-index: 1;
        top: 59px;
        width: 100%;
        font-weight: 400 !important;
        color: #e63148;
    }
    .kyc-status-avatar {
        width: 40% !important;
        height: 40% !important;
        margin: auto;
    }
}

.doc-upload-container {
    width: 160px;
    height: 160px;
    border: 1px dashed $border;
    border-radius: 12px;
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width:991px) {
        width: 120px;
        height: 120px;
    }

    img {
        max-width: 100%;
        max-height: 100%;
        position: relative;
        border-radius: 8px;
    }
}

// KYC Verification Section CSS

.kyc-verification {
    .small-text {
        font-size: 12px;
    }

    .kyc-proofdiv {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .kyc-label {
        font-weight: 500;
    }

    .kyc-label span {
        color: $primary;
    }

    .kyc-dropdown {
        padding: 5%;
    }
    .uploaded-docdiv {
        display: flex;
        align-items: center;
        // justify-content: space-between;
        .uploaded-doc {
            width: 100px;
            height: auto;
            border-radius: 5px;
        }
    }

    .verify-btn {
        width: 150px;
        background-color: $primary;
        color: #fff;
    }
    
}

@media (min-width: 300px) and (max-width: 768px) {
    .upload-btn {
        margin: 0;
        margin-right: 5%;
    }
}