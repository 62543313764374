.pdfview {
    box-shadow: 0 30px 40px 0 rgba(16, 36, 94, 0.2);
    border-radius: 8px;
    display: block;
    user-select: none;
    width: auto !important;
    height: auto !important;
    border-radius: 8px;
}

.pdf-page-controls {
    position: absolute;
    bottom: 5%;
    left: 50%;
    background-color: rgb(230, 230, 230);
    opacity: 1;
    transform: translateX(-50%);
    transition: opacity ease-in-out 0.2s;
    box-shadow: 0 30px 40px 0 rgba(16, 36, 94, 0.2);
    border-radius: 4px;
}

.pdf-page-controls button:enabled:hover,
.pdf-page-controls button:enabled:focus {
    background-color: rgb(204, 204, 204);
}

.pdf-page-controls button:disabled:hover,
.pdf-page-controls button:disabled:focus {
    background-color: rgb(204, 204, 204);
}

.pdf-page-controls button:enabled:hover {
    cursor: pointer;
}

.pdf-page-controls button:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.pdf-page-controls button {
    width: 44px;
    height: 44px;
    background-color: rgb(230, 230, 230);
    border: 0;
    font: inherit;
    font-size: 0.8em;
    border-radius: 4px;
    &:disabled {
        background-color: rgb(204, 204, 204) !important;
    }
}

.pdf-page-controls-pdf {
    position: absolute;
    left: 50%;
    opacity: 1;
    transform: translateX(-50%);
    transition: opacity ease-in-out 0.2s;
    box-shadow: 0 30px 40px 0 rgba(16, 36, 94, 0.2);
    border-radius: 4px;
    margin-top: -55px;
}

.pdf-page-controls-pdf button {
    width: 44px;
    height: 44px;
    border: 0;
    font: inherit;
    font-size: 0.8em;
    border-radius: 4px;
}

.pdf-page-controls-pdf button:enabled:hover {
    cursor: pointer;
}

.pdf-page-controls-pdf button:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

#my-pdf {
    width: 100% !important;
    min-height: 100% !important;
    overflow-inline: initial !important;
}

.pdf-card {
    width: 150px;
    height: 150px;
    background-color: grey;
}
