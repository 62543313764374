.signInBtn {
    background-color: #D9D9D9!important;
    border-radius: 0!important;
    padding: 20px 0!important;
    box-shadow: none !important;
    @media(max-width: 600px){
        padding: 15px 0 !important;
    }
}
.auth-bg {
    display: flex;
    justify-content: center;
    // min-height: calc(100vh - 140px);
    background-color: #F5F5F5;
}

.input-otp {
    border-radius: 3px !important;
    border: 1px solid $input-border !important;
    padding: 10px;
    color: $input-text !important;
    &::placeholder {
        color: $input-text !important;
    }
}

@media only screen and (max-width: 600px) {
    .auth-content {
        width: 300px !important;
    }
    .captcha-sm {
        transform: scale(0.8);
        -webkit-transform: scale(0.8);
        transform-origin: 0 0;
        -webkit-transform-origin: 0 0;
    }
}

.text-underline {
    text-decoration: underline !important;
}
