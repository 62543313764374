.action-timer {
    display: flex;
    margin-top: 10px;
    .timer-section {
        margin-right: 20px;
    }
    .text-secondary {
        font-weight: 300;
        font-size: 12px;
    }
}
