.checkout-container {
    padding-bottom: 20px;
    min-height: 400px;
    
    .payment-mode,
    .nft-section,
    .left-panel .right-panel {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .nft-section {
        border-right: $borderStyle;
        padding-right: 50px !important;
        .nft-preview,
        .nft-offer-preview {
            margin-top: 20px;
            margin-bottom: 20px;
            display: flex;
            .nft-image {
                width: 100px;
                max-height: 100px;
                margin-right: 20px;
                margin-bottom: 20px;
            }
            .qty-input {
                width: 150px;
                margin-left: 20px;
            }
            .nft-detail {
                flex: 1;
                display: flex;
                flex-direction: column;
                justify-content: column;
            }
        }
        .nft-detail {
            flex: 1;
        }
    }
    .right-panel {
        padding-left: 50px !important;
    }
    .select-edition {
        max-width: 10em;
    }

    #checkout-success-options {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        margin-top: 30px;

        #view-nft-button a,
        #marketplace-button a {
            background-color: #06010b;
            color: #fff;
        }

        #view-nft-button a {
            width: 160px;
        }
    }
}

@media only screen and (max-width: 960px) {
    .checkout-container {
        .select-edition {
            max-width: 25em;
        }
    }
}

@media only screen and (max-width: 768px) {
    .checkout-container {
        .nft-section {
            border-right: none !important;
            padding-right: 0px !important;
        }
        .left-panel {
            border-bottom: $borderStyle;
        }
        .right-panel {
            padding-left: 10px !important;
        }
        .select-edition {
            max-width: 25em;
        }

        #checkout-success-options {
            flex-direction: column;
            gap: 1rem;
            #view-nft-button a,
            #marketplace-button a {
                width: 100%;
            }
        }
    }
    .nft-preview,
    .nft-offer-preview {
        flex-direction: column;
    }
}
