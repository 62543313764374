.create-wallet-card {
    padding: 20px;
    background-color: $white;
    border-radius: 15px !important;
}

.wallet-box {
    height: 250px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    .app-logo {
        max-width: 80%;
    }
}

.create-wallet-card-logo-image {
    width: 200px !important;
}

.create-button-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
}
