.banner-wrapper {
    // padding-top: 102px;
    @media(max-width: 1199px){
        // padding-top: 60px;
    }
    @media(max-width: 991px){
        // padding-top: 50px;
    }
    @media(max-width: 768px){
        // padding-top: 40px;
    }
    // height: 600px;

    #banner-details {
        .MuiTypography-body1 {
            font-size: 20px;
            font-weight: 300;
            padding-right: 10px;
            line-height: 27px;
        }

        .collage-mobile {
            // display: none;

            // width: 100px;
            // height: 100px;
            display: flex;
            align-items: center;
            // justify-content: center;
        }
    }

    h5 {
        font-size: 20px;
        text-align: left;
        line-height: 30px;
    }

    h4,
    h1 {
        color: $text-primary;
        text-align: left;
        font-size: 3.7rem;
        line-height: 80px !important;
    }

    .hero-cta {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        // width: 85%;

        #hero-button {
            // width: 360px;
            border-radius: 0px;
            // background-color: $btn-primary-bg;
            color: $input-text;
            font-weight: 400;
            font-size: 20px;
            line-height: 41px;
            font-family: 'Matter';
            span{
                img{
                    width: 16px;
                    height: 16px;
                }
            }
        }

        #learnMore {
            font-family: 'Poppins';
            font-size: 18px;
            color: $primary;
        }
    }

    .collage {
        width: 100%;
        text-align: center;

        ul {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
            padding-top: 0;
        }

        li {
            margin: 20px 0px 20px 0px;

            &.li-no-style {
                list-style-type: none;
                height: 350px;
            }
        }

    }
}

@media only screen and (max-width: 959px) {
    .banner-wrapper {
        height: 100%;

        #banner-details {
            .MuiTypography-body1 {
                font-size: 20px;
                font-weight: 300;
                text-align: center;
                padding-right: 0;
                line-height: 25px;
            }

            .collage-mobile {
                justify-content: center;
                flex-direction: column;
                align-items: center;

                img {
                    max-width: 95vw;
                    max-height: 60vh;
                    object-fit: cover;
                }
            }
        }

        h1 {
            font-size: 35px;
            line-height: 44px !important;
            text-align: center;
        }

        h5 {
            font-size: 15px;
            line-height: 27px !important;
            text-align: center;
        }

        .hero-cta {
            flex-direction: column;
            width: 100%;
            margin-top: 10px;

            #learnMore {
                margin-top: 20px;
            }
        }

        
    }
}