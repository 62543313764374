.utility-text {
    text-align: center;
    padding-top: 5rem;
    margin-top: -15rem !important;
    padding-bottom: 2rem;
}

#banner-video::before {
    content: ' ';
    position: absolute;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.45);
}
.headingContianer {
    top: 10%;
    left: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
    padding-top: 0;
    padding-bottom: 0;
    color: white;
    font-size: 1.2em;
    z-index: 1;
    text-align: -webkit-center;
    width: 100%;
}

.headingContianer h5,
.headingContianer h1 {
    font-family: 'Poppins' !important;
}

.headingContianer h1::before {
    content: '';
    display: block;
    width: 31%;
    height: 5px;
    background: $primary;
    left: 0;
    top: 60%;
    position: absolute;
}

.headingContianer h1::after {
    content: '';
    display: block;
    width: 31%;
    height: 5px;
    background: $primary;
    right: 0;
    top: 60%;
    position: absolute;
}
.nft-cat {
    position: relative;
    top: -320px;
    margin-bottom: -280px;
    z-index: 10;
    .top-collection {
        display: flex;
        flex-direction: column;
        align-items: center;
        border-radius: 20px;
        height: 400px;
        position: relative;
        width: 300px;
        padding: 0 !important;
        margin: 0 auto;
        background: #eee;
        box-shadow: 0 8px 8px -4px black;
        .prices {
            border-radius: 50%;
            padding: 1rem;
            display: flex;
            flex-direction: column;
            justify-content: center;
            position: absolute;
            top: -20px;
            right: -20px;
            width: 85px;
            background: linear-gradient(
                90deg,
                rgba(73, 4, 144, 1) 25%,
                rgba(130, 16, 255, 1) 100%
            );
            background: rgb(73, 4, 144);
            background: -moz-linear-gradient(
                25deg,
                rgba(73, 4, 144, 1) 25%,
                rgba(130, 16, 255, 1) 100%
            );
            background: -webkit-linear-gradient(
                25deg,
                rgba(73, 4, 144, 1) 25%,
                rgba(130, 16, 255, 1) 100%
            );
            background: linear-gradient(
                25deg,
                rgba(73, 4, 144, 1) 25%,
                rgba(130, 16, 255, 1) 100%
            );
            color: #ffffff;

            .price h4 {
                font-size: 18px;
            }

            .discountedPrice {
                text-decoration: none;
            }
        }
        .collection-banner {
            height: 100%;
            width: 100%;
            border-radius: 5px;
        }
        .collection-logo {
            margin-top: -25px;
            width: 60px;
            height: 60px;
            border-radius: 50%;
            z-index: 9;
        }
        .collection-name {
            font-size: 20px;
            color: white;
            margin-top: 15px;
            // white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 100%;
            text-align: center;
            padding: 8px 0;
            line-height: 20px;
        }
        .user-detail {
            display: flex;
            position: absolute;
            align-items: center;
            justify-content: space-between;
            margin-top: 15px;
            padding: 10px;
            background-color: rgba(0, 0, 0, 0.55);
            backdrop-filter: blur(5px);
            border-radius: 30px;
            left: 20px;
            .user-info {
                .title {
                    color: #ffffff;
                }
            }
            .name-section {
                display: flex;
                .avatar {
                    height: 35px;
                    width: 35px;
                    margin-right: 10px;
                    border-radius: 50%;
                }
                .user-name {
                    color: white;
                    max-width: 100%;
                    margin-right: 5px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
                .eth-address {
                    color: white;
                    max-width: 10ch;
                    margin-right: 5px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
                .icon-verified {
                    width: 20px;
                }
                .user-role {
                    color: $text-primary;
                }
            }
        }
        .item-detail {
            margin-top: 20px;
            display: flex;
            flex-direction: column;
            // align-items: center;
            position: absolute;
            background-color: rgba(35, 2, 74, 0.55);
            backdrop-filter: blur(5px);
            bottom: 0;
            height: 150px;
            width: 100%;
            border-radius: 5px;
            justify-content: space-evenly;

            .item-detail-wrapper {
                margin: 10px;
                .collection-name {
                    text-align: left;
                    height: 40px;
                }

                .icon-item {
                    margin-right: 5px;
                    width: 15px;
                }
                .item-text {
                    color: $text-gray;
                }

                .item-detail-collections {
                    background-color: rgba(0, 0, 0, 0.85);
                    margin-top: 15px;
                    padding: 1rem;
                    border-radius: 20px;
                    align-items: center;
                    align-items: center;
                }

                .item-detail-favourites {
                    background-color: rgba(0, 0, 0, 0.85);
                    margin-top: 15px;
                    width: 45px;
                    padding: 1rem;
                    border-radius: 20px;
                    display: flex;
                    justify-content: center;
                }
            }
        }
    }
}
#vikram-world-container {
    text-align: center;
    padding-bottom: 20px;

    // #vikram-world-button {
    //     width: 600px;
    //     font-size: 1.5em;
    //     font-family: 'Poppins' !important;
    //     background-color: $primary;
    //     color: #fff !important;
    //     font-weight: 300;
    //     border: none;
    //     padding: 0.5rem 1.5rem;
    //     margin-top: -80px;
    //     margin-bottom: 1.5rem;
    //     cursor: pointer;

    //     span {
    //         font-weight: 500;
    //     }
    // }
    h3 {
        color: $primary;
        span {
        }
    }
}

@media only screen and (max-width: 767px) {
    .nft-cat {
        top: -50px;
        margin-bottom: 0px;
        .MuiGrid-container {
            row-gap: 5px;
        }
        .MuiGrid-item:nth-child(1) {
            margin: 0 20px;
            border-bottom: 1px solid gray;
        }
        .MuiGrid-item:nth-child(2) {
            margin: 0 20px;
            border-bottom: 1px solid gray;
        }
        .MuiGrid-item:nth-child(2) .prices {
            padding-top: 10px;
        }
    }

    .utility-text {
        margin-top: -5rem !important;
    }
    .headingContianer {
        top: 20%;
    }

    .headingContianer h5 {
        width: 300px;
        font-size: 0.4em;
    }
    .headingContianer h1 {
        width: 300px;
        font-size: 1rem;
        line-height: 20px;
    }

    .headingContianer h1::before {
        width: 22%;
        top: 65%;
    }

    .headingContianer h1::after {
        width: 22%;
        top: 65%;
    }
    // #vikram-world-container {
    //     #vikram-world-button {
    //         width: 300px;
    //         font-size: 0.8em;
    //         font-family: 'Poppins' !important;
    //         background-color: $primary;
    //         color: #fff !important;
    //         font-weight: 300;
    //         border: none;
    //         padding: 0.5rem 1rem;
    //         margin-bottom: 3rem;
    //         cursor: pointer;

    //         span {
    //             font-weight: 500;
    //         }
    //     }
    // }
}
