@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');


@import 'slick-carousel/slick/slick.css';
@import 'slick-carousel/slick/slick-theme.css';

// Helpers
@import './theme/Variables.scss';
@import './theme/Colors.scss';
@import './helpers/Utilities.scss';
@import './helpers/FontSize.scss';
@import './helpers/Space.scss';
@import './helpers/Fonts.scss';

// Components
@import './theme/components/Button.scss';
@import './theme/components/Input.scss';
@import './theme/components/Gradient.scss';
@import './theme/components/Slider.scss';
@import './theme/components/SegmentMenu.scss';
@import './theme/components/Accordian.scss';
@import './theme/components/Checkbox.scss';
@import './theme/components/Stripe.scss';
@import './theme/components/PopOver.scss';
@import './theme/components/Equalizer.scss';
@import './theme/components/AppVideoPlayer.scss';
@import './theme/components/Chip.scss';
@import './theme/components/Table.scss';

// Page or Modules
@import './modules/DesignBook.scss';
@import './modules/home/HomePage.scss';
@import './modules/home/Banner.scss';
@import './modules/home/TrendingAuctions.scss';
@import './modules/home/TopCollection.scss';
@import './modules/home/FeaturedNFTs.scss';

@import './modules/AppFooter.scss';
@import './modules/AppFooterSmall.scss';
@import './modules/AppHeader.scss';
@import './modules/AppGradient.scss';

@import './modules/marketplace/MarketPlace.scss';
@import './modules/marketplace/Filter.scss';
@import './modules/marketplace/CelebrityList.scss';

@import './modules/creations/NFTDetail.scss';
@import './modules/creations/ActionTimer.scss';
@import './modules/creations/CreationItem.scss';
@import './modules/creations/CreationDetails.scss';
@import './modules/creations/NFTPreview.scss';
@import './modules/creations/CheckoutForm.scss';
@import './modules/creations/CreationSellInitiate.scss';
@import './modules/creations/TradingHistory.scss';

@import './modules/activity/ActivityList.scss';
@import './modules/activity/ActivityPage.scss';

@import './modules/authentication/CreateWallet.scss';
@import './modules/authentication/EmailConfirmation.scss';
@import './modules/authentication/EmailVerified.scss';
@import './modules/authentication/ForgotPassword.scss';
@import './modules/authentication/SecurityVerification.scss';
@import './modules/authentication/MetamaskSignup.scss';
@import './modules/authentication/Login.scss';
@import './modules/authentication/Signup.scss';

@import './modules/portfolio/PortfolioPage.scss';

@import './modules/profile/ProfilePage.scss';
@import './modules/profile/ViewProfile.scss';
@import './modules/profile/KYCVerifyForm.scss';
@import './modules/profile/Wallet.scss';
@import './modules/profile/Withdrawrequestlist.scss';
@import './modules//profile/Pdf.scss';

@import './modules/marketting/AboutPage.scss';
@import './modules/marketting/ContactPage.scss';
@import './modules/marketting/CareerPage.scss';
@import './modules/marketting/PrivacyPolicyPage.scss';
@import './modules/marketting/FAQPage.scss';
@import './modules/marketting/HelpPage.scss';
@import './modules/marketting/NewsRoom.scss';
@import './modules/marketting/TermaandCondition.scss';
@import './modules/marketting/VikramPage.scss';
@import './modules/home/Referral.scss';

@import './Custom.scss';

body {
    margin: 0px;
    overflow-x: hidden;
}

@font-face {
    font-family: 'Matter';
    font-style: normal;
    font-weight: normal;
    src: local('Matter'), url('../../fonts/Matter\ Regular.ttf') format('woff');
}
@font-face {
    font-family: 'Tobias-Medium';
    font-style: normal;
    src: local('Tobias-Medium'), url('../../fonts/Tobias-Medium.ttf') format('woff');
}

.gradient-bg {
    background: url('../gradients/gradient-bg.png');
    background-repeat: no-repeat;
}

.primary-link {
    color: $primary;
}

a {
    text-decoration: none !important;
    color: inherit;
}

.app-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;

    .app-header {
        flex: 0;
        position: sticky;
        top: 0;
        z-index: 999;
        // display: flex;
        // flex-direction: column;

        header {
            box-shadow: none;
            background-color: unset;
        }
    }

    .app-body {
        flex: 1;
    }

    .app-footer {
        flex: 0;
        background-color: #000000;
    }
}

.app-divider {
    padding: 20px 0px 20px 0px;
    text-align: start;
}

.search-box {
    margin-right: 20px;

    img {
        width: 20px;
        color: white;
        margin-right: 20px;
    }
}

.icon-verified {
    width: 20px;
    height: 20px;
}

hr {
    border: 1px solid $border;
}

.user-info {
    display: flex;
    align-items: center;

    .title {
        color: $text-primary;
    }

    .MuiAvatar-root,
    .MuiTypography-root {
        margin-right: 10px;
    }

    .MuiAvatar-root {
        width: 35px;
        height: 35px;
    }
}

.break-text {
    word-break: break-word !important;
}

.text-lowercase {
    text-transform: lowercase !important;
}

.title-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

#referral-cta-container {
    position: fixed;
    bottom: 15px;
    left: 20px;
    z-index: 100;
    border-radius: 50%;
    box-shadow: 0px 0px 15px #000000;

    button {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        background-color: $primary;
        color: white;
        border: none;
        cursor: pointer;
        font-family: 'Poppins';
        font-size: 0.5em;
    }
}


.custom-table{
    th{
        line-height: 0.8rem !important;
    }
}