@mixin yellow-gradient {
    background: $yellowGradient;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

@mixin purple-gradient {
    background: $purpleGradient;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

@mixin green-gradient {
    background: $greenGradient;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

@mixin blue-gradient {
    background: $blueGradient;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

@mixin red-gradient {
    background: $redGradient;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

@mixin border-gradient {
    border-style: solid;
    border-radius: 10px;
    border: double 1px transparent;
    background-origin: border-box;
    background-clip: content-box, border-box;
}

.gradient-chip {
    @include border-gradient;
    .MuiTypography-root {
        margin: 5px 10px;
    }
}

.yellow-gr-text {
    @include yellow-gradient;
}

.purple-gr-text {
    @include purple-gradient;
}

.blue-gr-text {
    @include blue-gradient;
}

.red-gr-text {
    @include red-gradient;
}

.green-gr-text {
    @include green-gradient;
}

.gray-chip {
    border: 1px solid $grayChip;
    .MuiTypography-root {
        color: $grayChip;
    }
}

.yellow-chip {
    background-image: linear-gradient(black, black), $yellowGradient;
    .MuiTypography-root {
        @include yellow-gradient;
    }
}

.purple-chip {
    background-image: linear-gradient(black, black), $purpleGradient;
    .MuiTypography-root {
        @include purple-gradient;
    }
}

.green-chip {
    background-image: linear-gradient(black, black), $greenGradient;
    .MuiTypography-root {
        @include green-gradient;
    }
}

.red-chip {
    background-image: linear-gradient(black, black), $redGradient;
    .MuiTypography-root {
        @include red-gradient;
    }
}

.blue-chip {
    background-image: linear-gradient(black, black), $blueGradient;
    .MuiTypography-root {
        @include blue-gradient;
    }
}

.light-chip {
    background-color: white;
    color: $primary;
    border-radius: 30px;
    padding: 5px;
    .MuiTypography-root {
        font-size: 14px;
        font-weight: 300;
    }
    background-clip: border-box;
}
