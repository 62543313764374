@import './NFTItems.scss';

.featured-nfts-wrapper {
    margin-bottom: 50px;
    .section-header {
        // color: white;
        margin-bottom: 50px;
        // font-size: 40px;
    }
    #featured-viewall {
        a {
            border: 1px solid $primary;
            margin-top: 40px;
            border-radius: 30px;
            padding: 15px 50px;
            color: $text-primary;
        }
    }
}

.featured-nft-wrapper {
    background: #F2F2F2 0% 0% no-repeat padding-box;
    padding-bottom: 50px;
    // #featured-nft-sect{
    //     margin: 1px 80px;
    // }

    .featured-nft {
        // padding: 20px 50px;
        text-align: center;
        align-items: center;

        .auction-detail {
            width: 60%;
            display: flex;
            justify-content: space-between;
        }

        #featured-nft-image{
            padding-top: 40px;
            overflow: hidden;
            
            .nft-image{
                width: 450px;
                height: 400px;
                overflow: hidden;
                margin: auto;
            
            img{
                border: 1px solid #fff;
                transform:scale(1);
                transition: 0.6s ease;
                width: 100%;
                height: 100%;
               
            }
        }

            &:hover{
                img{
                    transform: scale(1.2);

                border: 2px solid #fff;
                }
            }
        }

        #featured-nft-detail{

            .nft-description {
                margin-top: 20px;
                .description {
                    color: $text-secondary;
                    font-weight: 400;
                    margin-top: 10px;
                    height: 70px;
                    display: -webkit-box;
                    -webkit-line-clamp: 3;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
                .description-full {
                    color: $text-secondary;
                    font-weight: 400;
                    margin-top: 10px;
                }
                .pointer {
                    cursor: pointer;
                    color: $primary;
                    font-size: 14px;
                }
            }
            #featured-nft-description{
                margin: 10px 0px;
                font-size:18px;
                line-height: 24px;
            }

            .auction-detail{

                .current-bid-value,.end-value{
                    margin-top:8px;
                    font-size:25px;
                }


            }
        }
    }
    #viewNFT {
        button {
            margin-top: 20px;
            padding: 10px 80px;
        }
    }
}

@media only screen and (max-width: 960px) {

    .featured-nfts-wrapper{
        .featured-nfts{
            padding: 16px;
        }
    }
    .featured-nft-wrapper {
        
        .section-header{ 
            margin-top: 50px;
        }
        #featured-nft-sect{
            margin: 0 !important;
        }
        .featured-nft {
            // background-color:#f2f2f2;
            padding: 8px;
            text-align: center;

            #featured-nft-detail {
                padding: 8px;
            }
            #featured-nft-image {
                order: -1;
                margin: auto;
            }

            .auction-detail {
                width: 100%;
            }

            #viewNFT {
                button {
                    width: 100%;
                }
            }
        }
    }
}
