#newsroom {
    .gradient-bg {
        height: 500px;
    }

    .newsroom-banner {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .gradient-bg::before {
        opacity: 0.95;
        background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxNDQwIDkwMCIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+PGRlZnM+PHN0eWxlPi5jbHMtMXtvcGFjaXR5Oi44O2ZpbGw6dXJsKCNsaW5lYXItZ3JhZGllbnQpfTwvc3R5bGU+PGxpbmVhckdyYWRpZW50IGlkPSJsaW5lYXItZ3JhZGllbnQiIHgxPSI3MjAiIHgyPSI3MjAiIHkyPSI5MDAiIGdyYWRpZW50VW5pdHM9InVzZXJTcGFjZU9uVXNlIj48c3RvcCBvZmZzZXQ9Ii40IiBzdG9wLW9wYWNpdHk9IjAiLz48c3RvcCBvZmZzZXQ9IjEiLz48L2xpbmVhckdyYWRpZW50PjwvZGVmcz48dGl0bGU+YmxhY2stZ3JhZGllbnQ8L3RpdGxlPjxnIGlkPSJMYXllcl8yIiBkYXRhLW5hbWU9IkxheWVyIDIiPjxwYXRoIGNsYXNzPSJjbHMtMSIgZD0iTTAgMGgxNDQwdjkwMEgweiIgaWQ9IkJsYWNrX0dyYWRpZW50IiBkYXRhLW5hbWU9IkJsYWNrIEdyYWRpZW50Ii8+PC9nPjwvc3ZnPg==);
    }
    .gradient-bg::before,
    .gradient-bg::after {
        content: '';
        height: 100%;
        left: 0;
        position: absolute;
        top: auto;
        bottom: 0;
        width: 100%;
        z-index: 1;
        background-size: cover;
        background-repeat: no-repeat;
        opacity: 0.8;
    }

    .headingContianer {
        top: 70%;
        left: 50%;
        position: absolute;
        transform: translate(-50%, -50%);
        padding-top: 0;
        padding-bottom: 0;
        color: white;
        font-size: 1.2em;
        z-index: 1;

        h1 {
            border-bottom: 3px solid #fa0000;
        }
    }

    .card-header {
        margin: 5px auto;
        overflow: hidden;
        height: 20px;
        font-size: 16px;
        font-weight: 300;
        color: $text-secondary;
    }

    .card-text {
        margin: 15px auto;
        overflow: hidden;
        height: 110px;
        font-weight: 400;
        color: $text-primary;
    }

    .card-date {
        margin: 20px auto 0;
        overflow: hidden;
        height: 20px;
        color: $text-secondary;
    }
}
