.nft-detail-container {
	#nft-detail-mobile {
		display: none;
	}

	.nft-image-container {
		display: flex;
		justify-content: center;
		align-items: center;
		position: relative;
		// height: 500px;

		.item-image {
			position: relative;
			padding: 20px;
			border-radius: 15px;
			display: flex;
			justify-content: center;

			img {
				border-radius: 15px;
				max-height: 500px;
				max-width: 100%;
			}
		}

		video {
			max-width: 100%;
			max-height: 500px;
			position: relative;
		}

		.play-btn {
			width: 50px;
			height: 50px;
			cursor: pointer;
			position: absolute;
			right: 0;
			bottom: 0;

			svg {
				color: $primary;
			}
		}
	}

	.nft-more-info {
		max-height: 400px;
		overflow: scroll;
	}

	.nft-detail {
		.chip {
			margin-right: 20px;
		}

		.nft-title-section {
			// margin-top: 20px;
			display: flex;
			justify-content: space-between;
			align-items: center;

			.title {
				color: $text-primary;
				font-size: 46px;
				@media (max-width : 1200px) {
					font-size: 36px;
				}

				@media (max-width : 600px) {
					font-size: 30px;
				}
			}

			.more-view {
				display: flex;
				margin-left: 20px;
				width: 200px;
				justify-content: flex-end;

				.MuiButtonBase-root {
					padding: 12px !important;
					margin-right: 20px;
				}

				.MuiButton-label {
					color: $text-secondary;
					font-size: 12px !important;
				}

				.fav-icon {
					width: 15px;
					height: 15px;
				}
			}
		}

		.view-and-availablitiy {
			display: flex;
			color: $text-secondary;
			align-items: center;

			.view {
				width: 25px;
				height: 25px;
			}

			.dot {
				width: 8px;
				height: 8px;
			}

			.view,
			img {
				margin-right: 10px;
			}

			p {
				margin-right: 10px;
			}
		}

		.nft-description {
			margin: 20px 0;

			.description {
				color: $text-secondary;
				font-weight: 400;
				font-size: 16px;
				display: -webkit-box;
				-webkit-line-clamp: 30;
				-webkit-box-orient: vertical;
				overflow: hidden;
				text-overflow: ellipsis;
			}

			.description-full {
				font-size: 16px;
				color: $text-secondary;
				font-weight: 400;
				margin-top: 10px;
			}

			.pointer {
				cursor: pointer;
				font-size: 14px;
				line-height: 20px;
				color: $input-text;
			}
		}

		.creatorName {
			display: flex;
			align-items: center;
			margin: 30px 0;
			@media (max-width : 992px) {
				margin: 10px 0;
			}
		}

		.collections {
			border: 1px solid #1515151a;
			padding: 0.4em;
			height: 100px;
		}

		.collection-creator,
		.bid-detail {
			margin-top: 0px;

			.royality {
				color: $primary;
				font-size: 10px;
				font-weight: 400;
				margin-left: 10px;
			}

			.user-info {
				margin-top: 10px;
				display: flex;
				align-items: center;
				color: $text-primary;

				.title {
					display: -webkit-box;
					-webkit-line-clamp: 2;
					-webkit-box-orient: vertical;
					overflow: hidden;
					text-overflow: ellipsis;
					font-weight: 300;
				}

				.MuiAvatar-root {
					margin-right: 10px;
					width: 60px;
					height: 60px;
				}
			}
		}

		.collection-creator {
			margin-bottom: 5px;
		}

		.bid-detail {
			.MuiTypography-h3 {
				font-family: 'Poppins' !important;
			}

			.bid-detail-1,
			.bid-detail-2 {
				border: 1px solid #1515151a;
				padding: 0.2em;
				height: 80px;
			}

			.action-timer {
				text-align: center;
			}

			.heading {
				font-weight: 400;
			}

			.bid-amount,
			.nft-count {
				font-size: 1.75em;
				line-height: 30px;
			}
		}

		.MuiPaper-root {
			background-color: transparent !important;
		}

		.properties {
			display: flex;
			flex-wrap: wrap;

			.property {
				padding: 10px;
				border-radius: 10px;
				margin-right: 10px;
				margin-bottom: 10px;
				border: $borderStyle;
				min-width: 50px;
				min-height: 50px;

				.title {
					color: $text-primary;
				}

				.value {
					margin-top: 10px;
					color: $text-secondary;
				}
			}
		}

		.authenticity {
			.text-secondary {
				font-weight: 400;
			}

			p {
				margin-bottom: 10px;
			}
		}
	}

	.nft-menus {
		display: flex;
		border-bottom: $borderStyle;
		padding: 0px 15px;

		.nft-menu {
			color: $text-primary;
			padding: 0px 0px 20px;
			margin-right: 40px;
			cursor: pointer;
		}

		.nft-menu:hover,
		.active-menu {
			color: $primary;
			border-radius: 2.5px;
			border-bottom: 5px solid $primary;
		}
	}

	.propertiesHeading,
	.authenticityHeading {
		font-size: 26px;
		line-height: 38px;
		font-family: "Matter";
		border-bottom: 1px solid #000;
		margin-bottom: 27px;
		padding-bottom: 15px;

		@media (max-width : 1200px) {
			font-size: 22px;
			line-height: 30px;
			margin-bottom: 20px;
		}
	}

	.history-img {
		width: 45px;
		height: 45px;
		border-radius: 50%;
		background-color: #d9d9d9;
		margin-right: 20px;

		@media (max-width : 1200px) {
			width: 50px;
		height: 45px;
			margin-right: 20px;
		}
		@media (max-width : 1100px) {
			width: 55px;
		height: 45px;
		}
		@media (max-width : 960px) {
				width: 45px;
			}
			@media (max-width : 576px) {
					width: 45px;
				}
				@media (max-width : 500px) {
					height: 35px;
				}
				@media (max-width : 375px) {
					// height: 35px;
					width: 55px;
				}

		// @media (max-width : 1024px) {
		// 	width: 60px;
		// }

		// @media (max-width : 960px) {
		// 	width: 45px;
		// }

		// @media (max-width : 460px) {
		// 	width: 55px;
		// }

		div {
			display: none;
		}
	}

	.properties {
		display: block;
		justify-content: flex-start !important;
		margin-bottom: 14px;

		@media (max-width : 960px) {
			justify-content: flex-start !important;
		}

		.property {
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin-bottom: 10px;
			// padding-right: 30px;

			.title,
			.value {
				font-family: 'Matter';
				font-weight: 400;
				font-size: 18px;
				line-height: 32px;

				@media (max-width : 440px) {
					font-size: 16px;
				}
			}

			.history-time {
				font-size: 12px;
				color: #B5B5B5;
				font-family: 'Matter';
				margin-top: 8px;
			}
			
			.open-in-new{
				width: 15px;
				height: 15px;
			}
		}
	}

	.authenticity {
		.text-secondary {
			font-weight: 400;
		}

		p {
			margin-bottom: 10px;
		}
	}

	.slick-initialized .slick-slide {
		display: flex;
		justify-content: center;
		border-radius: 15px;
		// padding: 20px;

		.slide-image {
			position: relative;
			border-radius: 15px;
			max-width: 100%;
			max-height: 500px;
			// object-fit: contain;
			.play-controls {
				width: 100% !important;
			}
		}
	}

	.action-btn {
		width: 150px;
	}
}

@media only screen and (max-width: 768px) {

	.creatorName,
	.ownerName {
		margin-left: 0px !important;
	}

	.nft-detail-container {
		.nft-image-container {
			height: 100%;
		}

		.nft-detail {
			.nft-title-section {
				.title {
					display: none;
				}

				.more-view {
					width: 100%;
					justify-content: space-between;
				}
			}

			.tags {
				display: none;
			}
		}

		#nft-detail-mobile {
			display: block;

			@media (max-width : 768px) {
				padding:  0 !important;
			}

			.nft-title-section {
				.title {
					display: block;
				}
			}

			.light-chip .MuiTypography-root {
				font-size: 12px;
			}
		}
	}

	.nft-detail-container .nft-menus {
		.nft-menu {
			margin-right: 20px;
		}

		overflow-x: auto;
	}
}
.nft-hover-video{
	position: relative;
}
.video-message {
	// cleaned up ineffective css
	display: flex;
	width: "auto";
	height: 50px;
	justify-content: center;
	align-items: center;
	transition: all 5s;
}

.message-content {
	// cleaned up ineffective css
	
	display: flex;
	justify-content: center;
	align-items: center;
	transition: all 5s;

	// added this to show audio cuew message just above the title row
	margin-top: -20px;

	color: #000;
}
.fade-in{
	opacity: 1;
	transition: opacity 5s ease;
}
.fade-out{
	opacity: 0;
	transition: opacity 5s ease;
}

.align-media-center{
	align-items: center;
}