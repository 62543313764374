* {
    font-family: 'Matter' !important;
}

.MuiContainer-maxWidthLg {
    max-width: 1450px !important;
}

@media only screen and (max-width: 1600px) {
    .MuiContainer-maxWidthLg {
        max-width: 1300px !important;
    }
}

@media only screen and (max-width: 1449px) {
    .MuiContainer-maxWidthLg {
        max-width: 1200px !important;
    }
}

@media only screen and (max-width: 1200px) {
    .MuiContainer-maxWidthLg {
        max-width: 100% !important;
    }
}

.market-place-container {
    .MuiGrid-container {
        max-width: 1450px !important;
        margin: 0 auto;
        padding-right: 12px;
        padding-left: 12px;

        @media only screen and (max-width: 1600px) {
            max-width: 1300px !important;
        }

        @media only screen and (max-width: 1449px) {
            max-width: 1200px !important;
        }

        @media only screen and (max-width: 1200px) {
            max-width: 100% !important;
        }
    }
}

.googleBtn {
    background: #F5F4F4 !important;
    border: 1px solid #000000 !important;
    border-radius: 0 !important;
    padding: 18px 0 !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;

    @media(max-width: 991px) {
        padding: 15px 0 !important;
    }

    @media(max-width: 600px) {
        padding: 12px 0 !important;
    }
}

.auth-content {
    margin: 30px;
    width: 456.14px;
    padding: 30px 69.18px 30px 69px;
    background-color: #F5F4F4;
    // box-shadow: 18px 17px;
    border: 1px solid #000000;

    @media(max-width: 991px) {
        margin: 30px 15px;
        padding: 20px 50px;
        width: 400px;
    }

    @media(max-width: 767px) {
        margin: 30px 15px;
        padding: 20px;
    }

    @media(max-width: 600px) {
        margin: 12px 10px;
        padding: 10px;
    }
}

.auth-divider {
    border: 1px solid #000000 !important;
}

.auth-label {
    font-family: 'Matter' !important;
    font-style: normal;
    font-weight: 400 !important;
    font-size: 15px !important;
    line-height: 98.9% !important;
    color: #000000 !important;

    @media(max-width: 600px) {
        font-size: 14px !important;
    }

    &::placeholder {
        color: #000000 !important;
    }
}

.signup-privacy-error {
    color: #f44336;
    font-size: 14px;
}

.signup-privacy-wrapper {
    margin-bottom: 20px;
}

.signup-privacy-box {
    display: flex;
    align-items: center;
}

#signup-pwd {
    .auth-input {
        padding: 14px 0 14px 14px !important;
        margin: 0 0 36px !important;

        @media(max-width: 600px) {
            padding: 8px !important;
            margin: 0 0 30px !important;
        }
    }
}

.auth-input {
    border: 1px solid #000000 !important;
    background: #F5F4F4 !important;
    padding: 14px !important;
    margin: 0 0 30px !important;

    @media(max-width: 600px) {
        padding: 8px !important;
        margin: 0 0 20px !important;
    }

    input {
        padding: 0 !important;

        &::placeholder {
            font-family: 'Matter' !important;
            font-style: normal;
            font-weight: 400 !important;
            font-size: 17px;
            line-height: 98.9% !important;
            color: #000000 !important;
            opacity: 1;

            @media(max-width: 600px) {
                font-size: 14px;
            }
        }
    }

    div::before,
    div::after {
        border-bottom: none !important;
        position: relative;
    }

    p {
        position: absolute;
        // bottom: -20px;
        top: 50px;
        font-size: 12px;
        line-height: 12px;
        margin-top: 0;
        left: 0;

        @media(max-width: 600px) {
            font-size: 9px;
            line-height: 10px;
            top: 40px;
        }
    }
}

.title-content {
    margin-bottom: 32px !important;

    @media(max-width: 600px) {
        margin-bottom: 20px !important;
    }
}

.title-content-login {
    margin-bottom: 32px !important;

    @media(max-width: 600px) {
        margin-bottom: 20px !important;
    }
}

.email-label {
    padding: 0 !important;
}

#signup-btn {
    button {
        pointer-events: all;
        cursor: pointer;
    }
}

.auth-main-title {
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 30px !important;
    line-height: 98.9% !important;
    color: #000000 !important;
    padding-bottom: 12px;

    @media(max-width: 600px) {
        font-size: 24px !important;
    }
}

.forgot-h3 {
    font-size: 41px !important;

    @media(max-width: 575px) {
        font-size: 30px !important;
    }

    @media(max-width: 400px) {
        font-size: 22px !important;
    }
}

.backBtn {
    background: #000 !important;
    color: #fff !important;
    border: 1px solid #000000 !important;
    border-radius: 0 !important;
    padding: 16px 28px !important;

    @media(max-width: 600px) {
        padding: 12px 22px !important;
    }
}

.end-content {
    padding-bottom: 27px !important;
}

.verified-content {
    font-family: 'Matter' !important;
    font-size: 17px !important;

    @media(max-width: 600px) {
        font-size: 14px !important;
    }
}

.wallet-content {
    font-family: 'Matter' !important;
}

.verify-content {
    display: block !important;
}

.auth-emailverify {
    width: 456px;
    padding: 48px 57px 43px 56px;
    text-align: center;
    margin: 240.36px auto 285px !important;

    @media(max-width: 1600px) {
        margin: 190px auto !important;
    }

    @media(max-width: 991px) {
        margin: 120px auto !important;
    }

    @media(max-width: 768px) {
        margin: 80px auto !important;
        padding: 30px 35px;
    }

    @media(max-width: 575px) {
        margin: 50px auto !important;
        padding: 20px 25px;
    }

    @media(max-width: 425px) {
        margin: 20px auto !important;
        padding: 10px;
    }
}

.email-verify-icon {
    padding-bottom: 22.58px;
}

// .auth-content-verify-signup {
//     padding: 39px 52px 54px 55px !important;

//     @media (max-width : 600px) {
//         padding: 22px 32px 34px 32px !important;
//         width: 450px !important;

//     }
// }

.profilename {
    font-style: normal;
    font-size: 48px !important;
    line-height: 98.9% !important;
    color: #000000 !important;
    font-weight: 400 !important;

    @media(max-width: 1600px) {
        font-size: 40px !important;
    }

    @media(max-width: 991px) {
        font-size: 24px !important;
    }
}

.interested-wallet {
    background-color: #000000 !important;

    @media(max-width: 600px) {
        padding: 10px !important;
    }

    span {
        span {
            @media(max-width: 600px) {
                font-size: 12px;
            }
        }
    }
}

.content-wrepper {
    display: flex;
    align-items: flex-start;
    position: relative;
    // border-top: 1px solid #000000;

    @media(max-width: 768px) {
        flex-direction: column;
        border-top: none;
    }
}

.about-content-wrepper {
    display: flex;
    width: 100%;

    @media(max-width: 768px) {
        flex-direction: column;
        margin-bottom: 0 !important;
    }
}

.about-section-wrapper {
    @media(max-width: 768px) {
        margin-top: 20px;
    }
}

.about-read-more a,
#banner-details .hero-banner-view-btn .btn-primary {
    position: relative;
}

.about-read-more a::after {
    position: absolute;
    content: '';
    width: 87%;
    height: 2px;
    background-color: #000000;
    bottom: 0;
    opacity: 0;
    left: 0;
}

#banner-details .hero-banner-view-btn .btn-primary::after {
    position: absolute;
    content: '';
    width: 87%;
    height: 2px;
    background-color: #000000;
    bottom: 10px;
    opacity: 0;
    left: 0;
}

.about-read-more a:hover::after,
#banner-details .hero-banner-view-btn .btn-primary:hover::after {
    opacity: 1;
}

.items-content {
    padding: 0 16px 15px 16px !important;

    @media(max-width: 1600px) {
        padding: 0 10px 10px !important;
    }
}

.import-nft-cancle {
    color: #000 !important;
}

.nft-more-info {
    width: 80%;
    // border-left: 1px solid #000000;
    margin-top: 0 !important;

    @media(max-width: 768px) {
        border-left: none;
        width: 100%;
    }
}

.border-content {
    background-color: #000000 !important;
}

.btnContent {
    background-color: #000000 !important;

    @media(max-width: 600px) {
        padding: 10px !important;
    }

    span {
        span {
            font-family: 'Matter' !important;

            @media(max-width: 600px) {
                font-size: 12px;
            }
        }
    }
}

.fieldset-box {
    p {
        font-family: 'Matter';
    }

    div {
        div {
            fieldset {
                border: 1px solid #000 !important;
                border-radius: 0;
            }
        }
    }
}


.cencelBtn {
    span {
        span {
            color: $input-text;
            font-family: 'Matter';
        }
    }
}

.profile-nft:hover {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}

.app-profile-wrapper {
    margin-top: 0 !important;
}

.saveBtn {
    background-color: #000000 !important;

    span {
        span {
            font-family: 'Matter';
        }
    }
}

.human-box {
    position: relative;
    width: 270px;
    background: rgba(217, 217, 217, 0.33);
    border: 1px solid #000000;
    margin-bottom: 46.82px;

    @media(max-width: 991px) {
        width: 230px;
        // height: 50px;
        margin-bottom: 30px;
    }

    @media(max-width: 600px) {
        width: 200px;
        // height: 50px;
        margin-bottom: 30px;
    }

    .human-check {
        margin: 0;
        display: flex;
        align-items: center;
        font-family: 'Matter' !important;
        font-style: normal;
        font-weight: 400;
        font-size: 17px;
        color: #000000;
        padding: 14px 18px;

        @media(max-width: 600px) {
            padding: 10px 10px;
        }

        span {
            font-family: 'Matter' !important;
        }

        .human-box-checkbox {
            margin-right: 11px;
            padding: 0;

            span {

                // background-color: #fff;
                svg {
                    fill: #000;
                }
            }
        }
    }

}

.captcha-icon {
    position: absolute;
    top: 21%;
    right: 5%;
}

.meta-mask-main-h3 {
    font-size: 41px !important;

    @media (max-width : 600px) {
        font-size: 26px !important;
    }
}

.signIn-content {
    margin-bottom: 16.29px;
    padding: 0 !important;

    // @media(max-width: 600px) {
    //     margin-bottom: 30px;
    // }

    .auth-label {
        padding: 14px 0 14px !important;
        background-color: #000 !important;
        color: #fff !important;
        font-size: 17px !important;
    }
}

.password-label {
    margin: 0 0 15px !important;

    .auth-input {
        margin: 0 !important;
    }
}

.forgot-pwd-button {
    display: flex;
    justify-content: space-around;
    align-items: center;

    .backBtn {
        @media(max-width: 600px) {
            padding: 10px 14px !important;
        }
    }
}

.align-center {
    margin-bottom: 24px;

    @media(max-width: 600px) {
        margin-bottom: 16px;
    }
}

// .center-content {
//     margin-bottom: 63px !important;

//     @media(max-width: 600px) {
//         margin-bottom: 30px !important;
//     }
// }

.forgot-input-name {
    // padding: 0 15px;

    .auth-input {
        margin-bottom: 27px !important;

        input {
            padding: 0;
        }
    }
}

.forgot-auth-wrapper {
    padding: 39px 54px 64px 67px !important;
    width: 456px !important;

    @media(max-width: 600px) {
        padding: 24px 30px !important;
    }

    .human-box {
        margin-bottom: 71px;
    }
}

.meta-mask-auth-content {
    width: 456px;
    padding: 38px 52px 53px 54px !important;

    @media(max-width: 600px) {
        padding: 24px 30px !important;
    }
}

.mobileicon {
    fill: #FFFFFF !important;
}

.backbtn-content {
    margin-bottom: 0 !important;
}

.otherContent {
    margin-bottom: 10px !important;
    padding: 0 !important;
}

.reset-password-content {
    margin-bottom: 52px !important;
}

.email-none {
    display: none;
}

.email-verify {
    display: flex;
    width: 100%;
    height: 250px;
    position: relative;
    margin-bottom: 60px;

}

.email-left {
    width: 455px;
    height: 406px;
    position: absolute;
    top: -249px;
    left: -51px;
    border-bottom: 1px solid #000000;
    transform: rotate(33deg);

    // transform: matrix(0.77, 0.6, -0.68, 0.77, 0, 0);
}

.email-right {
    width: 433px;
    height: 406px;
    position: absolute;
    top: 87px;
    right: -277px;
    border-top: 1px solid #000000;
    transform: rotate(-35deg);

    // transform: matrix(-0.75, 0.62, -0.69, -0.76, 0, 0);
}

// #anchor-my{

//         border: 1px solid #000 !important;
//         border-radius: 0 !important;
//         background: rgba(217, 217, 217, 0.33) !important;

// }
.verify-email-name {
    padding-bottom: 18px !important;
    margin: 0 0 18px 0 !important;
    border-bottom: 1px solid #000;

    @media (max-width : 575px) {
        margin: 0 0 10px 0 !important;
        padding-bottom: 12px !important;
    }

    h3 {
        font-style: normal !important;
        font-weight: 500 !important;
        font-size: 30px !important;
        line-height: 98.9% !important;
        text-align: center !important;
        color: #000000 !important;
        padding-bottom: 0;

        @media (max-width : 768px) {
            font-size: 24px !important;
        }
    }
}

.email-verify-text-line {
    font-family: 'Matter' !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 15px !important;
    line-height: 98.9% !important;
    text-align: center !important;
    color: #000000 !important;
    padding-bottom: 51px !important;

    @media (max-width : 768px) {
        padding-bottom: 35px !important;
    }
}

.verified-content {
    font-family: 'Matter' !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 23px !important;
    line-height: 98.9% !important;
    text-align: center !important;
    color: #000000 !important;
    padding-bottom: 44px !important;
    margin-bottom: 0 !important;

}

.email-verify-content {
    width: 738px;
    // height: 694px;
}

.email-verify-btn {
    .backBtn {
        font-family: 'Matter' !important;
        font-style: normal !important;
        font-weight: 400 !important;
        font-size: 15px !important;
        line-height: 98.9% !important;
        text-align: center !important;
        color: #FFFFFF !important;
        background-color: #000000 !important;
        box-shadow: none !important;
        padding: 13px 22px !important;

        @media (max-width : 768px) {
            padding: 10px 16px !important;
        }
    }
}

.google-img-icon {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 50px;
    top: 17px;
    z-index: 1;

    @media (max-width : 991px) {
        left: 45px;
        top: 15px;
    }

    @media (max-width : 767px) {
        left: 75px;
        top: 15px;
    }

    @media (max-width : 600px) {
        width: 20px;
        height: 20px;
        left: 33px;
        top: 10px;
    }
}

.metamask-form {
    .meta-content {
        margin-bottom: 20px !important;
    }
}

.metamask-img-icons {
    position: absolute;
    width: 28px;
    height: 28px;
    left: 40px;
    top: 14px;
    z-index: 1;

    @media (max-width : 991px) {
        left: 33px;
        top: 11px;
    }

    @media (max-width : 767px) {
        left: 60px;
        top: 12px;
    }

    @media (max-width : 600px) {
        width: 24px;
        height: 24px;
        left: 22px;
        top: 10px;
    }
}

.auth-login-box {
    .auth-input {
        margin: 22px 0 30px !important;
        padding: 14.93px 0 13.12px 11.64px !important;

        @media (max-width : 600px) {
            margin: 16px 0 20px !important;
            padding: 10px 0 10px 10px !important;
        }

        &>div {
            // font-size: 13px !important;
            line-height: 17px !important;
        }

        input {
            padding: 0 !important;
            // line-height: 20px !important;
        }
    }
}

// .auth-password-error{
//     p{

//     }
// }
.auth-password-box {
    .auth-input {
        padding: 14.93px 0 13.12px 11.64px !important;

        @media (max-width : 600px) {
            padding: 10px 0 10px 10px !important;
        }

        input {
            padding: 0 !important;
        }
    }
}

#google-login {
    padding: 0;
    padding-bottom: 15px;
    position: relative;
}

#metamask-login {
    padding: 0;
    position: relative;
    padding-bottom: 25px;
}

#google-signup {
    padding: 0;
}

.forgot-title-content {
    margin-bottom: 50px !important;
}

.nft-detail-wrapper {
    width: 100% !important;
    display: block !important;
    margin: 60px 0 0 0 !important;

    @media (max-width : 1600px) {
        margin: 50px 0 0 0 !important;
    }

    @media (max-width : 991px) {
        margin: 40px 0 0 0 !important;
    }

    @media (max-width : 768px) {
        margin: 30px 0 0 0 !important;
    }

    @media (max-width : 575px) {
        margin: 20px 0 0 0 !important;
    }
}

button.Mui-disabled.Mui-disabled {
    background-color: #312f2f96 !important;
}

.artwork-wrapper {
    .market-slider {
        width: 100%;
        padding: 0;
    }
}

// .slide-arrow-next {
//     @media (max-width : 691px) {
//         // display: none !important;
//         // margin: 40px 0 60px !important;
//     }
// }

// .slide-arrow-prev {
//     @media (max-width : 691px) {
//         // display: none !important;
//         // margin: 40px 0 60px !important;
//     }
// }

.nft-slider,
.img-slider,
.artwork-wrapper {
    width: 100%;

    .slick-slide {
        >div {
            width: 100%;
        }
    }

    .my-grid {
        padding: 0 14px;

        @media (max-width: 1600px) {
            padding: 10px;
        }

        @media (max-width: 991px) {
            padding:
                6px;
        }
    }

    .slide-arrow-prev,
    .slide-arrow-next {
        background: white;
        border: 1px solid black;

        svg {
            color: black;
        }
    }

    .slide-arrow-prev {
        left: 2px;
    }

    .slide-arrow-next {
        right: 2px;
    }

    .slick-dots {
        margin-top: 30px;
        position: static;

        @media (max-width: 600px) {
            li {
                button {
                    &::before {
                        width: 12px !important;
                        height: 12px !important;
                        border-radius: 50% !important;
                        background-color: #dbdbdb !important;
                        border: none !important;
                    }
                }
            }
        }

        li {
            button {
                &::before {
                    width: 18px !important;
                    height: 18px !important;
                    border-radius: 50% !important;
                    background-color: #dbdbdb !important;
                    border: none !important;
                }
            }
        }

        .slick-active {
            button {
                &::before {
                    background-color: #9f9f9f !important;
                }
            }
        }
    }
}

.item-ntf-contant {
    .profile-card {
        margin: 100px 0 140px !important;

        @media (max-width : 1600px) {
            margin: 60px 0 80px !important;
        }

        @media (max-width : 991px) {
            margin: 40px 0 60px !important;
        }

        @media (max-width : 575px) {
            margin: 20px 0 30px !important;
        }
    }

    .nft-slider {
        .slide-arrow-prev {
            left: 0 !important;
        }
    }
}

.up-arrow-icon {
    fill: #fff !important;
}

.zoomImg-container {
    @media (max-width : 769px) {
        // height: 62vh;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.slide-image {
    max-width: 90vh;
    height: 60vh;

    @media (max-width : 991px) {
        width: 500px;
    }

    @media (max-width : 768px) {
        width: 400px;
    }

    @media (max-width : 576px) {
        width: 100%;
    }
}

.banner-details {
    height: 60vh;

    @media (max-width : 991px) {
        height: 50vh;
    }

    @media (max-width : 768px) {
        height: 40vh;
    }
}

.img-slider {
    background: rgba(217, 217, 217, 0.53);

    padding: 30px 120px;

    @media (max-width : 1024px) {
        padding: 30px 100px;
    }

    @media (max-width : 576px) {
        padding: 30px 50px;
    }

    @media (max-width : 425px) {
        // increased from 10px to 20px for enough padding to show audio cue message
        padding: 20px;
    }

    div {
        &:first-child {
            div {
                // display: block;

                .slide-image {
                    margin: auto;
                }
            }
        }
    }

    .slide-arrow-next {
        right: 40px;

        @media (max-width :400px) {
            // display: none;
        }
    }

    .slide-arrow-prev {
        left: 40px;

        @media (max-width :400px) {
            // display: none;
        }
    }

    ul {
        bottom: 15px;
        left: 20px;
    }
}

.nft-content-over {
    align-items: flex-start;

    @media(max-width: 959px) {
        align-items: inherit;
    }
}

.ntf-content {
    display: flex !important;
    justify-content: space-between !important;
    // align-items: center;

    @media(max-width: 959px) {
        flex-direction: column;
        // display: block;
    }

    &:first-child {
        margin-bottom: 58.89px;

        @media(max-width: 1600px) {
            margin-bottom: 40px;
        }

        @media(max-width: 991px) {
            margin-bottom: 30px;
        }

        @media(max-width: 575px) {
            margin-bottom: 20px;
        }
    }
}

// .nft-content-box-wrapper {
//     @media(max-width: 1100px) {
//         flex-direction: row !important;
//     }
// }

.main-my-grid-wrapper {
    margin: 0 !important;
}

.main-my-grid-wrapper-art {
    padding: 18px;

    @media(max-width: 1600px) {
        padding: 10px;
    }
}

.ntf-details {
    margin-bottom: 64.36px;

    @media(max-width: 1600px) {
        margin-bottom: 50px;
    }

    @media(max-width: 991px) {
        margin-bottom: 30px;
    }
}

.nft-detail {
    @media(max-width: 960px) {
        margin-bottom: 10px;
    }
}

.ntf-title {
    font-size: 16.9372px !important;
    line-height: 20px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    color: #000 !important;

    @media(max-width: 425px) {
        font-size: 14px !important;
    }
}

.detail-value {
    -webkit-line-clamp: 1 !important;
    overflow: hidden !important;
    -webkit-box-orient: vertical !important;
    display: -webkit-box !important;
}

.detail-nft-value {
    padding-right: 10px;
}

.ntf-value {
    font-size: 26px !important;
    line-height: 36px !important;
    font-weight: 400;
    color: #000 !important;

    @media (max-width : 1600px) {
        font-size: 22px !important;
        line-height: 30px !important;
    }

    @media (max-width : 991px) {
        font-size: 18px !important;
    }

    @media (max-width : 600px) {
        font-size: 18px !important;
        padding-top: 0px !important;

    }
}

.ntfvalue {
    font-size: 16px !important;
    line-height: 16px !important;
    color: #000 !important;

    @media (max-width : 1600px) {
        font-size: 16px !important;
    }
}

.buybtn {
    font-size: 40px !important;
    line-height: 40px !important;
    color: #FFFFFF !important;
    background-color: #000000 !important;
    padding: 10px 40px !important;
    border-radius: 0 !important;

    @media (max-width : 1600px) {
        font-size: 22px !important;
        line-height: 22px !important;
        padding: 6px 20px !important;
    }

    // @media (max-width : 991px) {
    //     font-size: 28px !important;
    //     line-height: 38px !important;
    //     padding: 5px 38px !important;
    // }

    // @media (max-width : 768px) {
    //     font-size: 20px !important;
    //     line-height: 28px !important;
    //     padding: 5px 30px !important;
    // }

    @media (max-width: 575px) {
        font-size: 14px !important;
        line-height: 14px !important;
        padding: 5px 18px !important;
    }
}

.nft-details-history {
    margin-bottom: 127.36px;

    @media (max-width :1600px) {
        margin-bottom: 80px;
    }

    @media (max-width :991px) {
        margin-bottom: 50px;
    }

    @media (max-width :575px) {
        margin-bottom: 20px;
    }
}

.item-ntf-contant {
    .card-header {
        border-top: 1px solid #000;
        // margin-top: 64px;
        // @media (max-width :1600px) {
        //     margin-top: 50px;
        // }

        // @media (max-width :991px) {
        //     margin-top: 30px;
        // }

        // @media (max-width :575px) {
        //     margin-top: 5px;
        // }

        .view-profile {
            .title {
                padding: 32px 0 !important;
                font-weight: 600 !important;
                font-size: 60px;
                line-height: 72px;
                color: #000000;

                @media (max-width : 1600px) {
                    font-size: 50px !important;
                    line-height: 50px;
                    padding: 15px 0 !important;
                }

                @media (max-width : 991px) {
                    font-size: 40px !important;
                    line-height: 40px;
                    padding: 10px 0 !important;
                }

                @media (max-width : 768px) {
                    font-size: 30px !important;
                    line-height: 30px;
                }

                @media (max-width : 575px) {
                    font-size: 24px !important;
                    line-height: 24px;
                    padding: 6px 0 !important;
                }
            }
        }
    }

    // .profile-card {
    //     margin: 86px 0;
    // }
}

.profile-card-box-wrapper {
    // display: flex;
    padding: 70px 0 94px 80px !important;
    margin: 0 !important;
    border-top: 1px solid #000;

    @media (max-width : 1600px) {
        padding: 40px 0 70px 70px !important;
    }

    @media (max-width : 1400px) {
        padding: 40px 0 70px 50px !important;
    }

    @media (max-width : 991px) {
        padding: 30px 0 0 30px !important;
        // flex-direction: column;
    }

    @media (max-width : 768px) {
        padding: 20px 0 20px 30px !important;
    }

    @media (max-width : 575px) {
        padding: 20px 0 20px 12px !important;
    }

}

.border-pr {
    width: 1px;
    height: 329px;
    background-color: #000;
    margin-top: 30px;

    @media (max-width : 991px) {
        display: none;
    }
}

.profile-social-connection {
    padding: 0 81px 0 70px;

    @media (max-width : 1600px) {
        padding: 0 70px 0 70px !important;
    }

    @media (max-width : 1400px) {
        padding: 0 50px 0 50px !important;
    }

    @media (max-width : 991px) {
        padding: 0 30px 0 0 !important;
    }

    @media (max-width : 575px) {
        padding: 0 12px 0 0 !important;
    }

    .social-connection-wrapper-box {
        .social-text {
            font-family: 'Matter';
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 98.9%;
            color: #595959;
            margin-bottom: 18px;

            @media (max-width : 1600px) {
                margin-bottom: 10px;
            }

            @media (max-width : 991px) {
                font-size: 17px;
            }
        }

        .social-box {
            width: 307px;
            border: 0.823105px solid #000000;
            border-radius: 0;
            padding: 11px 15.69px;
            display: flex;
            margin-bottom: 38px;

            @media (max-width : 991px) {
                margin-bottom: 30px;
            }

            img {
                padding-right: 11.82px;
                width: 36px;
                min-width: 36px;
            }

            .text-input-box {
                width: 100%;

                input {
                    border: none;
                    padding-left: 16.78px;
                    border-left: 0.791667px solid #000;
                    height: 26px;
                    width: 100%;

                    @media (max-width : 991px) {
                        height: 20px;
                    }

                    &::placeholder {
                        font-family: 'Matter';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 12.2708px;
                        line-height: 98.9%;
                        color: #B8B8B8;
                    }

                    &:focus-visible {
                        outline: none !important;
                    }
                }
            }
        }
    }
}

.social-submit-btn {
    background-color: #000 !important;
    border-radius: 0 !important;
    padding: 16px 33px !important;
    font-family: 'Matter' !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 18.8651px !important;
    line-height: 98.9% !important;
    color: #FFFFFF !important;

    @media (max-width : 1600px) {
        padding: 12px 28px !important;
        font-size: 16px !important;
    }

    @media (max-width : 991px) {
        padding: 10px 20px !important;
        font-size: 14px !important;
    }
}

.profile-div-box {
    display: flex;

    @media (max-width : 991px) {
        flex-direction: column;
    }
}

.profile-setusername {
    padding-bottom: 20px;
}

.username-title {
    font-family: 'Matter' !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 20px !important;
    line-height: 98.9% !important;
    color: #333333 !important;
    padding-bottom: 10px;
    margin: 0 !important;

    @media (max-width : 991px) {
        font-size: 17px !important;
    }
}

.username-field-box {
    display: flex;
    align-items: baseline;
}

.profile-username-set-btn {
    width: 45%;
    margin: 0 !important;

    @media(max-width : 1170px) {
        width: 45%;
    }

    @media(max-width : 991px) {
        width: 40%;
    }
}

.profile-userName-input-field {
    margin: 0 40px 0 0 !important;
    width: 65%;

    @media(max-width : 1170px) {
        margin: 0 16px 0 0 !important;
        width: 55%;
    }

    @media(max-width : 991px) {
        width: 60%;
    }

    &:focus-visible {
        outline: none !important;
    }

    .MuiInputBase-formControl {
        &:focus-visible {
            outline: none !important;
        }
    }

    input {
        @media (max-width : 1600px) {
            padding: 14px;
        }

        @media (max-width : 991px) {
            padding: 12px;
        }

        &::placeholder {
            font-family: 'Matter';
            font-style: normal;
            font-weight: 400;
            font-size: 17px !important;
            line-height: 98.9%;
            letter-spacing: -0.04em;
            color: rgba(89, 89, 89, 0.24);
            opacity: 1;

            @media (max-width : 575px) {
                font-size: 14px !important;
                line-height: 14px;
            }
        }

    }

    fieldset {
        border-radius: 0 !important;
        border: 0.5px solid #000 !important;

        &:focus-visible {
            outline: none !important;
        }
    }

    p {
        margin: 3px 0 0 !important;
    }
}

.profile-cancle {
    margin: 0 !important;
    padding: 10px 0 0 !important;
}

.profile-social-connection {
    border-left: 1px solid #000;
    padding-left: 4rem !important;

    @media (max-width : 1064px) {
        padding-left: 0rem !important;
        border-left: 0px !important;
    }
}

.profile-page-wrapper {
    // border-right: 1px solid #000;
    // background-color: red;
    display: block !important;
    margin: 0 !important;
    padding: 0 75px 0 0 !important;

    @media (max-width : 1600px) {
        padding: 0 70px 0 0 !important;
    }

    @media (max-width : 1400px) {
        padding: 0 50px 0 0 !important;
    }

    @media (max-width : 991px) {
        // margin-bottom: 40px !important;
        padding: 0 30px 0 0 !important;
    }

    @media (max-width : 575px) {
        border-right: 0px;
        padding: 0 12px 0 0 !important;
    }

}

.profile-name-field {
    padding: 0 !important;
    margin-bottom: 10px !important;

    // @media (max-width : 1600px) {
    //     margin-bottom: 18px !important;
    // }

    // @media (max-width : 991px) {
    //     margin-bottom: 12px !important;
    // }

    .subtitle {
        font-family: 'Matter';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 98.9%;
        color: #595959;
        margin: 0 !important;

        @media (max-width : 991px) {
            font-size: 17px;
        }
    }
}

.profile-name-field-input {
    border: 0.5px solid #000000;
    width: 100%;
    margin-bottom: 26px !important;
    padding: 16px 0 16px 18px !important;
    font-size: 17px !important;


    @media (max-width : 991px) {
        padding: 12px 0 12px 10px !important;
        font-size: 14px !important;
    }

    @media (max-width : 768px) {
        width: 300px;
    }

    @media (max-width : 575px) {
        width: 240px;
        padding: 10px 0 10px 10px !important;
    }

    &::placeholder {
        font-family: 'Matter';
        font-style: normal;
        font-weight: 400;
        font-size: 17px !important;
        line-height: 98.9%;
        letter-spacing: -0.04em;
        color: rgba(89, 89, 89, 0.24);

        @media (max-width : 575px) {
            font-size: 14px !important;
            line-height: 14px;
        }
    }

    &:focus-visible {
        outline: none !important;
    }
}

.profile-email-field {
    padding: 0 !important;
    margin-bottom: 10px !important;

    // @media (max-width : 1600px) {
    //     margin-bottom: 18px !important;
    // }

    // @media (max-width : 991px) {
    //     margin-bottom: 12px !important;
    // }

    .subtitle {
        font-family: 'Matter';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 98.9%;
        color: #595959;
        margin: 0 !important;

        @media (max-width : 991px) {
            font-size: 17px;
        }
    }
}

.profile-email-field-input {
    border: 0.5px solid #000000;
    width: 100%;
    margin-bottom: 26px !important;
    padding: 16px 18px !important;
    font-size: 17px !important;

    @media (max-width : 1600px) {
        padding: 14px !important;
    }

    @media (max-width : 991px) {
        padding: 12px !important;
        font-size: 14px !important;
    }


    @media (max-width : 575px) {
        padding: 10px 0 10px 10px !important;
    }

    &::placeholder {
        font-family: 'Matter';
        font-style: normal;
        font-weight: 400;
        font-size: 17px !important;
        line-height: 98.9%;
        letter-spacing: -0.04em;
        color: rgba(89, 89, 89, 0.24);

        @media (max-width : 575px) {
            font-size: 14px !important;
            line-height: 14px;
        }
    }

    &:focus-visible {
        outline: none !important;
    }
}

.profile-bio-field {
    padding: 0 !important;
    margin-bottom: 10px !important;

    .subtitle {
        font-family: 'Matter';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 98.9%;
        color: #595959;
        margin: 0 !important;

        @media (max-width : 991px) {
            font-size: 17px;
        }
    }
}

.profile-bio-field-input {
    border: 0.5px solid #000000;
    width: 100%;
    height: 119px;
    margin-bottom: 49px !important;
    padding: 14px 18px !important;
    font-size: 17px !important;

    @media (max-width : 1600px) {
        height: 100px;
        padding: 14px !important;
    }

    @media (max-width : 991px) {
        padding: 12px !important;
        height: 60px;
        margin-bottom: 30px !important;
        font-size: 14px !important;
    }

    @media (max-width : 575px) {
        padding: 10px 0 10px 10px !important;
    }

    &::placeholder {
        font-family: 'Matter';
        font-style: normal;
        font-weight: 400;
        font-size: 17px !important;
        line-height: 98.9%;
        letter-spacing: -0.04em;
        color: rgba(89, 89, 89, 0.24);

        @media (max-width : 575px) {
            font-size: 14px !important;
            line-height: 14px;
        }
    }

    &:focus-visible {
        outline: none !important;
    }

    .details {
        border: none;

    }

}

.profile-connet-wrapper {
    margin-bottom: 20px;

    @media (max-width : 991px) {
        margin-bottom: 10px;
    }

    p {
        font-family: 'Matter';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 98.9%;
        color: #595959;
        padding-bottom: 10px;

        @media (max-width : 991px) {
            font-size: 18px;
        }
    }

    .connect-btn {
        display: flex;
        align-items: center;
        width: 170px;
        height: 40px;
        padding: 0 30px 0 8px;
        background-color: #000;
        color: #fff;
        font-family: 'Matter';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 98.9%;
        margin-bottom: 19px;
        border-radius: 0;

        @media (max-width : 991px) {
            // width: 170px;
            // height: 42px;
            padding: 0 24px 0 8px;
        }

        @media (max-width : 575px) {
            width: 150px;
            height: 36px;
            padding: 0 20px 0 8px;
            font-size: 14px;
        }

        &:hover {
            background-color: #000 !important;
        }

        img {
            padding-right: 30px;
        }
    }
}

.links-btn {
    display: flex;
    align-items: center;
    justify-content: flex-start !important;
    width: 220px !important;
    height: 40px !important;
    padding: 0 0 0 8px !important;
    background-color: #000 !important;
    color: #9C9C9C !important;
    font-family: 'Matter' !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 98.9% !important;
    margin-bottom: 29px !important;
    border-radius: 0 !important;

    @media (max-width : 991px) {
        width: 220px !important;
        height: 44px !important;
    }

    @media (max-width : 575px) {
        width: 190px !important;
        height: 40px !important;
        font-size: 14px !important;
    }

    &:hover {
        background-color: #000 !important;
    }

    img {
        padding-right: 13px;
    }
}

.file-icon {
    button {
        color: #000000;
    }
}

.icon-fav-heart {
    width: 20px;
    height: 20px;
    position: absolute;
    top: 12px;
    right: 12px;
}

.NFT-details-body {
    margin: 0 !important;

    .slide-image {
        @media (max-width : 1500px) {
            //    height: 580px;
        }

        @media (max-width : 1200px) {
            // height: 550px;
        }

        @media (max-width : 991px) {
            // height: 630px;
        }

        @media (max-width : 768px) {
            height: auto;
        }
    }
}


.ntf-detail-part-1 {
    @media (max-width : 959px) {
        margin-bottom: 30px;
    }
}

.main-authenticity {
    @media (max-width : 960px) {
        margin-bottom: 20px;
    }

    @media (max-width : 575px) {
        margin-bottom: 10px;
    }
}

.title-first {
    text-transform: capitalize;
}

.title-text-line {
    font-family: 'Matter';
    font-style: normal;
    font-weight: 400;
    font-size: 25.8276px !important;
    line-height: 31px !important;
    color: #000000;
    // text-transform: capitalize;

    @media (max-width : 1600px) {
        font-size: 22px !important;
        line-height: 26px !important;
    }

    @media (max-width : 991px) {
        font-size: 20px !important;
        line-height: 22px !important;
    }

    @media (max-width : 768px) {
        font-size: 18px !important;
        line-height: 18px !important;
    }

    @media (max-width : 575px) {
        font-size: 16px !important;
        line-height: 18px !important;
    }
}

.banner-text {
    h6 {
        margin-bottom: 0 !important;
        font-family: 'Matter';
        font-style: normal;
        font-weight: 400;
        font-size: 23.8264px;
        line-height: 29px;
        color: #777777;

        @media(max-width: 1600px) {
            font-size: 20px;
            line-height: 26px;
        }

        @media(max-width: 991px) {
            font-size: 14px;
            line-height: 22px;
        }
    }

    h5 {
        margin-bottom: 0 !important;
        font-family: 'Matter';
        font-style: normal;
        font-weight: 400;
        font-size: 26px;
        line-height: 37px;
        text-align: start !important;
        color: #0E0E0E;
        // -webkit-line-clamp: 1 !important;
        // overflow: hidden !important;
        // -webkit-box-orient: vertical !important;
        // display: -webkit-box !important;

        @media(max-width: 1600px) {
            font-size: 20px;
            line-height: 32px;
        }

        @media(max-width: 768px) {
            font-size: 18px;
            line-height: 26px;
        }
    }
}

.banner-box {
    img {
        max-width: 90vh;
        height: 60vh;

        @media (max-width : 991px) {
            width: 500px;
        }

        @media (max-width : 768px) {
            width: 400px;
            height: 48vh;
        }

        @media (max-width : 576px) {
            width: 100%;
            height: 40vh;
        }
    }
}

// .signup-pwd{
//     .password-icon-btn{
//         padding: 0 !important;
//     }
// }
.password-icon-btn {
    margin: 0 !important;
    // padding: 0 !important;
}

#banner-details {
    // padding-left: 41px;

    // @media(max-width: 1600px) {
    //     padding-left: 32px;
    // }

    // @media(max-width: 959px) {
    //     padding-left: 0;
    //     padding-top: 30px;
    // }

    // @media(max-width: 575px) {
    //     padding-top: 15px;
    // }

    .hero-banner-view-btn {
        margin: 0 !important;

        // @media(max-width: 1350px) {
        //     margin-top: 30px !important;
        // }

        // @media (max-width : 959px) {
        //     margin-top: 40px !important;
        // }

        // @media (max-width : 575px) {
        //     margin-top: 30px !important;
        // }

        .btn-primary {
            padding: 0 0 10px 0;
            font-size: 34px !important;

            @media (max-width : 1600px) {
                font-size: 30px !important;
            }

            @media (max-width : 991px) {
                font-size: 24px !important;
            }

            @media (max-width :768px) {
                font-size: 16px !important;
            }

            span {
                img {
                    width: 29px !important;
                    height: 30px !important;

                    @media (max-width : 1600px) {
                        width: 25px !important;
                        height: 20px !important;
                    }

                    @media (max-width : 991px) {
                        width: 20px !important;
                        height: 18px !important;
                    }

                    @media (max-width : 768px) {
                        width: 14px !important;
                        height: 14px !important;
                    }
                }
            }

            // &:hover {
            //     background-color: transparent !important;
            // }
        }
    }

    h1 {
        font-weight: 600;
        font-size: 75px;
        font-style: normal;
        line-height: 90px !important;
        color: #050505;
        margin-bottom: 47px;
        -webkit-line-clamp: 2 !important;
        overflow: hidden !important;
        -webkit-box-orient: vertical !important;
        display: -webkit-box !important;
        // min-height: 120px;

        @media(max-width: 1600px) {
            font-size: 60px;
            margin-bottom: 20px;
            line-height: 76px !important;
            min-height: 80px;
        }

        @media(max-width: 991px) {
            font-size: 50px;
            line-height: 65px !important;
            min-height: 62px;
        }

        @media(max-width: 768px) {
            font-size: 40px;
            line-height: 55px !important;
            min-height: 47px;
        }

        @media(max-width: 575px) {
            font-size: 24px;
            line-height: 38px !important;
            min-height: 40px;
        }
    }

    p {
        font-style: normal;
        font-weight: 400 !important;
        font-size: 20px !important;
        line-height: 25px !important;
        padding: 0 !important;
        color: #000000;
        margin-bottom: 61px;
        -webkit-line-clamp: 3 !important;
        overflow: hidden !important;
        -webkit-box-orient: vertical !important;
        display: -webkit-box !important;
        text-align: justify;

        @media(max-width: 1600px) {
            font-size: 18px !important;
            margin-bottom: 55px !important;
        }

        @media(max-width: 991px) {
            font-size: 15px !important;
            margin-bottom: 40px !important;
        }

        @media(max-width: 768px) {
            font-size: 15px !important;
            margin-bottom: 20px !important;
        }
    }

    .collage-mobile {
        margin-bottom: 76px !important;

        @media(max-width: 1600px) {
            margin-bottom: 55px !important;
        }

        @media(max-width: 991px) {
            margin-bottom: 40px !important;
        }

        @media(max-width: 768px) {
            margin-bottom: 20px !important;
        }
    }
}

.exhibition-py {
    padding: 30px 0 !important;
    margin-bottom: 97px !important;

    @media(max-width: 1600px) {
        padding: 10px 0 !important;
        margin-bottom: 50px !important;
    }

    @media(max-width: 991px) {
        margin-bottom: 40px !important;
    }

    @media(max-width: 768px) {
        margin-bottom: 26px !important;
    }

}

.artwork-py {
    padding: 30px 0 !important;
    margin-bottom: 73px !important;

    @media(max-width: 1600px) {
        padding: 10px 0 !important;
        margin-bottom: 50px !important;
    }

    @media(max-width: 991px) {
        margin-bottom: 40px !important;
    }

    @media(max-width: 768px) {
        margin-bottom: 26px !important;
    }
}

.artist-py {
    padding: 30px 0 !important;
    margin-bottom: 77px !important;

    @media(max-width: 1600px) {
        padding: 10px 0 !important;
        margin-bottom: 50px !important;
    }

    @media(max-width: 991px) {
        margin-bottom: 40px !important;
    }

    @media(max-width: 768px) {
        margin-bottom: 26px !important;
    }
}

.works-py {
    padding: 30px 0 !important;
    margin-bottom: 100px !important;

    @media(max-width: 1600px) {
        padding: 10px 0 !important;
        margin-bottom: 50px !important;
    }

    @media(max-width: 991px) {
        margin-bottom: 40px !important;
    }

    @media(max-width: 768px) {
        margin-bottom: 26px !important;
    }
}

.upcoming-exhibitions-py {
    padding: 30px 0 !important;
    margin-bottom: 56px !important;

    @media(max-width: 1600px) {
        padding: 10px 0 !important;
        margin-bottom: 50px !important;
    }

    @media(max-width: 991px) {
        margin-bottom: 40px !important;
    }

    @media(max-width: 768px) {
        margin-bottom: 26px !important;
    }
}

.about-title-box {
    border-top: 1px solid #000;
    border-bottom: 1px solid #000;
    padding: 35px 0;
    margin-bottom: 108px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media(max-width: 1600px) {
        margin-bottom: 50px;
        padding: 10px 0;
    }

    @media(max-width: 991px) {
        margin-bottom: 30px;
    }

    @media(max-width: 768px) {
        padding: 13px 0;
        margin-bottom: 20px;
    }

    .about-title {
        text-align: justify;
        font-weight: 600;
        font-size: 60px;
        line-height: 72px;
        color: #000000;

        @media(max-width: 1600px) {
            font-size: 40px;
            line-height: 55px;
        }

        @media(max-width: 991px) {
            font-size: 40px;
            line-height: 45px;
        }

        @media(max-width: 768px) {
            font-size: 35px;
            line-height: 36px;
        }

        @media(max-width: 575px) {
            font-size: 23px;
            line-height: 25px;
        }
    }

    a {
        padding: 0 !important;

        @media(max-width: 991px) {
            font-size: 22px !important;
        }

        @media(max-width: 768px) {
            font-size: 20px !important;
        }

        @media(max-width: 500px) {
            font-size: 18px !important;
        }
    }
}

#hero-button {
    border-radius: 0px;
    color: $input-text;
    font-weight: 400;
    font-size: 30px;
    line-height: 36px;
    font-family: 'Matter';

    &:hover {
        background-color: transparent !important;
    }


    @media (max-width : 1600px) {
        font-size: 24px !important;
    }

    @media (max-width : 991px) {
        font-size: 20px !important;
    }

    @media (max-width : 768px) {
        font-size: 18px !important;
    }

    span {
        align-items: baseline;

        img {
            width: 20px;
            height: 20.47px;

            @media(max-width: 991px) {
                width: 14px !important;
                height: 14px !important;
            }
        }
    }
}

.about-content-box {
    margin-bottom: 50px;

    @media(max-width: 1600px) {
        margin-bottom: 35px;
    }

    @media(max-width: 991px) {
        margin-bottom: 20px;
    }

    .about-title {
        font-family: 'Matter';
        font-weight: 400;
        font-size: 25.74px;
        line-height: 31px;
        color: #000000;

        @media(max-width: 1600px) {
            font-size: 21px;
            line-height: 28px;
        }

        @media(max-width: 991px) {
            font-size: 18px;
            line-height: 25px;
            text-align: justify;
        }
    }
}

.exhibition-img-block {
    // max-height: 270px;
    // min-height: 270px;
    // height: 270px;
    overflow: hidden;
    // margin: -1px;

    @media(max-width: 768px) {
        // max-height: 100%;
        // height: 100%;
    }

    @media(max-width: 400px) {
        // height: 230px;

    }
}

.about-wrapper-grid-box {
    display: flex;
    justify-content: center;
    align-items: center;
}

.about-wrapper-img-box {
    img {
        width: 210px;
    }
}

.about-image-box {
    text-align: center;

    @media(max-width: 960px) {
        // margin-bottom: 20px;
    }
}

.exhibition-box-content a {
    height: 100%;
    display: block;
}

.exhibition {
    position: relative;
    border: 1px solid #000;
    background-color: #F5F5F5;
    height: 100%;

    img {
        // width: 100%;
        object-fit: cover;
        // height: 100%;

        @media(max-width: 768px) {
            width: 100%;
        }
    }

    .exhibition-tag {
        font-family: 'Matter';
        font-size: 12.1453px;
        line-height: 15px;
        padding: 3px 6px;
        background-color: #57BFA4;
        position: absolute;
        top: 10px;
        right: 10px;

        @media(max-width: 575px) {
            font-size: 10px;
            line-height: 12px;
            padding: 3px 3px;
            top: 5px;
            right: 5px;
        }
    }

    .exhibition-tag-color {
        background-color: #fff !important;
        font-size: 7.99401px !important;
        line-height: 10px !important;
    }
}

.exhibition-box-content {
    @media(max-width: 768px) {
        // margin: 0 0 15px !important;
        margin-right: 0 !important;
    }

    .exhibition-content {
        padding: 16px 20px;
        text-align: left;
        display: inline-block;
        vertical-align: middle;
        width: 100%;
        // height: 250px;

        @media(max-width: 1600px) {
            // height: 230px;
        }

        @media(max-width: 991px) {
            padding: 12px;
            height: auto;
        }

        h1 {
            font-weight: 600;
            font-size: 42.9811px;
            line-height: 42px !important;
            margin-bottom: 20px !important;
            font-style: normal;
            color: #000000;
            height: 88px;
            -webkit-line-clamp: 2 !important;
            overflow: hidden !important;
            -webkit-box-orient: vertical !important;
            display: -webkit-box !important;

            @media(max-width: 1600px) {
                font-size: 38px;
                line-height: 38px !important;
                height: 82px;
            }

            @media (max-width: 991px) {
                font-size: 26px;
                line-height: 30px !important;
                text-align: left;
                height: 65px;
            }

            @media (max-width: 768px) {
                font-size: 24px;
                line-height: 30px !important;
                text-align: left;
            }
        }

        .exhibition-description {
            display: flex;
            align-items: center;
            justify-content: space-between;
            min-height: 65px;

            @media (max-width: 991px) {
                flex-direction: column;
                align-items: flex-start;
            }

            .collage-mobile {
                display: flex;
                align-items: center;
                width: 100%;

                @media (max-width: 1200px) {
                    width: 100%;
                }

                @media (max-width: 991px) {
                    width: 100%;
                    padding-bottom: 10px;
                }

                .banner-text {
                    width: 50%;

                    @media(max-width: 1200px) {
                        width: 50%;
                    }

                    @media(max-width: 991px) {
                        width: 80%;
                    }

                    h6 {
                        font-family: 'Matter';
                        font-weight: 400;
                        font-size: 13px;
                        line-height: 16px;
                        color: #777777;

                        @media(max-width: 1199px) {
                            font-size: 12px;
                        }
                    }

                    h5 {
                        font-family: 'Matter';
                        font-weight: 400;
                        font-size: 20px;
                        line-height: 24px;

                        @media(max-width: 1600px) {
                            font-size: 18px;
                        }

                        @media(max-width: 768px) {
                            font-size: 16px;
                        }
                    }
                }
            }

            .active-exhibitions-type-text-main {
                padding-left: 10px;
                width: 75%;

                @media (max-width: 1200px) {
                    width: 70%;
                }

                @media (max-width: 991px) {
                    width: 100%;
                }
            }

            .exhibition-time-content-description,
            .active-exhibitions-type-text {
                font-family: 'Matter';
                font-style: normal;
                font-weight: 400;
                font-size: 19px !important;
                line-height: 21px !important;
                color: #000;
                margin-bottom: 0;
                -webkit-line-clamp: 2 !important;
                overflow: hidden !important;
                -webkit-box-orient: vertical !important;
                display: -webkit-box !important;
                height: 65px;
                margin-top: 0 !important;

                @media(max-width: 1600px) {
                    font-size: 17px !important;
                    line-height: 20px !important;
                }

                @media (max-width: 991px) {
                    font-size: 14px !important;
                    line-height: 18px !important;
                    width: 100%;
                    margin-top: 10px;
                    height: 55px;
                }

                p {
                    margin-top: 0;
                }
            }

            .exhibition-time {
                display: flex;
                align-items: center;

                img {
                    width: 21px;
                    height: 28px;
                    margin-right: 10px;
                }

                .exhibition-time-content {
                    p {


                        font-size: 23px;
                        line-height: 23px;
                        font-family: 'Matter';
                        width: 100%;
                        margin: 0;

                        @media (max-width : 1024px) {
                            font-size: 20px;
                        }

                        @media (max-width : 575px) {
                            font-size: 16px;
                        }
                    }

                    h6 {
                        font-family: 'Matter';
                        font-size: 12px;
                        line-height: 14px;
                        width: 100%;
                        // margin-top: 10px;
                    }
                }
            }
        }

        .exhibition-button {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin: 15px 0 0 !important;

            .exhibition-icon {
                // display: flex;
                // align-items: center;

                // img {
                //     width: 28px;
                //     height: 28px;
                //     margin-right: 4px;
                // }

                h6 {
                    margin-bottom: 0;
                    font-family: 'Matter';
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 17px;
                    -webkit-line-clamp: 1 !important;
                    overflow: hidden !important;
                    -webkit-box-orient: vertical !important;
                    display: -webkit-box !important;
                }
            }

            #hero-button {
                font-family: 'Matter';
                font-weight: 400;
                font-size: 17px !important;
                line-height: 22px;
                padding: 0;
                border-bottom: 1px solid transparent;

                @media(max-width: 1600px) {
                    font-size: 15px !important;
                }
            }
        }
    }
}

.exhibition-icon-grid-wrapper {
    width: 50%;
}

.exhibition-time-content-over {
    p {
        font-family: 'Matter';
        font-style: normal;
        font-weight: 400;
        font-size: 19px !important;
        line-height: 21px !important;
        color: #000;
        margin-bottom: 0;
        -webkit-line-clamp: 2 !important;
        overflow: hidden !important;
        -webkit-box-orient: vertical !important;
        display: -webkit-box !important;
        margin-top: 0 !important;
        height: auto !important;

        @media(max-width: 1600px) {
            font-size: 17px !important;
        }

        @media(max-width: 768px) {
            font-size: 15px !important;
        }

    }
}

.exhibition-time-content-description {
    font-family: 'Matter';
    font-style: normal;
    font-weight: 400;
    font-size: 13.4724px !important;
    line-height: 17px !important;
    color: #000000;

}

.exhibition:hover .exhibition-button a#hero-button {
    border-bottom: 1px solid #000;
}

.work-read-more {
    text-align: end;

    a {
        display: inline-block;
        vertical-align: middle;

        span {
            display: flex;
            align-items: center;
            justify-content: end;
        }
    }
}

.work-read-more a#hero-button {
    padding: 0;
}

.about-title-box a#hero-button,
.work-read-more a#hero-button {
    position: relative;
}

.work-read-more a#hero-button::after {
    position: absolute;
    content: '';
    width: 87%;
    height: 2px;
    background-color: #000;
    bottom: 0;
    opacity: 0;
    left: 0;
}

.property-history {
    width: 87%;
}

.about-title-box a#hero-button::after {
    position: absolute;
    content: '';
    width: 78%;
    height: 2px;
    background-color: #000;
    bottom: 0;
    opacity: 0;
    left: 0;
}

.copied-icon {
    span {
        svg {
            color: #000 !important;
        }
    }
}

.about-title-box a#hero-button:hover::after,
.work-read-more a#hero-button:hover::after {
    opacity: 1;
}

.artwork-wrapper {
    .profile-card {
        width: 100%;
    }

    .card-header {
        display: none;
    }
}

.artist-box {
    overflow: hidden;
    border: 1px solid #000;

    .artist-box-img {
        // width: 345px;
        // height: 350px;
        // width: 100%;
        height: 315px;
        display: flex;
        justify-content: center;

        img {
            // width: 100%;
            // height: 100%;
            object-fit: cover;
            object-position: top;

            @media(max-width: 1200px) {
                width: 100%;
                height: 100%;
            }
        }
    }

    .artist-content {
        // border: 1px solid #000;
        // border-top: none;
        // min-height: 200px;
        padding: 17px 13px;

        @media (max-width:1600px) {
            // min-height: 174px;
        }

        @media (max-width:991px) {
            // min-height: 136px;
        }

        .artist-title {
            color: #202020;
            margin-bottom: 29px;
            font-weight: 400;
            font-size: 45.2255px;
            line-height: 54px;
            min-height: 112px;
            -webkit-line-clamp: 2;
            overflow: hidden;
            -webkit-box-orient: vertical;
            display: -webkit-box;

            @media(max-width: 1600px) {
                font-size: 38px;
                line-height: 47px;
                min-height: 94px;
                margin-bottom: 22px;
            }

            @media(max-width: 991px) {
                font-size: 22px;
                line-height: 30px;
                min-height: 62px;
                margin-bottom: 16px;
            }
        }

        .artist-bottom-content {
            display: flex;
            align-items: center;

            img {
                margin-right: 4px;
                width: 28px;
                height: 28px;
            }
        }
    }
}

.works {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: left;
    margin-bottom: 110px;

    @media(max-width: 1600px) {
        margin-bottom: 70px;
    }

    @media(max-width: 991px) {
        margin-bottom: 30px;
        flex-direction: column;
    }

    @media(max-width: 768px) {
        margin-bottom: 20px;
    }

    @media(max-width: 575px) {
        margin-bottom: 10px;
    }

    .work-title {
        font-weight: 600;
        font-size: 33.4928px;
        line-height: 40px;
        color: #000000;
        margin-bottom: 30px;

        @media(max-width: 1600px) {
            margin-bottom: 15px;
            font-size: 26px;
            line-height: 26px;
        }

        @media(max-width: 768px) {
            margin-bottom: 10px;
            font-size: 20px;
            line-height: 20px;
        }
    }

    .work-description {
        font-size: 16px;
        line-height: 20px;
        font-family: 'Matter';
        margin-bottom: 0 !important;

        @media(max-width: 991px) {
            font-size: 15px;
            margin-bottom: 15px !important;
        }
    }

    .curator {
        padding-right: 70px;

        @media(max-width: 1600px) {
            padding-right: 40px;
        }

        @media(max-width: 991px) {
            // padding-right: 20px;
            padding-right: 0;
        }

        @media(max-width: 768px) {
            padding: 0 10px;
        }
    }

    .artist {
        padding: 0 70px 0 112px;
        border-left: 1px solid #000;
        border-right: 1px solid #000;

        @media(max-width: 1600px) {
            padding: 0 40px;
        }

        @media(max-width: 991px) {
            // padding: 0 20px;
            padding: 0;
            border: none;
        }

        @media(max-width: 768px) {

            padding: 0 10px;
        }
    }

    .collector {
        padding-left: 112px;

        @media(max-width: 1600px) {
            padding-left: 40px;
        }

        @media(max-width: 991px) {
            // padding-left: 20px;
            padding-left: 0;
        }

        @media(max-width: 768px) {
            padding: 0 10px;
        }
    }
}

.item-art-name {
    font-size: 32.8288px;
    line-height: 39px;

    @media(max-width: 1600px) {
        font-size: 26px !important;
        line-height: 34px !important;
    }

    @media(max-width: 991px) {
        font-size: 22px !important;
        line-height: 28px !important;
    }
}

.profile-img-name-tag {
    display: flex;
    align-items: center;
    padding: 0 !important;
}

.profile-name-tag-text {
    margin-top: 0 !important;
}

.account-box {
    border: 0.972744px solid #000000;
    margin-bottom: 20px;
    padding: 21.93px 31px 29.63px 30px;

    @media (max-width: 991px) {
        padding: 20px;
    }

    @media (max-width: 768px) {
        padding: 16px;
    }

    @media (max-width: 575px) {
        padding: 12px;
    }
}

.account-setting {
    padding: 20px;
    display: flex;
    flex-wrap: wrap;
    border-top: 1px solid #000;
    padding: 70px 0 57px 82px;

    @media (max-width : 1600px) {
        padding: 40px 0 50px 70px;
    }

    @media (max-width : 991px) {
        padding: 30px 0 40px 40px;
    }

    @media (max-width : 600px) {
        padding: 20px 12px 30px 12px;
    }

    .setting-box {

        .profile-card {
            padding: 0 !important;

            .card-header {
                padding: 0 0 14.05px 0 !important;
                margin: 0 0 20px 0 !important;
                border-bottom: 1px solid #000 !important;

                @media (max-width : 991px) {
                    padding: 0 0 10px 0 !important;
                    margin: 0 0 14px 0 !important;
                }

                @media (max-width : 575px) {
                    padding: 0 0 6px 0 !important;
                    margin: 0 0 10px 0 !important;
                }

                h3 {
                    font-family: 'Matter';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 23.434px !important;
                    line-height: 35px;
                    color: #000000;

                    @media (max-width : 991px) {
                        font-size: 20px !important;
                        line-height: 28px;
                    }

                    @media (max-width : 575px) {
                        font-size: 16px !important;
                        line-height: 20px;
                    }
                }
            }

            .card-header-btn {
                padding: 0 0 14.05px 0 !important;
                margin: 0 0 20px 0 !important;
                border-bottom: 1px solid #000 !important;

                @media (max-width : 991px) {
                    padding: 0 0 10px 0 !important;
                    margin: 0 0 14px 0 !important;
                }

                @media (max-width : 575px) {
                    padding: 0 0 6px 0 !important;
                    margin: 0 0 10px 0 !important;
                }

                h3 {
                    font-family: 'Matter';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 23.3459px;
                    line-height: 35px;
                    letter-spacing: -0.015em;
                    color: #000000;

                    @media (max-width : 991px) {
                        font-size: 20px !important;
                        line-height: 28px;
                    }

                    @media (max-width : 575px) {
                        font-size: 16px !important;
                        line-height: 20px;
                    }
                }
            }

            .subtitle {
                margin: 0 !important;
                font-family: 'Matter';
                font-style: normal;
                font-weight: 400;
                font-size: 16.5367px;
                line-height: 98.9%;
                letter-spacing: -0.015em;
                color: #A2A2A2;

                @media (max-width : 991px) {
                    font-size: 16px !important;
                    line-height: 16px;
                }

                @media (max-width : 768px) {
                    font-size: 14px !important;
                    line-height: 14px;
                }
            }
        }
    }

    .kyc-wrapper {
        border: 0.972744px solid #000000;
        ;
        // margin-bottom: 23px;
        // margin-right: 30px;
        padding: 23px 32px 40.74px 32px;

        @media (max-width : 1279px) {
            // margin-right: 0;
        }

        @media (max-width : 991px) {
            padding: 20px;
        }

        @media (max-width : 768px) {
            padding: 16px;
        }

        @media (max-width : 575px) {
            padding: 12px;
        }
    }

    .bank-wrapper {
        @media (max-width : 991px) {
            // padding: 15px;
        }
    }

    .crypto-wrapper {
        border: 1px solid #000;
        margin-right: 30px;
        padding: 19.53px 21.48px 21.48px 15.62px;

        @media (max-width :1279px) {
            margin-bottom: 23px;
            margin-right: 0;
        }

        @media (max-width : 991px) {
            padding: 15px;
        }

    }

    .factor-wrapper {
        border: 1px solid #000;
        padding: 19.53px 21.48px 21.48px 15.62px;

        @media (max-width : 991px) {
            padding: 15px;
        }
    }
}

.kyc-sub-border {
    width: 1px;
    height: 220px;
    background-color: #000;
    margin-top: 50px;

}

.kyc-select {
    position: relative;

    &>div {
        &::after {
            position: absolute;
            content: '';
            width: 16px;
            height: 10px;
            background-image: url(../../assets/gradients/kyc-select-icon.svg);
            background-repeat: no-repeat;
            top: 18px;
            right: 16px;
        }
    }

    svg {
        display: none;
    }
}

.kyc-main-box-wrapper {
    margin-top: 40px;

    @media (max-width : 1279px) {
        margin-top: 30px;
    }

    @media (max-width : 575px) {
        margin-top: 20px;
    }
}

.kyc-box-wrapper {
    display: flex;
    justify-content: space-between;

    @media (max-width : 1279px) {
        flex-direction: column;
    }

    .kyc-one {

        // width: 50%;
        .kyc-selfie-box {
            display: flex;
            align-items: center;

            .upload-wrapper {
                margin-right: 16px;
            }
        }
    }

    .kyc-two {
        padding-right: 25px;

        @media (max-width:1279px) {
            padding-right: 0;
        }
    }
}

.upload-div {
    flex-direction: column;

    .kyc-text-line {
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding-bottom: 17px;

        @media (max-width:768px) {
            padding-bottom: 10px;
        }

        p {
            font-family: 'Matter';
            font-style: normal;
            font-weight: 300;
            font-size: 9.96915px;
            line-height: 15px;
            letter-spacing: -0.015em;
            text-transform: capitalize;
            color: #000000;
        }
    }

    .kyc-box-photo {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;

        .kyc-box-image {
            width: 50%;
        }
    }
}

.kyc-subline {
    text-align: center;
    padding: 0 !important;

    @media (max-width : 1279px) {
        text-align: start;
    }
}

.account-devider {
    border: 1px solid #000 !important;
}

.address-wrapper {
    padding-right: 15px;

    @media (max-width : 1279px) {
        padding-right: 0;
    }
}

.address-sub-wrapper {
    padding-left: 15px;

    @media (max-width : 1279px) {
        padding-left: 0;
    }
}

.adress-sub-wrapper {
    .address-line {
        padding-bottom: 30px;

        @media (max-width : 991px) {
            padding-bottom: 20px;
        }

        .subtitle {
            height: 35px;
        }
    }

    .adress-data {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .small-line {
            width: 60%;
            font-weight: 400 !important;
            font-size: 13.9675px !important;
            line-height: 21px !important;
            color: #000 !important;
            display: inline-block;

            @media (max-width:768px) {
                font-size: 10px !important;
                line-height: 14px !important;
            }
        }
    }
}

.profile-card-table {
    th {
        width: 40%;
        padding: 8px 0 !important;
        font-family: 'Matter';
        line-height: 20px;
        vertical-align: top !important;

        @media (max-width:399px) {
            width: 50%;
        }
    }

    td {
        padding: 8px 0 !important;
        font-family: 'Matter';
        line-height: 20px;
    }
}

.address-data-box {
    min-height: 380px;
    max-height: 380px;

    @media(max-width:1279px) {
        min-height: auto;
        max-height: 100%;
    }
}

.adress-data-text {
    p {
        margin: 0 !important;
        padding: 5px 0 !important;
    }
}

.adress-data-add-btn {
    justify-content: flex-end !important;
}

.addressBtn {
    padding: 13.13px 19.69px 13.13px 45px !important;
    background: #000000 !important;
    border-radius: 7.66273px !important;
    display: block;
    width: auto !important;

    @media (max-width : 991px) {
        padding: 10px 15px 10px 35px !important;
    }

    @media (max-width : 575px) {
        padding: 7px 10px 7px 30px !important;
    }

    span {
        &>span {
            position: relative;
            font-style: normal !important;
            font-weight: 500 !important;
            font-size: 19.1568px !important;
            line-height: 23px !important;
            letter-spacing: -0.015em;
            color: #FFFFFF;

            @media (max-width : 1600px) {
                font-size: 15px !important;
            }

            @media (max-width : 991px) {
                font-size: 13px !important;
            }

            &::after {
                position: absolute;
                content: '';
                width: 22px;
                height: 22px;
                background-image: url(../../assets/gradients/adress-btn-icon.svg);
                background-repeat: no-repeat;
                top: 1px;
                left: -30px;

                @media (max-width : 991px) {
                    left: -26px;
                }
            }
        }
    }
}

.uploaded-docdiv {
    display: flex;
}

.verify-btn-kyc {
    margin-top: 40px !important;
    padding: 13.13px 80px !important;
    font-style: normal;
    font-weight: 400 !important;
    font-size: 19.1568px !important;
    line-height: 23px !important;
    font-family: 'Matter' !important;
    letter-spacing: -0.015em;
    color: #FFFFFF;
    border-radius: 7.66273px !important;

    @media (max-width: 991px) {
        padding: 10px 50px !important;
        font-size: 15px !important;
        line-height: 20px !important;
    }

    @media (max-width: 768px) {
        padding: 8px 34px !important;
        font-size: 15px !important;
        line-height: 20px !important;
    }

    @media (max-width: 575px) {
        padding: 8px 24px !important;
        font-size: 15px !important;
        line-height: 18px !important;
    }
}

.kyc-sub-border-grid {
    display: flex;
    justify-content: center;

    @media (max-width : 1279px) {
        display: none;
    }
}

.kyc-btn-grid {
    display: flex;
    justify-content: flex-end;
}

.upload-btn {
    padding: 6.57px 8.17px !important;
    border-radius: 3.75645px !important;
    margin: 0 !important;
    align-items: center;

    img {
        width: 10px;
        height: 10px;
    }

    p {
        font-style: normal;
        font-weight: 400 !important;
        font-size: 9.39113px !important;
        // line-height: 11px !important;
        letter-spacing: 0.015em;
        color: #FFFFFF;
    }
}

.address-btn {
    color: #000 !important;
    font-family: 'Matter' !important;
}

.kyc-label-select {
    margin-bottom: 5px;
}

.kyc-label,
.selfie-label {
    font-family: 'Matter' !important;
    font-style: normal;
    font-size: 17.8992px !important;
    line-height: 27px !important;
    font-weight: 600 !important;
    letter-spacing: -0.015em;
    text-transform: capitalize;
    color: #000000 !important;

    @media (max-width : 991px) {
        font-size: 15px !important;
        line-height: 16px !important;
    }
}

.kyc-label {
    padding: 10px 0 0 0 !important;

    @media (max-width:1600px) {
        padding: 0 0 6px 0 !important;
    }
}

.doc-upload-container {
    margin-right: 10px;

    img {
        width: 100%;
    }
}

.kyc-grid-top-box {
    margin-top: 32px;

    @media (max-width:991px) {
        margin-top: 24px;
    }
}

.kyc-grid-second-box {
    @media (max-width:1279px) {
        margin-top: 32px;
    }

    @media (max-width:991px) {
        margin-top: 24px;
    }
}

.kyc-grid-third-box {
    margin-top: 16px;

    @media (max-width:1279px) {
        margin-top: 32px;
    }

    @media (max-width:991px) {
        margin-top: 24px;
    }
}

.upload-div {
    @media (max-width : 425px) {
        flex-wrap: wrap;
    }

    p {
        font-family: 'Matter' !important;
    }
}

.kyc-select {
    border-radius: 7px !important;

    &>div {
        padding: 12px 0 12px 27px !important;
        margin-right: 50px !important;
        border-right: 1px solid #000;
        border-radius: 0;

        // color: #B6B6B6 !important;
        @media (max-width : 991px) {
            padding: 10px 0 10px 20px !important;
        }

        @media (max-width : 575px) {
            padding: 10px 0 10px 10px !important;
        }

        p {
            font-family: 'Matter' !important;
            font-style: normal;
            font-weight: 400;
            font-size: 11.7936px;
            // line-height: 98.9%;
            letter-spacing: -0.015em;
            text-transform: capitalize;
            color: #B6B6B6;

            @media (max-width : 575px) {
                font-size: 12px;
            }
        }
    }
}

.select-box {
    fieldset {
        border: 1px solid #000 !important;
    }
}

.profile-re-wallet {
    display: flex;
    justify-content: end;

    .tus-wallet {
        h4 {
            font-family: 'Matter';
            font-style: normal;
            font-weight: 400;
            font-size: 17px;
            line-height: 99%;
            color: #000000;
            margin-bottom: 10px;
        }

        p {
            font-family: 'Matter';
            font-style: normal;
            font-weight: 400;
            font-size: 17px;
            line-height: 99%;
            color: #000000;
        }
    }
}

.wallet-btn {
    padding: 10px 20px !important;
    width: 100px;
    margin-top: 20px !important;
    font-size: 16px !important;

    @media (max-width : 991px) {
        padding: 8px 14px !important;
        width: 80px;
        margin-top: 20px !important;
        font-size: 14px !important;
    }
}

.profile-name-tag {

    display: flex;
    align-items: center;
    margin-left: 20px !important;
}

.spacing-minus {
    padding: 0 82px 178px 82px;
    margin: 0 !important;

    @media (max-width : 1600px) {
        padding: 0 60px 150px 60px;
    }

    @media (max-width : 991px) {
        padding: 0 50px 100px 40px;
    }

    @media (max-width : 768px) {
        padding: 0 30px 60px 30px;
    }

    @media (max-width : 575px) {
        padding: 0 12px 60px 12px;
    }
}

.left-panel-main {
    margin: 0 !important;
    justify-content: space-between;
    align-items: center;
    height: auto !important;
}

.profile-menu-tag {
    position: relative;

    img {
        position: absolute;
        top: 12px;
        left: 75px;
        width: 22px;
        height: 22px;

        @media (max-width : 1600px) {
            left: 57px;
        }

        @media (max-width : 1200px) {
            left: 30px;
            top: 8px;
        }

        @media (max-width : 991px) {
            left: 20px;
            width: 18px;
            height: 18px;
            top: 10px;
        }

        @media (max-width : 768px) {
            left: 15px;
            // top: 8px;
        }
    }
}

.nft-image-container {
    .item-image {
        text-align: center;
        // padding: 40px 0;
    }
}

.profile-wrapper .MuiContainer-maxWidthLg {
    max-width: 100%;
}

.tus-wallet~.tus-wallet {
    padding-left: 30px;
}

.signup-h3 {
    font-size: 31px !important;

    @media (max-width : 600px) {
        font-size: 24px !important;
    }

    @media (max-width : 400px) {
        font-size: 20px !important;
    }
}


.metamask-form {
    .metamask-img-icon {
        h5 {
            font-family: 'Matter';
            font-style: normal;
            font-weight: 400;
            font-size: 17px;
            line-height: 99%;
            color: #000000;
            padding-bottom: 5px;
        }

        div {
            p {
                font-family: 'Matter';
                font-style: normal;
                font-weight: 400;
                font-size: 13px;
                line-height: 99%;
                color: #000000;
            }
        }
    }

    .title-content {
        margin-bottom: 23px;
    }

    .metamask-logo {
        margin-top: 0 !important;
        margin-bottom: 26px;

        .metamask-logo-image {
            width: 60px !important;
            height: 60px !important;
            margin-right: 15px;

            @media (max-width : 600px) {
                width: 40px !important;
                height: 40px !important;
            }
        }
    }

    .metamask-name-field {
        padding: 0 !important;
    }

    .metamask-email-field {
        padding: 0 !important;

        .auth-input {
            margin-bottom: 33px !important;
        }
    }

    .align-center {
        padding: 0;
        margin-bottom: 33px;

        p {
            font-family: 'Matter';
            font-style: normal;
            font-weight: 400;
            font-size: 17px;
            line-height: 99%;
            color: #000000;

            .primary {
                color: #000 !important;
            }

            @media (max-width : 600px) {
                font-size: 15px;
            }
        }

        svg {
            color: #000 !important;
        }
    }

    .meta-account-tag {
        padding: 0;

        .meta-account-wrapper {
            display: block;

            .start-content {
                display: block !important;
                text-align: center;
                margin-bottom: 20px;

                a {
                    font-family: 'Matter';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 17px;
                    line-height: 99%;
                    color: #000000;

                    @media (max-width : 600px) {
                        font-size: 15px;
                    }
                }
            }

            .meta-wrapper-btn {
                text-align: center;

                .btn-primary {
                    @media (max-width : 600px) {
                        padding: 10px !important;
                    }
                }
            }

            .primary {
                color: #000 !important;
            }
        }
    }
}

.meta-input-text {
    .primary {
        color: #000 !important;
    }
}

.meta-button {
    background-color: #000 !important;
    box-shadow: none !important;

    span {
        font-family: 'Matter';
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 99%;
        // color: #000000;
    }
}

#tandc {
    svg {
        color: #000 !important;
    }

    .primary {
        color: #000 !important;
    }
}

.sign-black {
    color: #000 !important;
}

.meta-main-sign {
    .auth-content {
        @media (max-width : 600px) {
            width: 400px !important;
        }

        @media (max-width : 400px) {
            width: 100% !important;
        }
    }
}

.meta-imp-btn {
    background-color: #000 !important;
    color: #fff !important;
}

.gradient-bg {
    background: #fff !important;
}


// .home-banner-wrapper-py .slick-list {
//         padding: 0 !important;    
//     .banner-wrapper{
//         align-items: flex-end !important;
//     }
// }

#about-wrapper,
#artist-wrapper,
#exhibition-wrapper,
#home-banner-wrapper,
#upcoming-wrapper,
#works-wrapper {
    .banner-wrapper {
        align-items: center;
    }
}

.home-banner-wrapper-py {
    .slick-slide {
        img {
            display: inline-block !important;
        }
    }
}

@media only screen and (max-width: 959px) {
    .banner-wrapper #banner-details .home-collage-mobile {
        justify-content: center;
        flex-direction: row !important;
        align-items: center;
    }
}

.upcoming-wrraper {
    margin-bottom: 80px !important;

    @media (max-width: 1600px) {
        margin-bottom: 50px !important;
    }

    @media (max-width: 991px) {
        margin-bottom: 0 !important;
    }

    .banner-wrapper {
        .nft-slider {
            // .slick-track {
            //     // width: 100% !important;
            // }

            // .slick-slide {
            //     // width: 100% !important;
            // }

            // .slide-arrow-next {
            //     right: 30px;
            // }

            // .slide-arrow-prev {
            //     left: 30px;
            // }

            .slick-dots {
                margin-top: 0 !important;
            }
        }
    }
}

.home-collage-mobile {
    .banner-text {
        width: 80%;

        @media (max-width: 959px) {
            width: auto;
        }
    }
}

.stock-info {
    padding: 0 !important;

    .Artwork-sell {
        padding: 10px 33px;
        background-color: #000;
        color: #fff;
    }
}

.MuiButtonBase-root.btn-secondary .MuiCircularProgress-root {
    color: #000000 !important;
}

p#eth-balance {
    font-size: 13px;
    margin-top: 6px;
}

.nft-slider .artist-link .my-grid .artist-box:hover,
.nft-item.trans-3d-hover.market-nft:hover,
.nft-item.trans-3d-hover.profile-nft:hover,
.market-slider-wrapper-other .main-my-grid-wrapper .my-grid:hover,
.main-my-grid-wrapper-art .my-grid:hover,
.main-my-grid-wrapper-community .my-grid:hover {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    transform: scale(1.02);
    transition: 0.5s ease-in-out;
}

.home-banner-wrapper-py {
    margin: 100px 0 130px !important;

    @media (max-width: 1600px) {
        margin: 50px 0 100px !important;
    }

    @media (max-width: 991px) {
        margin: 50px 0 50px !important;
    }

    @media (max-width: 768px) {
        margin: 40px 0 40px !important;
    }

    @media (max-width: 575px) {
        margin: 20px 0 40px !important;
    }
}

.about-main-wrraper {
    margin-bottom: 130px;

    @media (max-width: 1600px) {
        margin-bottom: 100px !important;
    }

    @media (max-width: 991px) {
        margin-bottom: 50px !important;
    }

    @media (max-width: 768px) {
        margin-bottom: 40px !important;
    }
}

.exhibition-wrapper-py {
    margin-bottom: 90px;

    @media (max-width: 1600px) {
        margin-bottom: 60px !important;
    }

    @media (max-width: 991px) {
        margin-bottom: 0 !important;
    }
}

.artwork-wrapper-py {
    margin-bottom: 130px;

    @media (max-width: 1600px) {
        margin-bottom: 100px !important;
    }

    @media (max-width: 991px) {
        margin-bottom: 50px !important;
    }

    @media (max-width: 768px) {
        margin-bottom: 40px !important;
    }

}

.artwork-main-wrapper-py {
    margin-bottom: 130px;

    @media (max-width: 1600px) {
        margin-bottom: 100px !important;
    }

    @media (max-width: 991px) {
        margin-bottom: 50px !important;
    }

    @media (max-width: 768px) {
        margin-bottom: 40px !important;
    }

    .my-grid {
        padding: 0 20px !important;

        @media (max-width: 1600px) {
            padding: 0 8px !important;
        }
    }
}

.works-main-wrapper-py {
    margin-bottom: 130px;

    @media (max-width: 1600px) {
        margin-bottom: 100px !important;
    }

    @media (max-width: 991px) {
        margin-bottom: 50px !important;
    }

    @media (max-width: 768px) {
        margin-bottom: 40px !important;
    }

}

.nft-slider {
    .slick-list {
        padding: 15px 0 !important;
    }
}

.EditionNFTCardCount {
    display: none;
    position: absolute;
    right: -0.8rem;
    top: 290px;
}

.nft-item:hover {
    .EditionNFTCardCount {
        font-weight: 600;
        display: block;
    }

    .edition-chip {
        color: #fff;
        background-color: rgba(159, 159, 159, 0.4) !important;
        backdrop-filter: blur(10px) !important;
    }

    // img:nth-child(2){
    //     filter: blur(2px) !important;
    //     -webkit-filter: blur(2px) !important;
    // }
}



.nft-item {
    .item-detail {
        .user-detail {
            padding-top: 17px;
            margin: 0 !important;
        }
    }
}

.nft-item .item-detail {
    .auction-detail {
        display: flex;
        align-items: center;
        padding: 16px;

        .soldBtn {
            justify-content: center;
        }
    }

}

.exibition-artists-tag-center {
    justify-content: flex-start;
}

.artist-link-tab {
    width: 260px;

    @media (max-width : 1600px) {
        width: 230px;
    }

    @media (max-width : 1450px) {
        width: 214px;
    }

    @media (max-width : 1200px) {
        width: 200px;
    }

    @media (max-width : 991px) {
        width: 170px;
    }

    @media (max-width : 768px) {
        width: 180px;
    }

    @media (max-width : 575px) {
        width: 150px;
    }
}

.nft-item .item-detail .auction-detail {
    @media (max-width : 1600px) {
        padding: 10px !important;
    }
}

.stock-info .Artwork-sell {
    @media (max-width : 1600px) {
        padding: 10px 24px !important;
    }
}

.nft-item .item-detail .auction-detail .bid-info,
.nft-item .item-detail .auction-detail .stock-info {
    padding: 0 !important;
}

.nft-item {
    .item-detail {
        .auction-detail {
            .current-bid-value {
                color: #151515;
                font-family: 'Matter';
                font-weight: 400;
                font-size: 20.5017px;
                line-height: 18px;
            }
        }
    }
}

.nft-item .item-detail {
    .auction-detail .stock-info {
        p {
            font-family: 'Matter';
            font-weight: 400;
            font-size: 15.4488px;
            line-height: 19px;
        }
    }
}

.views {
    p {
        font-weight: 400;
        font-size: 18.7642px;
        line-height: 23px;
        font-style: normal;
        color: #000000;
    }
}

svg {
    .MuiCircularProgress-svg {
        color: #000000 !important;
    }
}

.empty-content {
    svg {
        color: #000000 !important;
    }
}

.market-slider {
    .slick-track {
        margin-left: 0;
    }
}

.nft-slider {
    .slick-track {
        margin-left: 0 !important;
    }
}

// .artwork-wrapper-py {
//     .nft-slider {
//         .slick-track {
//             // margin-left: 0!important;
//             // width: 2394px !important;
//         }
//     }
// }

.upcoming-pr {
    padding: 0 14px !important;
    margin-bottom: 39px;

    @media (max-width : 991px) {
        padding: 0 8px !important;
    }
}

.artist-detail-main-container {
    .artist-detail-inner-container img {
        width: auto;
        height: 64vh;
        max-width: 1500px;

        @media (max-width : 1600px) {
            max-width: 1400px;
        }

        @media (max-width : 1550px) {
            max-width: 1300px;
        }

        @media (max-width : 1410px) {
            max-width: 1100px;
        }

        @media (max-width : 1199px) {
            width: 100%;
            height: auto !important;
        }
    }

    .artist-detail-section {
        margin-top: 118px;

        @media (max-width : 1600px) {
            margin-top: 50px;
        }

        @media (max-width : 991px) {
            margin-top: 40px;
        }

        @media (max-width : 768px) {
            margin-top: 20px;
        }

        .artist-Detail-inner-section {
            display: flex;
            margin-bottom: 125px;
            justify-content: center;

            @media(max-width:1600px) {
                margin-bottom: 90px;
            }

            @media(max-width:991px) {
                margin-bottom: 50px;
            }

            @media(max-width:768px) {
                margin-bottom: 40px;
            }

            @media(max-width:991px) {
                flex-wrap: wrap;
            }

            .artist-Datail-image-section {
                @media(max-width:1199px) {
                    // width: 40%;
                }

                @media (max-width : 991px) {
                    // width: 100%;
                    // width: 300px;
                    // height: 300px;
                    text-align: center;
                    margin-bottom: 30px;
                }

                img {
                    width: 400px;
                    height: 400px;
                    border-radius: 50%;
                    object-fit: cover;

                    // @media (max-width : 1600px) {
                    //     width: 400px;
                    //     height: 400px;
                    // }
                    // @media (max-width : 991px) {
                    //     width: 400px;
                    //     height: 400px;
                    // }
                    @media (max-width : 991px) {
                        width: 300px;
                        height: 300px;
                    }

                    @media (max-width : 575px) {
                        width: 250px;
                        height: 250px;
                    }
                }
            }

            .artist-Detail-Description-section {
                margin-left: 110px;
                width: 67%;

                @media(max-width:1199px) {
                    width: 60%;
                    margin-left: 60px;
                }

                @media (max-width : 991px) {
                    width: 100%;
                    text-align: center;
                    margin-left: 0;
                }

                .artist-Detail-Description-Heading {
                    font-style: normal;
                    font-weight: 600;
                    font-size: 60px;
                    line-height: 72px;
                    color: #000000;

                    @media (max-width : 1600px) {
                        font-size: 50px;
                        line-height: 50px;
                    }

                    @media (max-width : 991px) {
                        font-size: 40px;
                        line-height: 40px;
                    }

                    @media (max-width : 768px) {
                        font-size: 34px;
                        line-height: 34px;
                    }

                    @media (max-width : 575px) {
                        font-size: 28px;
                        line-height: 28px;
                    }

                }

                .artist-Detail-social-media {
                    display: flex;
                    align-items: center;
                    padding-top: 23px;

                    @media (max-width : 991px) {
                        justify-content: flex-start !important;
                    }

                    .social-media-icon {
                        margin-right: 20px;

                        img {
                            padding-right: 12px;
                            display: inline-block;
                            vertical-align: middle;

                            @media (max-width : 432px) {
                                width: 38px;
                            }
                        }
                    }


                    .location-icon {
                        display: flex;
                        align-items: center;
                        // margin-left: 20px;

                        @media (max-width : 991px) {
                            margin-top: 0;
                        }

                        @media (max-width : 480px) {
                            margin-top: 0;
                            // margin-left: 0
                        }

                        @media (max-width : 432px) {


                            img {
                                width: 30px;
                            }
                        }

                        .location-detail {
                            font-family: 'Matter';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 25.8264px;
                            line-height: 31px;
                            margin-left: 5px;

                            @media (max-width : 1600px) {
                                font-size: 20px;
                            }

                            @media (max-width : 768px) {
                                font-size: 14px;
                                line-height: 14px;
                            }
                        }
                    }
                }

                .artist-Detail-description-text {
                    margin-top: 67px;

                    @media (max-width : 1600px) {
                        margin-top: 35px;
                    }

                    @media (max-width : 768px) {
                        margin-top: 22px;
                    }

                    .artist-Detail-description {
                        font-family: 'Matter';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 27px;
                        line-height: 43px;
                        text-align: justify;
                        color: #000000;

                        // margin-bottom: 116px;    
                        @media (max-width : 1600px) {
                            font-size: 20px;
                            line-height: 35px;
                        }

                        @media (max-width : 768px) {
                            font-size: 18px;
                            line-height: 28px;
                        }
                    }
                }


                .artist-detail-Read-more {
                    display: flex;
                    // padding-top: 68px;
                    align-items: center;

                    @media (max-width : 1199px) {
                        // padding-top: 40px;
                    }

                    p {
                        font-family: 'Matter';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 30px;
                        line-height: 36px;
                        text-align: center;
                        color: #000000;

                        @media (max-width : 1199px) {
                            font-size: 24px;
                            line-height: 24px;
                        }

                        @media (max-width : 991px) {
                            font-size: 22px;
                            line-height: 22px;
                        }

                        @media (max-width : 575px) {
                            font-size: 20px;
                            line-height: 20px;
                        }
                    }

                    img {
                        line-height: 36px;
                        display: inline-block;
                        vertical-align: middle;
                        padding-left: 18px;

                        @media (max-width : 1199px) {
                            padding-left: 15px;
                        }

                        @media (max-width : 991px) {
                            line-height: 22px;
                            padding-left: 10px;
                        }

                        @media (max-width : 575px) {
                            line-height: 20px;
                        }
                    }
                }
            }
        }
    }
}

.about-content-wrepper-tab {
    padding: 0 40px;

    @media (max-width : 1600px) {
        padding: 0;
    }
}

.artist-main-tab {
    margin-bottom: 134px;

    @media (max-width : 1600px) {
        margin-bottom: 70px;
    }

    @media (max-width :991px) {
        margin-bottom: 50px;
    }

    @media (max-width :768px) {
        margin-bottom: 40px;
    }

    .artist-tab {
        .MuiTabs-scroller.MuiTabs-fixed {
            border-bottom: 1px solid #888888;
            margin-bottom: 99px;

            @media (max-width : 1600px) {
                margin-bottom: 70px;
            }

            @media (max-width :991px) {
                margin-bottom: 50px;
            }

            @media (max-width :768px) {
                margin-bottom: 40px;
            }

            @media (max-width :575px) {
                margin-bottom: 20px;
            }
        }

        button {
            font-family: 'Matter';
            font-style: normal;
            font-weight: 400;
            font-size: 30px;
            line-height: 36px;
            color: #000000;
            text-transform: inherit;
            opacity: 1;
            min-height: auto;
            padding: 0;
            margin: 0;
            padding-bottom: 15px;
            display: inline-block;
            vertical-align: middle;
            text-align: left;
            min-width: auto;
            margin-right: 40px;

            @media (max-width : 1600px) {
                font-size: 24px;
                line-height: 28px;
                margin-right: 30px;
            }

            @media (max-width :991px) {
                font-size: 22px;
                line-height: 24px;
                margin-right: 20px;
                padding-bottom: 10px;
            }

            @media (max-width :575px) {
                font-size: 20px;
                margin-right: 15px;
            }

        }

        .MuiTab-textColorInherit.Mui-selected {
            position: relative;

            ::after {
                position: absolute;
                content: '';
                width: 100%;
                height: 3px;
                background: #000000;
                bottom: 0;
                left: 0;
                z-index: 100;
            }
        }
    }
}

.market-slider-wrapper {
    padding: 0 !important;
}

.market-slider-wrapper .market-slider {
    display: none;
}

.market-slider-wrapper .market-slider.active {
    display: block;
}

.data-not-found {
    text-align: center;
}

.nft-image-container {
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(217, 217, 217, 0.53);
    padding: 30px;
    overflow: hidden;

    @media (max-width : 1600px) {
        // height: auto;
    }

    @media(max-width:575px) {
        padding: 10px;
        // height: 62vh !important;
    }
}

.nft-image-container {

    .item-image img {
        // height: 656px;
        // width: 700px;
        // display: block;
        // width: 700px;
        // height: 700px;

        @media(max-width:1600px) {
            width: 600px;
            // height: auto;
        }

        @media(max-width:575px) {
            width: 100%;
        }
    }
}

.item-image-tag {
    img {
        @media(max-width:1279px) {
            width: 100%;
            height: 100%;
        }
    }
}

// .nft-image-container {
//     height: auto;
// }


.about-read-more #hero-button {
    font-family: 'Matter';
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 36px;
    text-align: center;
    color: #000000;
    padding: 0;
}

.user-icon {
    background-color: transparent;
}

.flex-wrap {
    flex-wrap: wrap;

    @media (max-width : 768px) {
        flex-direction: row !important;
    }
}

/***** My NFT ****/
.nft-content-wrapper .ntf-content {
    margin-bottom: 14px;
}

.nft-top-header-title {
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 60px !important;
    line-height: 72px !important;
    color: #000000 !important;
    -webkit-line-clamp: 1 !important;
    overflow: hidden !important;
    -webkit-box-orient: vertical !important;
    display: -webkit-box !important;

    @media (max-width : 1600px) {
        font-size: 44px !important;
        line-height: 54px !important;
    }

    @media (max-width : 991px) {
        font-size: 40px !important;
        line-height: 50px !important;
    }

    @media (max-width : 768px) {
        font-size: 30px !important;
        line-height: 40px !important;
    }
}

.nft-title-section-text-wrapper {
    margin-bottom: 56px !important;
    display: flex;
    align-items: center;

    @media (max-width : 1600px) {
        margin-bottom: 40px !important;
    }

    @media (max-width : 991px) {
        margin-bottom: 30px !important;
    }

    @media (max-width : 768px) {
        margin-bottom: 20px !important;
    }
}

.nft-detail-container .nft-detail .nft-title-section .title {
    font-style: normal;
    font-weight: 600;
    font-size: 60px;
    line-height: 72px;
    color: #000000;
    -webkit-line-clamp: 2 !important;
    overflow: hidden !important;
    -webkit-box-orient: vertical !important;
    display: -webkit-box !important;

    @media (max-width : 1600px) {
        font-size: 44px;
        line-height: 54px;
    }

    @media (max-width : 991px) {
        font-size: 40px;
        line-height: 50px;
    }

    @media (max-width : 768px) {
        font-size: 30px;
        line-height: 40px;
    }
}

.download-resource-btn {
    .download-btn-sm {
        display: none;
    }

    .download-btn-lg {
        margin-left: 10px;
    }

    @media (max-width: 864px) {
        .download-btn-lg {
            display: none;
        }

        .download-btn-sm {
            display: block;
        }
    }

}

.nft-detail-container .nft-detail .collection-creator .user-info .MuiAvatar-root {
    width: 53.62px;
    height: 53.62px;

    @media (max-width : 991px) {
        width: 50px;
        height: 50px;
    }

}

.nft-detail-container .nft-detail .collection-creator .user-info .title {
    font-family: 'Matter';
    font-style: normal;
    font-weight: 400;
    font-size: 32.3172px;
    line-height: 39px;
    color: #545065;

    @media (max-width : 1600px) {
        font-size: 24px;
        line-height: 28px;
    }

    @media (max-width : 991px) {
        font-size: 20px;
        line-height: 24px;
    }
}

.nft-wrapper-box {
    margin: 0 !important;
}

.nft-detail-container .nft-detail .collection-creator {
    margin: 0;
}

.nft-detail-container .nft-detail .collection-creator .user-info {
    margin: 0;
}

.nft-detail-container .nft-detail .collection-creator {
    margin: 0 0 47.55px;

    @media (max-width : 1600px) {
        margin: 0 0 36px;
    }

    @media (max-width : 991px) {
        margin: 0 0 26px;
    }

    .creatorName {
        margin: 0 !important;
    }
}

.nft-detail-container .nft-detail .nft-description .description {
    p {
        font-family: 'Matter' !important;
        font-style: normal !important;
        font-weight: 400 !important;
        font-size: 24.9969px !important;
        line-height: 30px !important;
        color: #000000 !important;
        margin: 0;

        @media (max-width : 1600px) {
            font-size: 20px !important;
            line-height: 22px !important;
        }

        @media (max-width : 991px) {
            font-size: 17px !important;
            line-height: 20px !important;
        }
    }
}

.nft-description-type {
    font-family: 'Matter' !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 22px !important;
    line-height: 30px !important;
    color: #000000 !important;
    margin: 0;

    @media (max-width : 1600px) {
        font-size: 17px !important;
        line-height: 20px !important;
    }

    @media (max-width : 991px) {
        font-size: 15px !important;
        line-height: 18px !important;
    }
}

.nft-detail-container {

    // margin-top: 7rem;
    .authenticityHeading {
        font-family: 'Matter';
        font-style: normal;
        font-weight: 400;
        font-size: 31.83px;
        line-height: 38px;
        color: #171725;
        padding-bottom: 26px;
        margin-bottom: 27px;

        @media (max-width : 1600px) {
            font-size: 26px;
            line-height: 26px;
            padding-bottom: 10px;
            margin-bottom: 20px;
        }

        @media (max-width : 991px) {
            font-size: 24px;
            line-height: 28px;
        }

        @media (max-width : 768px) {
            font-size: 22px;
            line-height: 24px;
            padding-bottom: 6px;
            margin-bottom: 10px;
        }
    }
}

.nft-type {
    font-family: 'Matter' !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 25.98px !important;
    line-height: 38px !important;
    color: #000000 !important;

    @media (max-width : 1600px) {
        font-size: 20px !important;
        line-height: 26px !important;
    }

    @media (max-width : 991px) {
        font-size: 18px !important;
        line-height: 22px !important;
    }

    @media (max-width : 768px) {
        font-size: 15px !important;
        line-height: 20px !important;
    }

    a {
        color: #000;
    }
}

.nft-detail-container {
    .history-img {
        width: 55px;
        height: 55px;

        @media (max-width : 1400px) {
            height: 45px;
            width: 45px;
        }

        @media (max-width : 1200px) {
            height: 45px;
            width: 50px;
        }

        @media (max-width : 959px) {
            height: 45px;
            width: 45px;
        }

        @media (max-width : 425px) {
            height: 40px;
            width: 45px;
        }
    }
}

.nft-content-wrapper {
    overflow-y: auto;
    max-height: 380px;
}

.nft-content-wrapper .nft-content-box-wrapper {
    margin-bottom: 14px;
}

.stock-info-not-available {
    width: 100%;
    text-align: center;
}

.stock-info-not-available .end-text {
    line-height: 39px !important;
}

.artist-detail-inner-container {
    position: relative;
    // removed this to avoid video cutting on > 1440
    // max-height: 800px;
    overflow: hidden;
    padding: 40px;
    text-align: center;
    background: rgba(217, 217, 217, 0.53);

    @media (max-width : 1199px) {
        max-height: 100%;
        padding: 20px;
    }
}

// .more-view {
//     display: none !important;
// }

.exhibition-heading {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding-bottom: 40px;

    @media (max-width : 1600px) {
        padding-bottom: 30px;
    }

    @media (max-width : 991px) {
        padding-bottom: 20px;
    }

    @media (max-width : 768px) {
        padding-bottom: 10px;
    }

    h2 {
        font-style: normal;
        font-weight: 500;
        font-size: 60px;
        line-height: 72px;
        color: #050505;
        padding-right: 28px;
        // display: inline-block;
        // -webkit-line-clamp: 1 !important;
        // overflow: hidden !important;
        // -webkit-box-orient: vertical !important;
        // display: -webkit-box !important;

        @media (max-width : 1600px) {
            font-size: 50px;
            line-height: 62px;
        }

        @media (max-width : 991px) {
            font-size: 40px;
            line-height: 52px;
        }

        @media (max-width : 768px) {
            font-size: 34px;
            line-height: 46px;
        }

        @media (max-width : 575px) {
            font-size: 24px;
            line-height: 34px;
        }
    }

    img {
        width: 33px;
        height: 33.13px;

        @media (max-width : 1600px) {
            width: 46px;
            height: 46px;
        }

        @media (max-width : 991px) {
            width: 40px;
            height: 40px;
        }

        @media (max-width : 768px) {
            width: 30px;
            height: 30px;
        }
    }
}

.exhibition-img-block-other {
    height: 279px;

    img {
        @media (max-width : 768px) {
            height: 100%;
        }
    }
}

.exhibition-img-block-active {
    height: 286px;

    img {
        @media (max-width : 768px) {
            height: 100%;
        }
    }
}

.exhibition-img-block-extra {
    height: 187px;

    img {
        @media (max-width : 768px) {
            height: 100%;
        }
    }
}

.exhibition-img-block-upcome {
    height: 297px;

    img {
        @media (max-width : 768px) {
            height: 100%;
        }
    }
}

.exhibition-detail-section {
    margin-top: 60px !important;

    @media (max-width : 1600px) {
        margin-top: 40px !important;
    }

    @media (max-width : 768px) {
        margin-top: 30px !important;
    }
}

.banner-img {
    width: 6rem !important;
    height: 6rem !important;
    margin-right: 10px;

    @media (max-width : 991px) {
        width: 5rem !important;
        height: 6rem !important;
    }

    @media (max-width : 768px) {
        width: 5rem !important;
        height: 5rem !important;
    }

    @media (max-width : 568px) {
        height: 4rem !important;
        width: 4rem !important;
    }

}

.exibition-img-name {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 55px;

    @media (max-width : 1600px) {
        padding-bottom: 40px;
    }

    @media (max-width : 991px) {
        padding-bottom: 26px;
    }

    @media (max-width : 768px) {
        padding-bottom: 20px;
    }

    @media (max-width : 668px) {
        padding-bottom: 20px;
        flex-direction: column;
    }

    .exhibition-mobile {
        .exi-banner-img {
            // width: 98.36px;
            // height: 94px;
            border-radius: 50%;
            overflow: hidden;
            margin-right: 18.64px;

            @media (max-width : 1600px) {
                // width: 80px;
                // height: 76px;
            }

            @media (max-width : 991px) {
                // width: 70px;
                // height: 66px;
                margin-right: 14px;
            }

            @media (max-width : 768px) {
                // width: 50px;
                // height: 47px;
                margin-right: 10px;
            }

            img {
                // width: 100%;
                // height: 100%;
                width: 98px;
                object-fit: cover;
                border-radius: 50%;

                @media (max-width : 991px) {
                    width: 60px;
                    height: 60px;
                }
            }
        }

        .exhibition-banner-text {
            margin-right: 30px;
            width: 230px;

            @media (max-width : 1600px) {
                width: 210px;
            }

            @media (max-width : 991px) {
                width: 180px;
            }

            @media (max-width : 768px) {
                width: 140px;
                margin-right: 10px;
            }

            @media (max-width : 575px) {
                width: 100px;
            }

            h6 {
                font-family: 'Matter';
                font-style: normal;
                font-weight: 400;
                font-size: 21px;
                line-height: 89.8%;
                color: #050505;

                @media (max-width : 1600px) {
                    font-size: 16px;
                }

                @media (max-width : 768px) {
                    font-size: 14px;
                }

                @media (max-width : 425px) {
                    font-size: 13px;
                }
            }

            h5 {
                font-family: 'Matter';
                font-style: normal;
                font-weight: 400;
                font-size: 24px;
                line-height: 30px;
                color: #050505;
                padding-top: 6px;
                -webkit-line-clamp: 2 !important;
                overflow: hidden !important;
                -webkit-box-orient: vertical !important;
                display: -webkit-box !important;

                @media (max-width : 1600px) {
                    font-size: 20px;
                    line-height: 26px;
                }

                @media (max-width : 991px) {
                    font-size: 18px;
                    line-height: 24px;
                }

                @media (max-width : 425px) {
                    font-size: 14px;
                    line-height: 20px;
                }
            }
        }

        .exibition-globe-icon {
            display: flex;
            justify-content: center;
            align-items: center;

            img {
                width: 48.65px;
                height: 48.84px;

                @media (max-width : 1600px) {
                    width: 40px;
                    height: 40px;
                }

                @media (max-width : 768px) {
                    width: 30px;
                    height: 30px;
                }
            }

            h6 {
                font-family: 'Matter';
                font-style: normal;
                font-weight: 400;
                font-size: 25.8264px;
                line-height: 31px;
                display: flex;
                align-items: center;
                color: #0E0E0E;
                -webkit-line-clamp: 2 !important;
                overflow: hidden !important;
                -webkit-box-orient: vertical !important;
                display: -webkit-box !important;

                @media (max-width : 1600px) {
                    font-size: 20px;
                    line-height: 24px;
                }

                @media (max-width : 575px) {
                    font-size: 16px;
                    line-height: 20px;
                }

                @media (max-width : 425px) {
                    font-size: 14px;
                    line-height: 18px;
                }
            }
        }
    }
}

.exibition-middle-text {
    padding-bottom: 109px;

    @media (max-width : 1600px) {
        padding-bottom: 80px;
    }

    @media (max-width : 991px) {
        padding-bottom: 60px;
    }

    @media (max-width : 768px) {
        padding-bottom: 30px;
    }

    p {
        font-family: 'Matter';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 24px;
        text-align: justify;
        color: #000000;

        @media (max-width : 1199px) {
            font-size: 16px;
            line-height: 22px;
        }

        @media (max-width : 575px) {
            font-size: 14px;
            line-height: 20px;
        }
    }
}

.exibition-explore-component {
    margin-bottom: 133.19px;

    @media (max-width : 1600px) {
        margin-bottom: 80px;
    }

    @media (max-width : 991px) {
        margin-bottom: 50px;
    }

    @media (max-width : 768px) {
        margin-bottom: 30px;
    }

    .exibition-explore {
        border-top: 1px solid #000;
        border-bottom: 1px solid #000;
        padding: 16px 0 16px;
        margin-bottom: 86px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media (max-width : 1600px) {
            padding: 10px 0 10px;
            margin-bottom: 60px;
        }

        @media (max-width : 991px) {
            padding: 6px;
            margin-bottom: 50px;
        }

        @media (max-width : 768px) {
            margin-bottom: 30px;
        }

        h3 {
            font-style: normal;
            font-weight: 600;
            font-size: 60px;
            line-height: 72px;
            color: #000000;
            -webkit-line-clamp: 1 !important;
            overflow: hidden !important;
            -webkit-box-orient: vertical !important;
            display: -webkit-box !important;


            @media (max-width : 1600px) {
                font-size: 50px;
                line-height: 62px;
            }

            @media (max-width : 991px) {
                font-size: 40px;
                line-height: 52px;
            }

            @media (max-width : 768px) {
                font-size: 34px;
                line-height: 44px;
            }

            @media (max-width : 575px) {
                font-size: 26px;
                line-height: 34px;
            }
        }
    }
}

.exibition-artists-component {
    margin-bottom: 145px;

    @media (max-width : 1600px) {
        margin-bottom: 80px;
    }

    @media (max-width : 991px) {
        margin-bottom: 50px;
    }

    @media (max-width : 768px) {
        margin-bottom: 30px;
    }

    .exibition-artists {
        border-top: 1px solid #000;
        border-bottom: 1px solid #000;
        padding: 16px 0 16px;
        margin-bottom: 116.87px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media (max-width : 1600px) {
            padding: 10px 0 10px;
            margin-bottom: 60px;
        }

        @media (max-width : 991px) {
            padding: 6px;
            margin-bottom: 50px;
        }

        @media (max-width : 768px) {
            margin-bottom: 30px;
        }

        h3 {
            font-style: normal;
            font-weight: 500;
            font-size: 60px;
            line-height: 72px;
            text-align: right;
            color: #000000;

            @media (max-width : 1600px) {
                font-size: 50px;
                line-height: 62px;
            }

            @media (max-width : 991px) {
                font-size: 40px;
                line-height: 52px;
            }

            @media (max-width : 768px) {
                font-size: 34px;
                line-height: 44px;
            }

            @media (max-width : 575px) {
                font-size: 26px;
                line-height: 34px;
            }
        }
    }

    .exibition-artists-tag {
        display: flex;
        flex-wrap: wrap;
        // justify-content: space-between;
        // align-items: center;
        // padding: 0 20px;

        // @media (max-width : 575px) {
        //     padding: 0 14px;
        // }

        .exibition-artists-bg {
            text-align: center;
            // width: 230px;
            padding-bottom: 20px;

            @media (max-width :1600px) {
                // width: 200px;
            }

            @media (max-width : 991px) {
                // width: 160px;
            }

            @media (max-width : 575px) {
                // width: 130px;
            }

            .exibition-artists-tag-box {
                // width: 121px;
                // height: 121px;
                border-radius: 50%;
                overflow: hidden;
                margin: 0 auto;
                margin-bottom: 15.46px;

                @media (max-width : 1600px) {
                    // width: 80px;
                    // height: 80px;
                }

                @media (max-width : 991px) {
                    // width: 60px;
                    // height: 60px;
                }

                img {
                    width: 164px;
                    height: 164px;
                    object-fit: cover;
                    border-radius: 50%;

                    @media (max-width : 599px) {
                        width: 80px;
                        height: 80px;
                    }
                }
            }

            p {
                font-style: normal;
                font-weight: 400;
                font-size: 24px;
                line-height: 29px;
                color: #202020;
                width: 80%;
                margin: 0 auto;
                -webkit-line-clamp: 2 !important;
                overflow: hidden !important;
                -webkit-box-orient: vertical !important;
                display: -webkit-box !important;
                min-height: 23px;

                @media (max-width : 1600px) {
                    font-size: 20px;
                    line-height: 25px;
                }

                @media (max-width : 768px) {
                    font-size: 16px;
                    line-height: 22px;
                }
            }
        }
    }
}

.exibition-other-component {
    margin-bottom: 132.42px;

    @media (max-width : 1600px) {
        margin-bottom: 90px;
    }

    @media (max-width : 991px) {
        margin-bottom: 60px;
    }

    @media (max-width : 575px) {
        margin-bottom: 20px;
    }

    .nft-slider {
        .upcoming-pr {
            margin-bottom: 0 !important;
            padding: 0 12.66px !important;
        }

        .slick-list {
            padding: 0 !important;
        }

        .slick-dots {
            display: none !important;
        }
    }

    .exibition-other {
        border-top: 1px solid #000;
        border-bottom: 1px solid #000;
        padding: 8px 0 12px;
        margin-bottom: 102px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media (max-width : 1600px) {
            padding: 10px 0 10px;
            margin-bottom: 60px;
        }

        @media (max-width : 991px) {
            padding: 6px;
            margin-bottom: 50px;
        }

        @media (max-width : 768px) {
            margin-bottom: 30px;
        }

        h3 {
            font-style: normal;
            font-weight: 600;
            font-size: 60px;
            line-height: 72px;
            text-align: right;
            color: #000000;

            @media (max-width : 1600px) {
                font-size: 50px;
                line-height: 62px;
            }

            @media (max-width : 991px) {
                font-size: 40px;
                line-height: 52px;
            }

            @media (max-width : 768px) {
                font-size: 34px;
                line-height: 44px;
            }

            @media (max-width : 575px) {
                font-size: 26px;
                line-height: 34px;
            }
        }
    }
}

.exhibition-time-other {
    width: 70%;

    @media (max-width : 1199px) {
        width: 65%;
    }

    @media (max-width : 991px) {
        width: auto;
        padding-top: 10px;
    }

    .exhibition-time-content {
        p {
            font-family: 'Matter';
            font-style: normal;
            font-weight: 400;
            font-size: 13.4724px;
            line-height: 17px;
            display: flex;
            align-items: center;
            text-align: justify;
            color: #000000;
            min-height: 54px;
        }
    }
}

.other-upcoming-pr {
    .exhibition:hover .exhibition-button a#hero-button {
        border-bottom: 1px solid #000000;
    }

    .exhibition {
        .exhibition-content {
            h1 {
                font-weight: 600;
                font-size: 41.3614px;
                line-height: 40px;

                @media (max-width : 1600px) {
                    font-size: 36px;
                }

                @media (max-width : 991px) {
                    font-size: 30px;
                }

                @media (max-width : 768px) {
                    font-size: 26px;
                }

                @media (max-width : 575px) {
                    font-size: 20px;
                }
            }

            .exhibition-button {
                a {
                    span {
                        font-size: 22.1332px !important;
                        line-height: 27px !important;

                        @media (max-width : 1600px) {
                            font-size: 18px !important;
                        }

                        @media (max-width : 991px) {
                            font-size: 14px !important;
                        }
                    }
                }
            }
        }
    }
}

.banner-img-exi-tab {
    img {
        width: 40px;
    }
}

.exhibitionpost-wrapper {
    .market-slider {
        display: flex;
        align-items: center;

        .main-my-grid-wrapper {
            padding: 0 18px !important;
            margin-bottom: 31.95px !important;

            @media (max-width : 1600px) {
                padding: 0 10px !important;
            }

            .item-image {
                img {
                    @media (max-width : 1279px) {
                        width: 100%;
                    }
                }
            }
        }
    }
}

.auction-detail-available {
    .stock-info-not-available .end-text {
        line-height: 50px !important;
    }
}

.market-slider-wrapper-other {
    display: flex;
    // justify-content: center;
    align-items: center;
}

.nft-item .item-detail .user-detail .name-section .user-role {
    @media (max-width : 1600px) {
        font-size: 16px !important;
    }

    @media (max-width : 991px) {
        font-size: 14px !important;
    }
}

.nft-item .item-detail .item-name {
    @media (max-width : 1600px) {
        font-size: 22px !important;
    }

    @media (max-width : 991px) {
        font-size: 20px !important;
    }
}

// .nft-item-video {
//     width: 100% !important;
//     max-height: 800px !important;
//     overflow: hidden;
//     position: relative;

//     @media (max-width : 1199px) {
//         max-height: 100%;
//         padding: 20px;
//     }

//     // @media (max-width : 1600px) {
//     //     max-height: 580px !important;
//     // }
//     // @media (max-width : 991px) {
//     //     max-height: 500px !important;
//     // }
//     // @media (max-width : 768px) {
//     //     max-height: 400px !important;
//     // }
//     // @media (max-width : 575px) {
//     //     // max-height: 350px !important;
//     // }
//     // @media (max-width : 425px) {
//     //     max-height: 300px !important;
//     // }
// }

.exhibition-mobile-wrapper {
    display: flex !important;
    align-items: center;
}

.filter-margin {
    font-family: 'Matter' !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 23px !important;
    line-height: 28px !important;
    color: #212529 !important;

    @media (max-width : 991px) {
        font-size: 18px !important;
        line-height: 18px !important;
    }

    @media (max-width : 575px) {
        font-size: 16px !important;
        line-height: 16px !important;
    }
}

.class {
    display: inline-block;
    // padding: 14.5px 20px;
    background: #FFFFFF;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.12);
    margin-right: 13px !important;
    margin-bottom: 13px !important;
    font-family: 'Matter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #212529;
    border: 1px solid transparent;
    transition: all 0.5s;
    cursor: pointer;

    @media (max-width :575px) {
        padding: 8px 14px;
        font-size: 12px;
        line-height: 14px;
    }

    &:hover {
        border: 1px solid #000;
    }
}

.search-input {
    text-align: center;
    border: 0.5px solid #000 !important;
    width: 314px;
    margin: 10px 0 69px;

    @media (max-width : 1400px) {
        width: 200px;
        margin: 10px 0 30px;
    }

    .search-field {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 22px 21px;

        @media (max-width : 1400px) {
            padding: 10px 21px;
        }
    }

    svg {
        color: #000;
    }

    fieldset {
        border: none !important;
    }

    .search-box-other {
        input {
            font-family: 'Matter';
            font-style: normal;
            font-weight: 400;
            font-size: 23px;
            line-height: 28px;
            color: #000000 !important;
            padding: 0;
            opacity: 1 !important;

            @media (max-width : 1400px) {
                font-size: 18px;
            }

            &::placeholder {
                color: #000 !important;
                opacity: 1 !important;
            }
        }
    }
}

.price-wrapper {
    padding: 27px 0 63px;
    border-top: 0.8px solid #DEE2E6;
    border-bottom: 0.8px solid #DEE2E6;

    @media (max-width : 1600px) {
        padding: 22px 0 22px;
    }

    @media (max-width : 575px) {
        padding: 12px 0 12px;
    }

    .price-slider {
        width: 100% !important;

        @media (max-width : 959px) {
            width: 40% !important;
        }

        @media (max-width : 575px) {
            width: 70% !important;
        }

        @media (max-width : 499px) {
            width: 90% !important;
        }

        // @media (max-width : 1400px) {
        //     width: 180px !important;
        // }
    }
}

.price-box {
    .filter-margin {
        margin-bottom: 11px;

        @media (max-width : 575px) {
            margin-bottom: 10px;
        }
    }
}

// .filter-container {
//     padding: 60px 20px 0 0;

//     @media (max-width : 959px) {
//         padding: 30px 0 0 0;
//     }

//     @media (max-width : 575px) {
//         padding: 10px 0 0 0;
//     }
// }

.nft-panel {
    .nft-list {
        padding: 0;
        // padding-top: 10px !important;

    }
}

.filter-margin {
    margin-left: 0 !important;
}

.price-slider-design {
    color: #000 !important;
    padding: 0 !important;

    .MuiSlider-rail {
        height: 1px !important;
    }

    .MuiSlider-thumb {
        background-color: white !important;
        border: 1px solid #000 !important;
    }
}

.status-wrapper {
    padding: 27px 0 28px;
    border-bottom: 0.8px solid #DEE2E6;

    @media (max-width : 1600px) {
        padding: 22px 0 22px;
    }

    @media (max-width : 575px) {
        padding: 12px 0 12px;
    }

    p {
        margin-bottom: 11px;

        @media (max-width : 575px) {
            margin-bottom: 10px;
        }
    }
}

.single-wrapper {
    padding: 27px 0 28px;
    border-bottom: 0.8px solid #DEE2E6;

    @media (max-width : 1600px) {
        padding: 22px 0 22px;
    }

    @media (max-width : 575px) {
        padding: 12px 0 12px;
    }
}

.type-wrapper {
    padding: 27px 0 28px;
    border-bottom: 0.8px solid #DEE2E6;

    @media (max-width : 1600px) {
        padding: 22px 0 22px;
    }

    @media (max-width : 575px) {
        padding: 12px 0 12px;
    }

    .filter-margin {
        margin-bottom: 11px;

        @media (max-width : 575px) {
            margin-bottom: 10px;
        }
    }
}

.classes {
    display: inline-block;
    padding: 14px 20px;
    background: #FFFFFF;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.12);
    margin-right: 13px !important;
    margin-bottom: 13px !important;
    border: 1px solid transparent;
    transition: all 0.5s;
    cursor: pointer;

    @media (max-width :575px) {
        padding: 10px 18px;
    }

    &:hover {
        border: 1px solid #000;
    }
}

.single,
.single-edition {
    font-family: 'Matter' !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 23px !important;
    line-height: 28px !important;
    color: #212529;

    @media (max-width : 1600px) {
        font-size: 18px !important;
        line-height: 18px !important;
    }
}

.mobile-apply-btn {
    display: none !important;

    @media (max-width : 575px) {
        display: block !important;
    }
}

// .region-wrapper-py{
//     // padding-top: 0 !important;
// }
.status-wrapper-py {
    padding-top: 0 !important;
}

.type-wrapper-py {
    padding-top: 0 !important;
}

.region-wrapper {
    padding: 27px 0 41px;
    border-bottom: 0.8px solid #DEE2E6;

    @media (max-width : 1600px) {
        padding: 22px 0 22px;
    }

    @media (max-width : 575px) {
        padding: 12px 0 12px;
    }

    .filter-margin {
        margin-bottom: 11px;

        @media (max-width : 575px) {
            margin-bottom: 10px;
        }
    }
}

.blockchain-wrapper {
    padding: 27px 0 28px;
    border-bottom: 0.8px solid #DEE2E6;

    @media (max-width : 1600px) {
        padding: 22px 0 22px;
    }

    @media (max-width : 575px) {
        padding: 12px 0 12px;
    }

    .filter-margin {
        margin-bottom: 11px;

        @media (max-width : 575px) {
            margin-bottom: 10px;
        }
    }
}

.region-form-select-input {
    margin-bottom: 26px;

    .region-form-inner {
        border: 1px solid #000;
        // select{
        width: 100% !important;


        // }
        &::after {

            // @media (max-width : 1400px) {
            //     right: 65px !important;
            // }
            // pointer-events: none !important;
            @media (max-width : 959px) {
                right: 15px !important;
            }

        }

        select {
            border: none !important;
        }

        .region-form-control {
            option {
                font-size: 17px;

                // width: 100% !important;
                @media (max-width : 1400px) {
                    font-size: 12px;
                }
            }
        }
    }

}

.region-form-control {
    option {
        font-size: 12px;
        // width: 100% !important;       
    }
}

.region-form {
    .region-form-inner {
        display: inline-block;
        vertical-align: middle;
        position: relative;
        width: 100%;

        &::after {
            position: absolute;
            content: "";
            width: 17px;
            height: 10px;
            // background-color: #000;
            top: 50%;
            right: 13px;
            transform: translate(0, -50%);
            background-image: url(../../assets/gradients/select-icon.svg);
            background-repeat: no-repeat;
            pointer-events: none;

            @media (max-width : 959px) {
                right: 62%;
            }

            @media (max-width : 575px) {
                right: 32%;
            }

            @media (max-width : 499px) {
                right: 12%;
            }
        }
    }

    select {
        -webkit-appearance: none;
        width: 100% !important;
        border: 1px solid #000 !important;
        padding: 17px 32px 17px 16px !important;

        @media (max-width : 1600px) {
            padding: 12px 32px 12px 12px !important;
        }

        @media (max-width : 959px) {
            width: 40% !important;
        }

        @media (max-width : 575px) {
            width: 70% !important;
            padding: 8px 30px 8px 8px !important;
        }

        @media (max-width : 499px) {
            width: 90% !important;
        }
    }
}

.region-form-select-input {
    .region-form-inner {
        select {
            @media (max-width : 959px) {
                width: 100% !important;
            }

            @media (max-width : 575px) {
                width: 100% !important;
            }

            @media (max-width : 499px) {
                width: 100% !important;
            }
        }
    }
}

.region-form-control {
    width: 100%;
    border: none !important;
    font-family: 'Matter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #343A40;

    &:focus-visible {
        outline: none;
    }
}

.exhibition-wrapper {
    padding: 27px 0 41px;
    border-bottom: 0.8px solid #DEE2E6;

    @media (max-width : 1600px) {
        padding: 22px 0 22px;
    }

    .filter-margin {
        margin-bottom: 11px;

        @media (max-width : 575px) {
            margin-bottom: 10px;
        }
    }
}

.utility-wrapper {
    padding: 27px 0 41px;
    border-bottom: 0.8px solid #DEE2E6;

    @media (max-width : 1600px) {
        padding: 22px 0 22px;
    }

    .filter-margin {
        margin-bottom: 11px;

        @media (max-width : 575px) {
            margin-bottom: 10px;
        }
    }
}

.btn-none {
    display: none !important;
}

.utility {
    display: flex;
}

.utility-box {
    width: 142px;
    height: 47px;
    background: rgba(255, 255, 255, 0.62);
    border: 1px solid #000000;
    margin-right: 18px;
}

.year-wrapper {
    padding: 27px 0 41px;

    @media (max-width : 1600px) {
        padding: 22px 0 30px;
    }

    .filter-margin {
        margin-bottom: 11px;

        @media (max-width : 575px) {
            margin-bottom: 10px;
        }
    }

}

.year-form {
    -webkit-appearance: none;

    .year-form-inner {
        display: inline-block;
        vertical-align: middle;
        position: relative;

        &::after {
            position: absolute;
            content: "";
            width: 17px;
            height: 10px;
            // background-color: #000;
            top: 50%;
            right: 13px;
            transform: translate(0, -50%);
            background-image: url(../../assets/gradients/select-icon.svg);
            background-repeat: no-repeat;
            pointer-events: none;
        }
    }

    select {
        -webkit-appearance: none;
        width: 139px !important;
        border: 1px solid #000 !important;
        padding: 17px 16px !important;

        @media (max-width : 1600px) {
            padding: 12px !important;
        }
    }
}

.active-filter {
    border: 1px solid #000;
}

.buy-now {
    display: inline-block;

    label {
        margin: 0 !important;
        padding: 14.5px 20px;
        // & :active{
        //     border: 1px solid #000;
        // }

        span {
            &:first-child {
                display: none !important;
            }
        }
    }
}

.buy-now.class.status-selected {
    border: 1px solid #000;
}

.selected-filters {
    .selected-filters-icon {
        margin-bottom: 8px;
        color: #000 !important;
        border: 1px solid #000 !important;
        margin-right: 8px;

        @media (max-width : 575px) {
            padding: 7px 3px !important;
        }

        svg {
            color: #000 !important;
        }

        &>span {
            @media (max-width : 575px) {
                font-size: 10px !important;
                padding: 0 10px 0 0 !important;
            }
        }

        span.MuiChip-label:first-letter {
            text-transform: capitalize;
        }
    }

}

.selected-filters-clear {
    color: #000 !important;
    border-radius: 4rem !important;
    height: 2.4rem;
}

.blockchain-filter {
    display: inline-block;

    label {
        margin: 0 !important;

        span {
            &:first-child {
                display: none !important;
            }
        }
    }
}

.hide-close-btn-if-not-mobile {
    width: 100%;
    display: none !important;

    div {
        width: 100%;
        display: flex;
        justify-content: flex-end;
    }

    @media (max-width : 575px) {
        display: block !important;
    }
}

.celebrity-search {
    // padding-bottom: 22px;
    // @media (max-width : 1600px) {
    //     padding-bottom: 20px;
    // }

    // @media (max-width : 959px) {
    //     padding-bottom: 12px;
    // }
    .celebrity-search-box {
        // width: 314px !important;

        div {

            // padding: 22px 21px !important;
            input {
                font-family: 'Matter';
                font-style: normal;
                font-weight: 400;
                font-size: 23px;
                line-height: 28px;
                color: #000000 !important;
                padding: 0;
                opacity: 1 !important;
                padding: 0 !important;

                @media (max-width : 1400px) {
                    font-size: 18px;
                }

                @media (max-width : 575px) {
                    font-size: 14px;
                }

                &::placeholder {
                    color: #000 !important;
                    opacity: 1 !important;
                }
            }

            button {
                padding: 0 !important;

                svg {
                    color: #000;
                }
            }
        }

        fieldset {
            // border: 0.5px solid #000000 !important;
            border: none !important;
            border-radius: 0 !important;
        }
    }
}

.text-field-search-box {
    display: flex;
    align-items: center;
    padding: 13px 21px !important;
    width: 100% !important;
    border: 0.5px solid #000000 !important;

    @media (max-width : 1600px) {
        // width: 200px !important;
        padding: 10px !important;
    }

    @media (max-width : 959px) {
        width: 40% !important;
    }

    @media (max-width : 575px) {
        width: 70% !important;
        padding: 8px 8px !important;
    }

    @media (max-width : 499px) {
        width: 90% !important;
    }
}

.nft-market {
    padding: 0 10px 38.42px !important;

    @media (max-width : 1600px) {
        padding: 0 !important;
        padding: 0 8px 30px 8px !important;
    }

    @media (max-width : 599px) {
        padding: 0 !important;
        padding-bottom: 16px !important;
    }
}

.market-place-inner-wrapper {
    margin-top: 60px !important;
}

.MuiSlider-thumb.Mui-focusVisible,
.MuiSlider-thumb:hover {
    box-shadow: none !important;
}

.filter-margin-icon {
    svg {
        color: #000 !important;
    }
}

.mobile-filter-wrapper {
    @media (max-width : 575px) {
        padding-left: 10px;
        padding-right: 10px;
    }

    .apply-btn {
        color: #000 !important;
        border: 1px solid #000 !important;

        @media (max-width : 575px) {
            padding: 10px !important;
            width: 70% !important;
            //    margin: 0 auto !important;
        }

        @media (max-width : 499px) {
            width: 90% !important;
        }
    }
}

.circularProgress-icon {
    color: #000 !important;
}

.marketplace-tablist {
    button {
        @media (max-width : 575px) {
            padding: 5px 0;
        }

        span {
            font-family: 'Matter';
            font-style: normal;
            font-weight: 400;
            font-size: 30px;
            line-height: 36px;
            display: flex;
            align-items: center;
            text-transform: capitalize;

            // color: #97999B;
            @media (max-width : 1600px) {
                font-size: 24px;
                line-height: 24px;
            }

            @media (max-width : 991px) {
                font-size: 20px;
                line-height: 20px;
            }

            @media (max-width : 768px) {
                font-size: 17px;
                line-height: 17px;
            }

            @media (max-width : 575px) {
                font-size: 15px;
                line-height: 15px;
            }
        }
    }

    .MuiTabs-flexContainer {
        justify-content: space-between;
        padding-left: 370px;

        @media (max-width : 1600px) {
            padding-left: 340px;
        }

        @media (max-width : 991px) {
            padding: 0;
        }

    }
}

.tab-value-box {
    @media (min-width : 600px) {
        min-width: 140px !important;
    }
}

.exhibition-tab-main {
    border-top: 1px solid #000;
    border-bottom: 1px solid #000 !important;
    margin-bottom: 60px;
    margin-top: 133px;

    @media (max-width : 1600px) {
        margin-bottom: 50px;
        margin-top: 60px;
    }

    @media (max-width : 991px) {
        margin-bottom: 30px;
        margin-top: 30px;
        margin-top: 10px;
    }

    @media (max-width : 768px) {
        margin-bottom: 20px;
    }
}

.nft-detail-container .nft-detail .nft-description .description {
    -webkit-line-clamp: 3 !important;
    overflow: hidden !important;
    -webkit-box-orient: vertical !important;
    display: -webkit-box !important;
}

.artist-grid-flex {
    display: flex;
    flex-wrap: wrap;
}

.exhibition-tab-main-wrapper {
    .exibition-artists-component {
        margin-bottom: 0 !important;

        .exibition-artists-tag {
            padding: 0 !important;

            a {
                .exibition-artists-bg {
                    padding-right: 0;
                    padding-bottom: 84px;

                    @media (max-width : 1600px) {
                        // padding-right: 70px;
                        padding-bottom: 60px;
                    }

                    @media (max-width : 991px) {
                        // padding-right: 40px;
                        padding-bottom: 40px;
                    }
                }
            }
        }
    }
}

.filter-panel {
    padding-right: 30px;

    @media (max-width :1600px) {
        padding-right: 20px;
    }

    @media (max-width :959px) {
        padding-right: 0;
        padding-bottom: 20px;
    }
}

.tab-panel-artist {
    padding: 0 !important;
    padding-bottom: 20px !important;
}

.curator-tab-main-wrapper {
    .exibition-artists-component {
        margin-bottom: 0 !important;

        .exibition-artists-tag {
            .exibition-artists-bg {
                padding-bottom: 84px;

                @media (max-width : 1600px) {
                    padding-bottom: 60px;
                }

                @media (max-width : 991px) {
                    padding-bottom: 40px;
                }
            }
        }
    }
}

.exhibition-tab-link {
    padding: 0 16px 31px 16px !important;

    // padding-top: 0 !important;
    @media (max-width :599px) {
        padding: 0 0 30px 0 !important;
    }
}

.activeExhibitionTab-description {
    width: 65% !important;

    @media (max-width :1250px) {
        width: auto !important;
        padding-top: 10px;
    }
}

.exhibition-tab {
    .exhibition-tab-wrapper {
        padding: 16px;

        @media (max-width :1600px) {
            padding: 10px 14px;
        }

        .exhibition-tab-text {
            h1 {
                font-style: normal;
                font-weight: 400;
                font-size: 29.6586px !important;
                line-height: 29px !important;
                color: #000000;
                margin-bottom: 10px;
                text-align: start;
                height: 62px;
                -webkit-line-clamp: 2 !important;
                overflow: hidden !important;
                -webkit-box-orient: vertical !important;
                display: -webkit-box !important;

                // padding-right: 11.72px;
                @media (max-width :1600px) {
                    font-size: 26px !important;
                }

                @media (max-width :991px) {
                    font-size: 20px !important;
                    line-height: 22px !important;
                    height: 48px;
                }
            }
        }

        .exhibition-description {
            display: flex;
            align-items: center;
            justify-content: space-between;

            // @media (max-width :991px) {
            //     flex-direction: column;
            // }
            @media (max-width :1250px) {
                flex-direction: column;
                justify-content: flex-start;
            }

            .collage-mobile {
                display: flex;
                align-items: center;
                width: 35%;

                @media (max-width :1250px) {
                    width: 100%;
                }

                .banner-text {
                    width: 50%;

                    @media (max-width :1200px) {
                        width: 75%;
                    }

                    h6 {
                        font-family: 'Matter';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 13.0375px;
                        line-height: 16px;
                        display: flex;
                        align-items: center;
                        color: #777777;
                    }

                    h5 {
                        font-family: 'Matter';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 20.0577px;
                        line-height: 24px;
                        display: flex;
                        align-items: center;
                        color: #0E0E0E;
                        -webkit-line-clamp: 1 !important;
                        overflow: hidden !important;
                        -webkit-box-orient: vertical !important;
                        display: -webkit-box !important;

                        @media (max-width :991px) {
                            font-size: 16px;
                        }
                    }
                }
            }

            p {
                font-family: 'Matter';
                font-style: normal;
                font-weight: 400;
                font-size: 19.0548px;
                line-height: 21px;
                color: #000000;
                width: 100%;
                text-align: justify;
                padding-left: 10px;
                -webkit-line-clamp: 3 !important;
                overflow: hidden !important;
                -webkit-box-orient: vertical !important;
                display: -webkit-box !important;
                min-height: 64px;

                @media (max-width :1600px) {
                    font-size: 16px !important;
                    line-height: 16px;
                    min-height: 50px;
                }

                @media (max-width :991px) {
                    font-size: 14px !important;
                    line-height: 14px;
                    min-height: 40px;
                }

            }
        }

        .exhibition-button {
            display: flex;
            justify-content: space-between;
            margin-top: 15px;

            .exhibition-icon {
                width: 50%;

                @media (max-width : 425px) {
                    width: 45%;
                }

                img {
                    width: 28px;
                    height: 28px;
                    margin-right: 6px;
                }

                h6 {
                    font-family: 'Matter';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 17px;
                    display: flex;
                    color: #0E0E0E;
                    -webkit-line-clamp: 1 !important;
                    overflow: hidden !important;
                    -webkit-box-orient: vertical !important;
                    display: -webkit-box !important;
                }
            }

            .exhibition-text-line {
                h6 {
                    font-family: 'Matter';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 17.049px;
                    line-height: 20px;
                    color: #000000;

                    @media (max-width : 991px) {
                        font-size: 14px;
                    }

                }
            }
        }
    }
}

.import-nft-blockchain {
    .select-box {
        margin-bottom: 30px;
    }
}

.import-nft-btn {
    position: absolute !important;
    top: -60px;
    right: 20px;

    @media (max-width : 768px) {
        top: -75px;
        font-size: 12px !important;
        padding: 6px !important;
    }
}

.banner-img-active-icon {
    img {
        width: 53px;
    }
}

.Exi-tab {
    display: flex;
    flex-wrap: wrap;
}

.sub-cards {
    display: flex;
    justify-content: space-between;
}

.wallet-btn-profile {
    margin-top: 0 !important;
}

.email-profile-wrapper {
    padding: 20px 0 0 70px !important;

    @media (max-width : 1600px) {
        padding: 40px 0 0 40px !important;
    }

    @media (max-width : 991px) {
        padding: 30px 0 0 30px !important;
    }

    @media (max-width : 768px) {
        padding: 20px 0 0 0 !important;
    }
}

.marketplace-item {
    img {
        @media (max-width : 1199px) {
            width: 100%;
            height: 100%;
        }

    }
}

.name-section {
    img {
        width: 50px !important;
    }
}

.name-section-com {
    width: 50px !important;
    height: 50px !important;
    margin-right: 10px !important;

    .user-avatar-com {
        width: 100% !important;
        height: 100% !important;
    }
}

.read-more span.MuiButton-label {
    font-family: 'Matter';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 36px;
    text-align: center;
    color: #545065;
    padding: 0;

    @media (max-width : 1600px) {
        font-size: 14px;
        line-height: 14px;
    }

    @media (max-width : 768px) {
        font-size: 12px;
        line-height: 12px;
    }
}

// payment-box css
.payment-method-box {
    padding-top: 14px;

    .payment-text {
        font-style: normal;
        font-weight: 500;
        font-size: 23.1468px;
        line-height: 98.9%;
        text-align: left;
        color: #000000;
        padding-bottom: 19px;
    }

    .payment-wrapper {
        margin: 0;
        padding: 50px 0;
        border-top: 0.79974px solid #D7D7D7;

        .crypto-box {
            display: flex;
            padding: 10px 15px;
            box-shadow: 0px 0px 15.4372px -0.765481px rgba(153, 127, 255, 0.24), inset 0px 0px 4.4653px rgba(0, 0, 0, 0.09);
            border-radius: 18.244px;
            margin-bottom: 10.21px;

            &>img {
                padding-right: 12.24px;
            }

            .credit-img {
                padding-right: 22.33px;
            }

            .crypto-box-text {
                display: flex;
                justify-content: space-between;
                width: 100%;
                align-items: center;
                border-left: 0.12758px solid #000000;
                padding-left: 12px;

                & :hover {
                    background-color: #fff !important;
                }

                button {
                    font-family: 'Matter';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 15.0663px;
                    line-height: 98.9%;
                    color: #595959;

                }

                img {
                    width: 7px;
                    height: 14px;
                }
            }
        }
    }
}

.text-border {
    width: 0.301511px;
    height: 15px;
    background-color: #000;
}

.checkout-text {
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 17px !important;
    line-height: 98.9% !important;
    text-align: center;
    color: #000000 !important;
}

.purchase-text {
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 23.1468px !important;
    line-height: 98.9% !important;
    color: #000000 !important;
}

.mode-text {
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 23.1468px !important;
    line-height: 98.9% !important;
    color: #000000 !important;
}

.itemDetail-text {
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 17px !important;
    line-height: 98.9% !important;
    color: #000000 !important;

}

.subtotal-box {
    .subtotal-text {
        padding-bottom: 8px !important;

        p {
            font-family: 'Matter' !important;
            font-style: normal !important;
            font-weight: 500 !important;
            font-size: 12.1024px !important;
            line-height: 98.9% !important;
            color: #000000 !important;
        }

    }

    .total-text {
        margin-top: 0 !important;

        h6,
        p {
            font-family: 'Matter' !important;
            font-style: normal !important;
            font-weight: 700 !important;
            font-size: 16.1px !important;
            line-height: 98.9% !important;
            color: #000000 !important;
        }
    }
}

.popup-check {
    background-color: #FBFBFB !important;
}

.react-player {
    display: flex;
    justify-content: center !important;
    align-items: center !important;

    video {
        outline: none;
        width: 100% !important;
    }
}

.video-loader-skeleton {
    // added this to have a video loader
    @media (max-width: 768px) {
        height: 30vh;
    }
    
    height:40vh;
}

.main-video-Wrapper {
    width: 100%;
    display: flex;
    justify-content: center !important;
}

video:focus {
    outline: none;
}

.radio-checkout-btn {
    color: #000 !important;
}

.btn-continue {
    background-color: #000 !important;
    color: #fff !important;
}

.checkout-title-section {
    button {
        &>span {
            svg {
                color: #000 !important;
            }
        }
    }
}

.circular-load {
    color: #000 !important;
}

.MuiTab-textColorInherit.Mui-selected .MuiTab-wrapper {
    font-weight: 600 !important;
}

.home-banner-wrapper-py .slick-list {
    padding: 0 !important;

    .banner-wrapper {
        align-items: flex-start !important;
    }
}

.read-more {
    border-radius: 10px;
    // padding: 10px 0 0 0 !important;
    // display: block !important;
}

.readmore-global {
    border-radius: 10px;
    background-color: transparent !important;
}

.readmore-global:hover {
    background-color: #000 !important;
}

.payment-nft-btn,
.btn-profile-cancle {
    color: #000 !important;
}

.profile-username-btn {
    border-radius: 0 !important;

    @media (max-width : 1600px) {
        padding: 8px !important;
    }
}

.account-cancel-btn {
    color: #000 !important;
    font-family: 'Matter' !important;
}

.withdraw-popup-wrapper {
    padding: 16px 24px 0 !important;
}

.withdraw-popup-head {
    font-family: 'Matter' !important;
    font-size: 16px !important;
}

.view-popup-btn {
    background-color: #000 !important;
    color: #fff !important;
    float: right;
    padding: 12px 15px !important;
    margin-top: 10px !important;

    @media (max-width : 768px) {
        padding: 8px 10px !important;
        font-size: 12px !important;
    }
}

.withdraw-text-box {
    padding: 8px 24px 20px 24px !important;
}

// forgot-password-css
.forgot-pwd-profile-card {
    padding: 70px 0 50px 80px !important;

    @media (max-width : 1600px) {
        padding: 40px 0 70px 70px !important;
    }

    @media (max-width : 1400px) {
        padding: 40px 0 70px 50px !important;
    }

    @media (max-width : 991px) {
        padding: 30px 0 0 30px !important;
    }

    @media (max-width : 768px) {
        padding: 20px 0 20px 30px !important;
    }

    @media (max-width : 575px) {
        padding: 20px 0 20px 12px !important;
    }

    .subtitle {
        margin-top: 0 !important;
    }
}

.forgot-password-form {
    display: flex;
    flex-direction: column;

    .auth-input {
        width: 40%;
        padding: 20px !important;
        margin: 0 0 45px !important;

        @media (max-width : 991px) {
            width: 50%;
        }

        @media (max-width : 575px) {
            width: 80%;
            margin: 0 0 35px !important;
        }

        p {
            position: absolute;
            top: 60px;
        }

        .MuiFormLabel-root.Mui-focused {
            color: #000 !important;
        }

        label {
            top: 0px !important;
            left: 15px !important;
        }

        &>div {
            margin: 0 !important;
        }
    }
}

.forgot-pwd-submit-btn {
    @media (max-width : 768px) {
        padding: 10px !important;
    }
}

// Error page css

.error-page-wrapper {
    .error-page-box {
        padding: 50px 0 50px;

        @media (max-width : 1600px) {
            padding: 40px 0 40px;
        }

        @media (max-width : 991px) {
            padding: 30px 0 30px;
        }

        @media (max-width : 768px) {
            padding: 20px 0 20px;
        }

        //  @media (max-width : 575px) {
        //     padding: 20px 0 40px;
        //  }
        h2 {
            font-style: normal;
            font-weight: 400;
            font-size: 42px;
            line-height: 42px;
            color: #000000;
            text-align: center;
            padding-bottom: 40px;

            @media (max-width : 1600px) {
                font-size: 32px;
                line-height: 38px;
                padding-bottom: 30px;
            }

            @media (max-width : 991px) {
                font-size: 26px;
                line-height: 32px;
                // padding-bottom: 30px;
            }

            @media (max-width : 768px) {
                font-size: 22px;
                line-height: 26px;
                padding-bottom: 20px;
            }

            @media (max-width : 575px) {
                font-size: 20px;
                line-height: 24px;
                // padding-bottom: 20px;
            }
        }

        .error-page-img {
            margin: 0 0 40px;
            text-align: center;

            @media (max-width : 1600px) {
                margin: 0 0 30px;
            }

            @media (max-width : 991px) {
                // margin: 0 0 40px;
            }

            @media (max-width : 768px) {
                margin: 0 0 20px;
            }

            @media (max-width : 575px) {
                // margin: 0 0 20px;
            }

            img {
                width: 150px;

                // @media (max-width : 1600px) {
                //     width: 300px;
                //  }
                @media (max-width : 991px) {
                    width: 120px;
                }

                //   @media (max-width : 768px) {
                //     width: 200px;
                //   }
            }
        }

        h5 {
            font-size: 24px;
            line-height: 30px;
            font-family: 'Matter';
            font-weight: 400;
            font-style: normal;
            text-align: center;
            padding-bottom: 50px;

            @media (max-width : 1600px) {
                font-size: 20px;
                line-height: 24px;
                padding-bottom: 34px;
            }

            @media (max-width : 991px) {
                font-size: 18px;
                line-height: 22px;
                padding-bottom: 30px;
            }

            @media (max-width : 768px) {
                font-size: 16px;
                line-height: 20px;
                padding-bottom: 20px;
            }

            //   @media (max-width : 575px) {
            //     font-size: 16px;
            //     line-height: 20px;
            //     padding-bottom: 20px;
            //   }
        }

        .error-home-btn {
            background-color: #000 !important;
            padding: 10px 12px;
            color: #fff;
            font-size: 20px;
            line-height: 28px;
            font-family: 'Matter';
            font-style: normal;
            font-weight: 400;
            display: block;
            margin: 0 auto;
            border-radius: 0;

            @media (max-width : 1600px) {
                font-size: 18px;
                line-height: 22px;
            }

            @media (max-width : 991px) {
                font-size: 18px;
                line-height: 20px;
            }

            @media (max-width : 768px) {
                padding: 10px 10px;
                font-size: 16px;
                line-height: 16px;
            }

            @media (max-width : 575px) {
                font-size: 12px;
                line-height: 12px;
            }

            & :hover {
                background-color: #000 !important;
            }
        }
    }
}

// filter css
.market-place-container .nft-list .selected-filters-container .selected-filters {
    padding: 0px 0px 20px 30px !important;
    margin: 0px !important;

    @media (max-width : 959px) {
        padding: 20px 0 20px 20px !important;
    }

    @media (max-width : 575px) {
        padding: 0 !important;
    }
}

// wallet css
.walletbalance-wrapper {
    border-top: 1px solid #000;
    padding: 70px 0 155.92px 82px;
    display: flex;
    flex-wrap: wrap;

    @media (max-width : 1600px) {
        padding: 40px 0 100px 70px;
    }

    @media (max-width : 991px) {
        padding: 30px 0 60px 40px;
    }

    @media (max-width : 600px) {
        padding: 20px 12px 30px 12px;
    }

    .profile-content {
        border: 0.976415px solid #000000;
        margin: 0 21.48px 15.62px 0;

        @media (max-width : 1279px) {
            margin: 0 0 15px 0;
        }

        .wallet-balance-box {
            height: 213.83px;
            padding: 19.53px 16.6px 12.69px 15.62px;

            @media (max-width : 1600px) {
                padding: 15px 15px 12px 15px;
            }

            @media (max-width : 991px) {
                height: 200px;
                padding: 12px;
            }

            @media (max-width : 575px) {
                height: 165px;
            }

            .profile-card {
                height: 100%;
                padding: 0;
                display: flex;
                justify-content: space-between;

                #wallet-fantico {
                    font-family: 'Matter';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 23.434px;
                    line-height: 35px;
                    letter-spacing: -0.015em;
                    color: #000000;

                    @media (max-width : 1600px) {
                        font-size: 20px;
                        line-height: 20px;
                    }

                    @media (max-width : 575px) {
                        font-size: 17px;
                        line-height: 17px;
                    }
                }

                .wallet-btn-wrapper {
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-end;

                    @media (max-width : 449px) {
                        flex-direction: column;
                        align-items: flex-start;
                    }

                    #wallet-address {
                        font-family: 'Matter';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 16.5991px;
                        line-height: 98.9%;
                        letter-spacing: -0.015em;
                        color: #959595;

                        @media (max-width : 575px) {
                            font-size: 15px;
                            line-height: 15px;
                        }

                        .wallet-address-data {
                            font-size: 11px;
                            line-height: 11px;
                            padding-top: 8px;
                        }
                    }

                    .btn-primary {
                        width: auto;
                        display: block;
                        font-style: normal;
                        font-family: 'Matter';
                        font-weight: 400;
                        font-size: 14.6462px;
                        line-height: 18px;
                        letter-spacing: -0.015em;
                        color: #FFFFFF;
                        border-radius: 5.85849px;
                        padding: 9.74px 28.74px 11.32px 29.2px !important;

                        @media (max-width : 1400px) {
                            padding: 9px 20px !important;
                            font-size: 14px;
                        }

                        @media (max-width : 768px) {
                            font-size: 12px;
                        }

                        @media (max-width : 449px) {
                            margin-top: 10px;
                        }
                    }
                }
            }
        }
    }

    &>.profile-card {
        padding: 0;

        .fiat-wallet-warpper {
            border: 0.976415px solid #000000;
            padding: 19.53px 16.6px 12.69px 15.62px;
            height: 213.83px;
            display: flex;
            justify-content: space-between;

            @media (max-width : 1600px) {
                padding: 15px 15px 12px 15px;
            }

            @media (max-width : 991px) {
                height: 200px;
            }

            @media (max-width : 575px) {
                height: 165px;
            }

            #wallet-fiat {
                font-family: 'Matter';
                font-style: normal;
                font-weight: 400;
                font-size: 23.434px;
                line-height: 35px;
                letter-spacing: -0.015em;
                color: #000000;

                @media (max-width : 1600px) {
                    font-size: 20px;
                    line-height: 20px;
                }

                @media (max-width : 575px) {
                    font-size: 17px;
                    line-height: 17px;
                }
            }

            .fiat-wallet-btn-box {
                display: flex;
                justify-content: space-between;
                align-items: flex-end;

                @media (max-width : 449px) {
                    flex-direction: column;
                    align-items: flex-start;
                }

                .fiat-wallet-btn-type {

                    .wallet-content,
                    #balance {
                        font-family: 'Matter';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 16.5991px;
                        line-height: 98.9%;
                        letter-spacing: -0.015em;
                        color: #959595 !important;

                        @media (max-width : 575px) {
                            font-size: 15px;
                            line-height: 15px;
                        }
                    }
                }

                .btn-primary {
                    width: auto;
                    display: block;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14.6462px !important;
                    line-height: 18px;
                    letter-spacing: -0.015em;
                    color: #FFFFFF;
                    border-radius: 5.85849px;
                    padding: 9.74px 28.74px 11.32px 29.2px !important;

                    @media (max-width : 1400px) {
                        padding: 9px 20px !important;
                        font-size: 14px !important;
                    }

                    @media (max-width : 768px) {
                        font-size: 12px !important;
                    }

                    @media (max-width : 449px) {
                        margin-top: 10px;
                    }
                }
            }
        }
    }
}

.profile-card-nft {
    margin: 0 !important;
    padding: 55px 0 0 50px !important;

    @media (max-width : 1600px) {
        padding: 15px 0 0 20px !important;
    }

    @media (max-width : 991px) {
        padding: 10px 0 0 10px !important;
    }
}

.MuiCircularProgress-colorPrimary {
    color: #000000 !important;
}

.MuiSvgIcon-colorPrimary {
    color: #000000 !important;
}

// community css
.community-detail-section {
    .community-img-wrapper-box {
        margin: 90px 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        @media (max-width : 1600px) {
            margin: 80px 0;
        }

        @media (max-width : 991px) {
            margin: 50px 0;
        }

        @media (max-width : 599px) {
            justify-content: center;
            margin: 30px 0;
        }

        .community-box {
            position: relative;
            margin: 0 16px;

            @media (max-width : 1600px) {
                margin: 0 12px;
            }

            @media (max-width : 991px) {
                margin: 0 8px;
            }

            @media (max-width : 959px) {
                margin: 0;
            }

            .community-img {
                width: 100%;
            }

            .community-box-text {
                position: absolute;
                font-family: 'Matter';
                font-style: normal;
                font-weight: 600;
                font-size: 25px;
                line-height: 30px;
                text-transform: capitalize;
                color: #ffffff;
                right: 21%;
                bottom: 33px;

                @media (max-width : 1600px) {
                    font-size: 22px;
                    line-height: 22px;
                }

                @media (max-width : 1449px) {
                    font-weight: 500;
                }

                @media (max-width : 1199px) {
                    bottom: 30px;
                    font-size: 18px;
                }

                @media (max-width : 1099px) {
                    bottom: 24px;
                    font-size: 18px;
                }

                @media (max-width : 959px) {
                    right: 35%;
                    bottom: 50px;
                    font-size: 30px;
                }

                @media (max-width : 768px) {
                    bottom: 40px;
                    font-size: 24px;
                }

                @media (max-width : 599px) {
                    bottom: 30px;
                    right: 30%;
                    font-size: 18px;
                }

                @media (max-width :425px) {
                    right: 27%;
                    bottom: 25px;
                    font-size: 16px;
                }
            }
        }
    }

    .community-social-warpper {
        margin-bottom: 140px;

        @media (max-width : 1600px) {
            margin-bottom: 120px;
        }

        @media (max-width : 991px) {
            margin-bottom: 80px;
        }

        @media (max-width : 768px) {
            margin-bottom: 50px;
        }

        @media (max-width : 575px) {
            margin-bottom: 30px;
        }

        .community-social-text {
            padding: 0 0 92px 25px;

            @media (max-width : 1600px) {
                padding: 0 0 60px 12px;
            }

            @media (max-width : 991px) {
                padding: 0 0 30px 10px;
            }

            @media (max-width : 959px) {
                padding: 0 0 20px 0;
            }

            p {
                font-style: normal;
                font-weight: 600;
                font-size: 40px;
                line-height: 48px;
                color: #000000;

                @media (max-width : 1600px) {
                    font-size: 34px;
                    line-height: 34px;
                }

                @media (max-width : 991px) {
                    font-size: 24px;
                    line-height: 24px;
                }

                @media (max-width : 599px) {
                    font-size: 20px;
                    line-height: 20px;
                }

                @media (max-width :425px) {
                    font-size: 16px;
                    line-height: 16px;
                }
            }
        }

        .community-social-box-wrapper {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            .community-social-box {
                // width: 644px;
                height: 348px;
                margin: 0 25px;
                background: #D9D9D9;

                @media (max-width : 1600px) {
                    margin: 0 18px;
                }

                @media (max-width : 991px) {
                    margin: 0 12px;
                }

                @media (max-width : 599px) {
                    margin: 0 0 20px 0;
                }

                @media (max-width : 768px) {
                    height: 300px;
                }

                @media (max-width : 575px) {
                    height: 240px;
                }
            }
        }
    }
}

.main-my-grid-wrapper-community {
    padding: 10px;

    .item-image {
        img {
            @media (max-width : 1279px) {
                width: 100%;
                height: 100%;
            }
        }
    }
}

.artist-main-tab .artist-tab .community-tab .MuiTabs-scroller.MuiTabs-fixed {
    margin-bottom: 113.82px !important;

    @media (max-width : 1600px) {
        margin-bottom: 80px !important;
    }

    @media (max-width : 991px) {
        margin-bottom: 60px !important;
    }

    @media (max-width : 768px) {
        margin-bottom: 40px !important;
    }

    @media (max-width : 575px) {
        margin-bottom: 20px !important;
    }
}

.bank-account-field {
    p {
        font-family: 'Matter' !important;
    }
}

.address-form {
    p {
        font-family: 'Matter' !important;
    }
}

// terms and conditions css
.terms-condition-wrapper {
    .flex-column {
        margin-top: 60px !important;

        @media (max-width : 991px) {
            margin-top: 40px !important;
        }

        @media (max-width : 575px) {
            margin-top: 20px !important;
        }

        .playfair-bold {
            font-weight: 700;
            font-size: 36px !important;

            @media (max-width : 991px) {
                font-size: 30px !important;
                line-height: 34px !important;
            }

            @media (max-width : 575px) {
                font-size: 22px !important;
                line-height: 22px !important;
                font-weight: 500 !important;
            }
        }
    }

    .terms-box-wrapper {
        margin: 32px 0 !important;

        @media (max-width : 991px) {
            margin: 22px 0 !important;
        }

        @media (max-width : 575px) {
            margin: 10px 0 !important;
        }
    }
}

.privacy-terms {
    .flex-column {
        margin-top: 60px !important;

        @media (max-width : 991px) {
            margin-top: 40px !important;
        }

        @media (max-width : 575px) {
            margin-top: 20px !important;
        }

        .playfair-bold {
            font-weight: 700;
            font-size: 36px !important;

            @media (max-width : 991px) {
                font-size: 30px !important;
                line-height: 34px !important;
            }

            @media (max-width : 575px) {
                font-size: 22px !important;
                font-weight: 500 !important;
                line-height: 22px !important;
            }
        }
    }

    .privacy-box-wrapper {
        margin: 32px 0 !important;

        @media (max-width : 991px) {
            margin: 22px 0 !important;
        }

        @media (max-width : 575px) {
            margin: 10px 0 !important;
        }
    }
}

.terms-condition-container {
    @media (max-width : 575px) {
        // padding: 8px 8px;
    }
}

.terms-header {
    font-size: 26px !important;
    font-weight: 700 !important;
    padding: 20px 0 10px;

    @media (max-width : 991px) {
        font-size: 20px !important;
    }

    @media (max-width : 575px) {
        font-size: 17px !important;
        font-weight: 700 !important;
    }
}

.bold-text {
    line-height: 26px;
}

.terms-text {
    font-family: 'Matter' !important;
    font-size: 18px !important;
    line-height: 28px !important;
    font-weight: 400 !important;

    @media (max-width : 575px) {
        font-size: 15px !important;
        line-height: 22px !important;
    }
}

.end-of-terms {
    font-family: 'Matter' !important;
    font-size: 22px;
    font-weight: 600 !important;

    @media (max-width : 991px) {
        font-size: 18px !important;
        font-weight: 500 !important;
    }

    @media (max-width : 575px) {
        font-size: 16px !important;
    }
}

.policy-main-text {
    font-size: 32px;
    font-weight: 700 !important;
    padding: 20px 0 10px;

    @media (max-width : 991px) {
        font-size: 26px !important;
        font-weight: 500 !important;
        padding: 10px 0;
    }

    @media (max-width : 575px) {
        font-size: 20px !important;
        font-weight: 500 !important;
    }
}

.policy-text {
    font-family: 'Matter' !important;
    font-size: 18px !important;
    line-height: 28px !important;
    font-weight: 400 !important;

    @media (max-width : 575px) {
        font-size: 15px !important;
        line-height: 22px;
    }
}

.policy-main-head-text {
    font-family: 'Matter' !important;
    font-size: 22px !important;
    line-height: 30px !important;
    font-weight: 400 !important;

    @media (max-width : 575px) {
        font-size: 18px !important;
        line-height: 26px;
    }
}

.table-policy-text {
    font-family: 'Matter' !important;
    font-size: 18px !important;
    line-height: 22px !important;
    font-weight: 400 !important;
    margin: 20px 0;

    @media (max-width : 575px) {
        font-size: 12px !important;
        line-height: 12px !important;
    }
}

.policy-table {

    table,
    th,
    td {
        border: 1px solid black;
        border-collapse: collapse;
        padding: 6px;

        @media (max-width : 349px) {
            padding: 2px;
        }
    }
}

// contact us page
#contactPage {
    &>.flex-column {
        margin-top: 60px !important;

        @media (max-width : 1600px) {
            margin-top: 40px !important;
        }

        @media (max-width : 991px) {
            margin-top: 20px !important;
        }

        @media (max-width : 768px) {
            margin-top: 12px !important;
        }

        p {
            font-family: 'Matter' !important;
            font-size: 18px;
            line-height: 22px;

            @media (max-width : 575px) {
                font-size: 15px !important;
                line-height: 20px !important;
            }
        }
    }

    .playfair-bold {
        font-size: 36px;

        @media (max-width : 991px) {
            font-size: 28px !important;
            line-height: 34px !important;
        }

        @media (max-width : 575px) {
            font-size: 22px !important;
            line-height: 28px !important;
        }
    }
}

.contact-address-text {
    font-family: 'Matter' !important;
    font-size: 18px !important;
    line-height: 22px !important;

    @media (max-width : 991px) {
        font-size: 15px !important;
        line-height: 18px !important;
    }
}

.matter-fontfamily {
    font-family: 'Matter' !important;
}

.contactus-submit-btn {
    padding-left: 10px !important;
    padding-right: 10px !important;
}

#contactPage .contact-container .customCard {
    @media (max-width : 575px) {
        margin-bottom: 0;
    }
}

.contact-us-wrapper {
    padding: 56px 0 !important;

    @media (max-width : 1600px) {
        padding: 46px 0 !important;
    }

    @media (max-width : 991px) {
        padding: 30px 0 !important;
    }
}

.contact-left-box {
    padding: 16px !important;

    @media (max-width : 1600px) {
        padding: 12px !important;
    }

    @media (max-width : 991px) {
        padding: 8px !important;
    }
}

.contact-us-left-text {
    @media (max-width : 768px) {
        margin-bottom: 0 !important;
    }
}

.contactus-form {
    .contact-field {
        &>p {
            font-size: 16px !important;
            font-family: 'Matter' !important;
            padding-bottom: 4px;
        }

        .outer-label {
            input {
                &::placeholder {
                    font-family: 'Matter' !important;
                    font-style: normal !important;
                    font-weight: 400 !important;
                    font-size: 17px !important;
                    line-height: 98.9% !important;
                    letter-spacing: -0.04em !important;
                    color: rgba(89, 89, 89, 0.24) !important;

                    @media (max-width : 575px) {
                        font-size: 14px !important;
                        line-height: 14px !important;
                    }
                }
            }
        }
    }

    .contact-field-textarea {
        .Mui-error {
            margin: 3px 0 0 0 !important;
        }

        &>div {
            margin: 0 !important;
        }

        fieldset {
            border-radius: 0 !important;
        }

        textarea {
            &::placeholder {
                font-family: 'Matter' !important;
                font-style: normal !important;
                font-weight: 400 !important;
                font-size: 17px !important;
                line-height: 98.9% !important;
                letter-spacing: -0.04em !important;
                color: rgba(89, 89, 89, 0.24) !important;
                opacity: 1;

                @media (max-width : 575px) {
                    font-size: 14px !important;
                    line-height: 14px !important;
                }
            }
        }

        .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
            border: 1px solid #000 !important;
        }
    }
}

.form-card {
    @media (min-width : 600px) {
        padding-top: 0 !important;
    }
}

.contact-submit-btn {
    @media (max-width : 768px) {
        padding: 10px !important;
    }

    @media (max-width : 575px) {
        padding: 5px !important;
    }

    span {
        font-family: 'Matter' !important;
        font-size: 20px !important;

        @media (max-width : 768px) {
            font-size: 16px !important;
        }
    }
}

// faq page css
.main-faq-text {
    font-family: 'Matter' !important;
    font-size: 18px !important;
    line-height: 26px !important;
    -webkit-line-clamp: 2 !important;
    overflow: hidden !important;
    -webkit-box-orient: vertical !important;
    display: -webkit-box !important;
    height: 50px;

    @media (max-width : 575px) {
        font-size: 15px !important;
        line-height: 22px !important;
    }
}

.main-faq-head-text {
    font-size: 22px !important;

    @media (max-width : 991px) {
        font-size: 18px !important;
    }
}

.faq-help-box-wrapper {
    padding: 56px 0;

    @media (max-width :1600px) {
        padding: 50px 0;
    }

    @media (max-width : 991px) {
        padding: 20px 0;
    }

    @media (max-width : 575px) {
        padding: 10px 0;
    }
}

.faq-help-box-wrapper-grid {
    padding: 10px;
}

.faq-box-wrapper-help {
    padding: 8px;
}

#faqPage {
    .flex-column {
        margin-top: 60px !important;

        @media (max-width :1600px) {
            margin-top: 50px !important;
        }

        @media (max-width : 991px) {
            margin-top: 20px !important;
        }

        @media (max-width : 575px) {
            margin-top: 10px !important;
        }

        h5 {
            font-size: 18px;

            @media (max-width : 991px) {
                font-size: 14px !important;
            }
        }

        .playfair-bold {
            font-size: 36px !important;
            line-height: 46px !important;

            @media (max-width : 991px) {
                font-size: 28px !important;
                line-height: 32px !important;
            }

            @media (max-width : 768px) {
                font-size: 22px !important;
                line-height: 26px !important;
            }
        }
    }

    .faqLeft {
        .list {
            padding: 20px !important;

            @media (max-width :1600px) {
                padding: 16px !important;
            }

            @media (max-width : 991px) {
                padding: 10px !important;
            }

            .faq-heading {
                font-size: 22px !important;

                @media (max-width : 991px) {
                    font-size: 18px !important;
                }

                @media (max-width : 575px) {
                    font-size: 15px !important;
                }
            }

            .faq-text {
                span {
                    font-family: 'Matter' !important;
                    font-size: 17px !important;
                    line-height: 20px !important;

                    @media (max-width : 991px) {
                        font-size: 15px !important;
                        line-height: 15px;
                    }
                }
            }
        }
    }

    .faq-right {
        .faqContainer {
            .faq-heading {
                font-size: 24px !important;
                line-height: 28px !important;

                @media (max-width : 991px) {
                    font-size: 18px !important;
                    line-height: 22px !important;
                }

                @media (max-width : 575px) {
                    font-size: 15px !important;
                    line-height: 18px !important;
                }
            }

            .faq-content {
                font-family: 'Matter' !important;
                font-size: 20px !important;
                line-height: 24px !important;

                @media (max-width : 575px) {
                    font-size: 15px !important;
                    line-height: 18px !important;
                }
            }
        }
    }
}

.faq-help-card {
    padding: 16px !important;

    @media (max-width : 991px) {
        padding: 10px !important;
    }
}

#helpPage {
    .flex-column {
        margin-top: 60px !important;

        @media (max-width :1600px) {
            margin-top: 50px !important;
        }

        @media (max-width : 991px) {
            margin-top: 20px !important;
        }

        @media (max-width : 575px) {
            margin-top: 10px !important;
        }

        h5 {
            font-size: 18px;

            @media (max-width : 991px) {
                font-size: 16px;
            }

            @media (max-width : 991px) {
                font-size: 14px;
            }
        }

        .playfair-bold {
            font-size: 36px;
            line-height: 46px !important;

            @media (max-width : 991px) {
                font-size: 28px !important;
                line-height: 32px !important;
            }

            @media (max-width : 768px) {
                font-size: 22px !important;
                line-height: 26px !important;
            }
        }
    }
}

// new css
.uploaded-doc {
    width: 100%;
}

// .upload-btn {
//     margin: 10px 10px 10px 0!important;
// }
.MuiButton-textPrimary {
    color: #000000;
}

.profile-card .MuiPaper-elevation1 {
    box-shadow: none !important;
}

.notFound {
    top: 40%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    position: absolute;
}

.payment-wrapper .crypto-box.active {
    border: 1px solid #000000;
}

.team-img-border {
    border: 0.4px solid #000000;
    height: 335px;
    width: 318px !important;


}

.team-image {
    object-position: top;
}

.Our-Advisors-2 {
    display: flex;
    justify-content: flex-end;
    padding-left: 2rem;
    border-left: 1px solid #000000;

    @media (max-width : 1191px) {
        border-left: 0px !important;
        padding-left: 0rem !important;
    }

    @media (max-width : 991px) {
        margin-top: 2rem;
    }
}

#aboutPage p.about-paragraph {
    font-family: 'Matter';
    font-weight: 400;
    font-size: 17.74px;
    line-height: 20px;
    color: #000000;
    margin: 0 0 16px 0 !important;

    @media (max-width : 1600px) {
        font-size: 17px;
        line-height: 23px;
    }

    @media (max-width : 991px) {
        font-size: 15px;
        line-height: 18px;
    }
}

.about-page-wrapper-box {
    margin: 40px 0 100px;

    @media (max-width : 991px) {
        margin: 30px 0 50px;
    }

    @media (max-width : 575px) {
        margin: 20px 0 50px;
    }
}

#aboutPage .about-page-header {
    font-weight: 600;
    font-size: 40px;
    line-height: 48px;
    color: #000000;
    margin-bottom: 30px;

    @media (max-width : 1600px) {
        font-size: 36px;
        line-height: 44px;
        margin-bottom: 26px;
    }

    @media (max-width : 991px) {
        font-size: 30px;
        line-height: 38px;
        margin-bottom: 20px;
    }

    @media (max-width : 768px) {
        font-size: 24px;
        line-height: 30px;
    }

    @media (max-width : 575px) {
        font-size: 22px;
        line-height: 24px;
    }
}

.aboutpage-box {
    align-items: center;
}

.about-grid-text {
    @media (max-width : 1279px) {
        margin-bottom: 20px !important;
    }
}

.about-box-img-grid {
    text-align: right;
    align-self: center;

    @media (max-width : 1279px) {
        text-align: center;
    }

    img {
        @media (max-width : 600px) {
            width: 100%;
        }
    }
}

// artist slider css
.artist-detail-inner-container {
    .img-slider {
        padding: 0 !important;
    }

    .slide-arrow-next,
    .slide-arrow-prev {
        top: 30%;
    }
}

.artist-video {
    position: relative !important;
}

// withdraw popup

.withdraw-popup {
    padding: 16px 20px !important;
}

.MuiDialogContent-root.withdraw-popup {
    padding: 0 20px 16px 20px !important;
}

.withdraw-popup .title-section {
    margin-bottom: 5px !important;
}


.banner-details-sub-text {
    position: relative;
    display: inline-block;
    word-wrap: break-word;
    overflow: hidden;
    max-height: 8em;
    /* (Number of lines you want visible) * (line-height) */
    line-height: 1.2em;
    text-align: justify;
}

.banner-details-sub-text::after {
    content: "...";
    position: absolute;
    right: -12px;
    bottom: 4px;
}

.setting-link {
    display: inline;
    text-decoration: underline;
    cursor: pointer;
}

.secondary-text {
    color: #A2A2A2;
}

.maxheight4 {
    max-height: 4rem;

}

.img-icons {
    margin: 8px;
    width: 24px;
    height: 24px;
    object-fit: contain;
}

// ==================== How it works
.vertical-scroll {
    width: auto;
    overflow-y: scroll;
}

.how-ItWorks-stepper-wrapper {
    padding-top: 10px;
    // margin: 0 4rem;
    margin-top: auto;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;

    @media (max-width: 1200px) {
        width: 1200px;
    }

    .text-gray-desc {
        text-align: justify;
        margin-top: 0.5em;
        color: #575757;
        font-size: 1rem;
        line-height: 1.4;
    }
}

.step-status-wrapper {
    width: auto;

    @media (max-width: 1200px) {
        width: 290px;
    }
}

.how-it-work-content {
    padding-top: 10px;
    border-radius: 10px;
    box-shadow: 0px 2px 4px rgba(166, 173, 201, .2);
}

.how-it-work-content:hover {
    box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.25);
    transform: scale(1.02);
    transition: 0.5s ease-in-out;
    // box-shadow: 4px 4px 8px rgba(166, 173, 201, .2);
}

.subtitle-steps {
    line-height: 20px !important;

    @media (max-width: 1200px) {
        font-size: 1.2rem !important;
        text-align: left !important;
    }
}

.stepper-item {
    position: relative;
    display: flex;
    flex-direction: column;
    // align-items: center;
    flex: 1;

    @media (max-width: 768px) {
        font-size: 12px;
    }
}

.text-capitalize {
    text-transform: capitalize !important;
}

.text-ellipsis--1 {
    &>p {
        text-align: left !important;
        margin: 0 !important;
    }

    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box !important;
    -webkit-line-clamp: 2 !important;
    -webkit-box-orient: vertical !important;
    white-space: normal !important;
}

.text-ellipsis--2 {
    &>p {
        text-align: left !important;
        margin: 0 !important;
    }

    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box !important;
    -webkit-line-clamp: 2 !important;
    -webkit-box-orient: vertical !important;
    white-space: normal !important;
}

.text-ellipsis--3 {
    &>p {
        text-align: left !important;
        margin: 0 !important;
    }

    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box !important;
    -webkit-line-clamp: 3 !important;
    -webkit-box-orient: vertical !important;
    white-space: normal !important;
}

.curator-text {
    text-align: left !important;
    line-height: 8px !important;
    font-size: 0.8em !important;
}

@media (max-width: 768px) {
    .banner-text h5 {
        line-height: 17px;
    }
}

.curatorName {
    line-height: 1.2rem !important;
    font-size: 1em !important;
    white-space: pre-wrap;
}

.underLine {
    text-decoration: underline !important;
}

.hoverRow:hover {
    box-shadow: 4px 8px 20px rgba(166, 173, 201, .2);
    background-color: transparent !important;
}

.globlLoading {
    background-color: #fff;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
}

// .video-container:hover .video-icon {
//     display: block;
// }

// .video-container {
//     @media (min-width: 1300px) {
//         padding: 0 2rem;
//     }
// }

.PhysicalNFT {
    height: 40px !important;
    border-radius: 0 !important;
    background: #FFFFFF !important;
    border: 1px solid #000000 !important;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.11);
}

@media only screen and (max-width:439px) {
    .edition {
        margin-right: 10px;
    }
}

.subscribe_input {
    width: 100%;
    color: #fff !important;
    padding-left: 10px;
    height: 46px;
    background: transparent;
    border: none;
}

.subscribe-wrapper {
    border: 2px solid rgba(255, 255, 255, 0.8);
    // border: 10px solid !important;
    // border-image-slice: 1 !important;
    // border-width: 2px !important;
    // border-image-source: linear-gradient(286.08deg, rgba(255, 255, 255, 0.8) -0.1%, rgba(255, 255, 255, 0.472) 42.69%) !important;

}

.subscribe-btn {
    width: 120px !important;
    color: #fff !important;
    border-radius: 0px !important;
    font-size: 1rem !important;
    cursor: pointer !important;
    background: none !important;
}

.subscribe_input::placeholder {
    color: #fff !important;
}

.subscribe_input {
    color: #fff !important;
}

.subscribe_input:active {
    border: none !important;
    outline: none !important;
}

.subscribe_input:focus {
    border: none !important;
    outline: none !important;
}

.flatpickr-day.selected {
    background-color: #000000 !important;
}

.flatpickr-days {
    .selected {
        border-color: #000 !important;
    }
}

.readmore-chip {
    background-color: #000000 !important;
    color: #fff !important;
}

.slide-thumbnail {
    object-fit: contain;
    max-width: 90vw;
}

.goesLiveUiWrapper {
    border: 1px solid #000000;
}

.zoom-image-container {
    height: 101vh !important;

    @media (max-width: 968px) {
        height: 100% !important;
    }
}

.time-icon {
    // margin-left: 0.2rem;
    width: 16px !important;
    height: 16px !important;
    object-fit: fill;

    @media (max-width: 768px) {
        width: 12px !important;
        height: 12px !important;
    }
}

.sales-start {
    @media (max-width: 668px) {
        display: none !important;
    }
}

.editionWrapper-detailpage {
    @media (max-width: 958px) {
        display: flex;
        justify-content: flex-end;
        // display: none !important;
    }
}

.justify-text {
    text-align: justify;
}

.align-items {
    display: flex;
    justify-content: space-between;
}

.align-item-edition {
    display: flex;
    justify-content: flex-end;

    @media (max-width: 768px) {
        margin-top: 0.8rem;
        justify-content: flex-start;
    }
}

.about-company {
    font-size: 18px !important;
    line-height: 22px !important;
}

@media (max-width: 768px) {
    .small-device-share {
        padding: 0px !important;
        margin-top: 0px !important;
    }
}

.sold-content {
    display: flex;
    justify-content: flex-end;
}

.edition-chip {
    color: #fff;
    background-color: rgba(159, 159, 159, 0.4) !important;
    backdrop-filter: blur(10px) !important;
}

.type-chip {
    height: 30px !important;
    border-radius: 0 !important;
    background: #FFFFFF !important;
    border: 1px solid #000000 !important;
    padding: 11px 6px !important;
    box-shadow: 0px 4px 12px rgb(0 0 0 / 11%);
}

// .announcement-img-wrapper {
//     width: 100%;
//     height: 500px;
//     display: flex;
//     justify-content: space-between;

//     .imageBox {
//         padding: 1rem;
//         width: 33.33%;
//     }

//     @media (max-width:568px) {
//         flex-direction: column;

//         .imageBox {
//             width: 100%;
//         }
//     }
// }
.desktop-announcement-img-wrapper {
    width: 100%;

    @media (max-width:467px) {
        display: none;
    }
}

.mobile-announcement-img-wrapper {
    @media (min-width:468px) {
        display: none;

        div {
            display: flex;
            flex-direction: column;
        }
    }

}

.desktop-announcement-img-wrapper {
    .slick-track {
        display: flex !important;
        flex-direction: row !important;
        align-items: center !important;
    }
}

.announcement-img-wrapper {

    .slide-arrow-prev,
    .slide-arrow-next {
        background: white;
        border: 1px solid black;

        svg {
            color: black;
        }
    }

    .slide-arrow-prev {
        left: 2px;
    }

    .slide-arrow-next {
        right: 2px;
    }

    .slick-dots {
        margin-top: 30px;
        position: static;

        @media (max-width: 600px) {
            li {
                button {
                    &::before {
                        width: 12px !important;
                        height: 12px !important;
                        border-radius: 50% !important;
                        background-color: #dbdbdb !important;
                        border: none !important;
                    }
                }
            }
        }

        li {
            button {
                &::before {
                    width: 18px !important;
                    height: 18px !important;
                    border-radius: 50% !important;
                    background-color: #dbdbdb !important;
                    border: none !important;
                }
            }
        }

        .slick-active {
            button {
                &::before {
                    background-color: #9f9f9f !important;
                }
            }
        }
    }
}

#audioBox {
    display: flex !important;
    justify-content: center !important;
    height: 60vh !important;
    align-items: center;
}

.notification_bar {
    background-color: #4d4a98;
    position: relative;
    @media (max-width: 534px) {
        position: fixed;
        bottom: 0;
        right: 0;
        padding: 0.5rem;
    }
}

// Audio Wavesuffer style 

#waveform {
    width: 100%;
    height: 100px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}


@media(max-width: 600px) {
    .sell-content{
        padding: 5px  !important
    }
    .dialog-sell{
        .MuiDialog-paper{
            margin: 5px !important;
        }
    }   

}

.region-name-1 {
    -webkit-line-clamp: 1;
    overflow: hidden;
    -webkit-box-orient: vertical;
    display: -webkit-box;
}