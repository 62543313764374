.MuiButtonBase-root.MuiButton-root {
    text-transform: none !important;
    border-radius: 3px;
    padding: 15px;
    font-weight: 300;
    margin-top: 10px;
    .MuiCircularProgress-root {
        margin-right: 10px;
    }
}

.MuiButtonBase-root.btn-primary {
    .MuiCircularProgress-root {
        color: $btn-primary-text;
    }
}

.MuiButtonBase-root.btn-secondary {
    .MuiCircularProgress-root {
        color: $btn-secondary-text;
    }
}

// .MuiButtonBase-root.btn-small {
//     padding: 7.5px !important;
// }
