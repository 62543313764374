.item-container {
    background-color: $white;
    .img-container {
        display: flex;
        justify-content: center;
        height: 350px;
        align-items: center;
        position: relative;
        video {
            max-width: 90%;
            max-height: 300px;
        }
        .item-image {
            max-height: 350px;
            max-width: 100%;
            min-width: 50%;
        }
        .play-btn {
            width: 50px;
            height: 50px;
            cursor: pointer;
            position: absolute;
            right: 0;
            bottom: 0;
            background-color: white;
            border: 1px solid #0000003d;
            svg {
                color: black;
            }
        }
    }
    .item-detail {
        padding-top: 20px;
    }
    .justify-content {
        display: flex;
        justify-content: space-between;
    }
    .gray-color {
        color: rgb(138, 147, 155) !important;
        font-size: 14px;
    }
    .item-name {
        cursor: pointer;
    }
}
