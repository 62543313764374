#helpPage {
    .query-box {
        display: flex;
        margin: 20px 0px;

        input {
            width: 400px;
            background-color: $white;
            color: $text-primary;
            border-radius: 30px !important;
            ::placeholder {
                color: $text-gray !important;
            }
        }
        .MuiOutlinedInput-root {
            border-radius: 30px !important;
        }
    }

    // .about-paragraph {
    //     line-height: 1.8;
    //     text-align: justify;
    // }

    // .faqHeading {
    //     svg {
    //         color: white;
    //     }
    // }

    // .teamIntro {
    //     text-align: center;
    //     line-height: 1.8 !important;
    //     margin-top: 20px !important;
    // }

    .teamCard {
        padding: 20px;
        background-color: $white !important;
        color: $text-primary !important;
        box-shadow: 0px 0px 35px #0000001a;
        text-align: center;

        // transition: ease 0.3s !important;
        // &:hover {
        //     box-shadow: rgb(61, 61, 61) 0px 0px 8px 0px;
        //     transform: translate(0, -20px);
        //     background-color: $white !important;
        // }
    }

    // .helpContainer {
    //     top: 50%;
    //     left: 50%;
    //     position: absolute;
    //     transform: translate(-50%, -50%);
    //     padding-top: 0;
    //     padding-bottom: 0;
    //     color: $text-primary;
    //     font-size: 1.2em;
    // }
}
