@mixin poppins($weight) {
    font-family: 'Poppins';
    font-weight: $weight !important;
}

@mixin poppins-thin {
    @include poppins(100);
}

@mixin poppins-extralight {
    @include poppins(200);
}

@mixin poppins-light {
    @include poppins(300);
}

@mixin poppins-regular {
    @include poppins(400);
}

@mixin poppins-medium {
    @include poppins(500);
}

@mixin poppins-semibold {
    @include poppins(600);
}

@mixin poppins-bold {
    @include poppins(700);
}

.poppins-thin {
    @include poppins-thin;
}

.poppins-extralight {
    @include poppins-extralight;
}

.poppins-light {
    @include poppins-light;
}

.poppins-regular {
    @include poppins-regular;
}

.poppins-medium {
    @include poppins-medium;
}

.poppins-semibold {
    @include poppins-semibold;
}

.poppins-bold {
    @include poppins-bold;
}

@mixin playfair($weight) {
    font-family: 'Playfair Display';
    font-weight: $weight !important;
}

@mixin playfair-regular {
    @include playfair(400);
}

@mixin playfair-medium {
    @include playfair(500);
}

@mixin playfair-semibold {
    @include playfair(600);
}

@mixin playfair-bold {
    @include playfair(700);
}

.playfair-regular {
    @include playfair-regular;
}

.playfair-medium {
    @include playfair-medium;
}

.playfair-semibold {
    @include playfair-semibold;
}

.playfair-bold {
    @include playfair-bold;
}
