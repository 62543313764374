.filter-container {
    .heading {
        margin-bottom: 20px;
    }

    .heading.mobile {
        display: none;
    }

    .btn-small.mobile {
        display: none;
    }

    .MuiAccordion-root.Mui-expanded {
        margin: 0px;
    }

    .MuiAccordionDetails-root {
        padding: 0px 20px 20px;
    }

    .MuiAccordion-root {
        border-top: $borderStyle;
    }

    .filter-content {
        width: 100%;
    }

    .filter-margin {
        // margin-left: 20px !important;
        .filter-input{
            // width: 40%;
            display: flex;
            position: relative;
        }
    }
    .price-filter-input{
        width: 100%;
        height: 45px;
        margin-top: 10px;
        margin-right: 6px;
        border: 1px solid black;
        margin-bottom: 8px;
        font-size: 16px;
        padding-left: 10px;
        outline: none;
        display: flex;
        flex-direction: column;
        justify-content: center;
  
        
    }
    .price-filter-container{
        // width:700px;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        margin-bottom: 9px;
    }
    .price-filter-message{
        width: 84%;
        height: 29%;
        position: absolute;
        margin-top: 4px;
        bottom: -2px;
        line-height: 13.5px;
        word-break: keep-all;
        line-break: auto;
        margin: -10px;
        left: 55px;
        font-size: 0.78rem;
        color: red;
    }
    @media screen and (max-width: 1133px) {
        .price-filter-message{
            width: 82%;
        }
        .price-filter-container{
            margin-bottom: 10px;
        }

    }
    // @media screen and (max-width: 1007px) {
    //     .price-filter-container{
    //         margin-bottom: 7px;
    //     }

    // }
    @media screen and (max-width: 991px) {
        .filter-margin{
            margin-bottom: 6px;
        }
        .price-filter-container{
            margin-bottom: 25px;
        }
        .price-filter-message{
            bottom: 7px;
            left: 47px;
        }
    }
    @media screen and (max-width: 959px) {
        .price-filter-input{
            width: 90%;
        }
        .price-filter-message{
            width: 90%;
        }
        .filter-input{
            width: 40%;
        }
    }


    @media screen and (max-width: 575px) {
        .price-filter-input{
            width: 60%;
        }
        .price-filter-message{
            width: 92%;
        }
        .filter-input{
            width: 80%;
        }
    }
    @media screen and (max-width: 499px) {
        .filter-input{
            width: 100%;
        }
    }
}

@media screen and (max-width: 575px) {
    .filter-container .mobile-filter {
        display: none;
    }
    .filter-container .btn-small.mobile {
        display: block;
    }
    .filter-container .heading.mobile {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-right: 20px;
    }

    .filter-container .heading.mobile.top {
        display: block;
        position: relative;

        img {
            width: 19px;
            height: 19px;
            margin-right: 10px;
        }

        bdi {
            width: 12px;
            height: 12px;
            position: absolute;
            background-color: $primary;
            border-radius: 50%;
            margin-left: 5px;
        }
    }

    .filter-container .heading.desktop {
        display: none !important;
    }

    .filter-container.open ~ .app-body {
        background-color: red;
    }

    .filter-container.open {
        display: block;
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 1000;
        overflow: scroll;
        padding-top: 20px;
        margin: 0;
        background-color: $white;

        .heading.mobile.top {
            display: none !important;
        }

        .mobile-filter {
            display: block;

            bdi {
                width: 10px;
                height: 10px;
                position: absolute;
                background-color: #000;
                border-radius: 50%;
                margin-left: 5px;
            }
        }
    }
}
